import { format, parseISO } from "date-fns";

export const formatDateToUsersLocale = (date: string) => {
  return format(parseISO(date), "MMM d, yyyy");
};

export const formatTimeToUsersLocale = (date: string) => {
  return format(parseISO(date), "h:mmaaa");
};

export const formatFullDateToUsersLocale = (date: string) => {
  return format(parseISO(date), "MMM d, yyyy. h:mmaaa");
};

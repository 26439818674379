import {
  TAccountLoading,
  IChangePasswordBody,
  IUpdateNotificationBody,
  IResetTransactionPinBody,
  IChangeTransactionPinBody,
  ICreateTransactionPinBody
} from "../models/account";
import { Dispatch } from "redux";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import uploadFile from "data/utils/fileUpload";
import { actions } from "../reducers/account.reducer";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import accountService from "data/services/account.service";
import { updateNotification } from "../reducers/business-setup.reducers";
import { actions as dashboardActions } from "../reducers/dashboard.reducer";

export const changePassword = (body: IChangePasswordBody) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("changePassword"));
      await accountService.changePassword(body);
      dispatch(actions.success({ for: "changePassword" }));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "changePassword" }));
    }
  };
};

export const createTransactionPin = (body: ICreateTransactionPinBody) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("createTransactionPin"));
      await accountService.createTransactionPin(body);
      dispatch(actions.success({ for: "createTransactionPin" }));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "createTransactionPin" }));
    }
  };
};

export const getResetPinCode = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getResetPinCode"));
      await accountService.getResetPinCode();
      dispatch(actions.success({ for: "getResetPinCode" }));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.error({ msg, for: "getResetPinCode" }));
      toast.error(msg);
    }
  };
};

export const validateResetPinCode = (code: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("validateResetPinCode"));
      await accountService.validateResetPinCode(code);
      dispatch(actions.success({ for: "validateResetPinCode" }));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.error({ msg, for: "validateResetPinCode" }));
      toast.error(msg);
    }
  };
};

export const resetTransactionPin = (body: IResetTransactionPinBody) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("resetPin"));
      await accountService.resetTransactionPin(body);
      dispatch(actions.success({ for: "resetPin" }));
      toast.success("Success");
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.error({ msg, for: "getResetPinCode" }));
      toast.error(msg);
    }
  };
};

export const updateTransactionPin = (body: IChangeTransactionPinBody) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("changeTransactionPin"));
      await accountService.updateTransactionPin(body);
      dispatch(actions.success({ for: "changeTransactionPin" }));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.error({ msg, for: "changeTransactionPin" }));
      toast.error(msg);
    }
  };
};

export const updateNotificationSettings = (
  callSign: string,
  body: IUpdateNotificationBody
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("updateNotificationSettings"));
      const res = await accountService.updateNotificationSettings(
        callSign,
        body
      );
      dispatch(actions.updateNotificationInfo());
      dispatch(updateNotification(body));
      toast.success(res.data.message);
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.error({ msg, for: "updateNotificationSettings" }));
      toast.error(msg);
    }
  };
};

export const uploadProfileImage = (file: File, filename: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("uploadProfileImage"));
      const response = await uploadFile(file, filename);
      const regex = /.com\/\//gi;
      let image = response.location;
      image = image.replaceAll(regex, ".com/");
      const res = await accountService.uploadProfileImage(image);
      dispatch(actions.success({ for: "uploadProfileImage" }));
      dispatch(dashboardActions.updateUserProfileImage(image));
      toast.success(res.data.message);
    } catch (err: any) {
      const msg = err?.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "uploadProfileImage" }));
    }
  };
};

export const setAccountIdleStatus = (value: TAccountLoading) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.idle({ for: value }));
  };
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  loading: false
};

export const AppSLice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    }
  }
});

export const actions = AppSLice.actions;

export default AppSLice.reducer;

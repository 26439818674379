import {
  TBank,
  ICountry,
  IResolvedBank,
  IResolveBankBody,
  TMobileMoneyAgent,
  IAppVersion
} from "app/store/models/misc";
import { AxiosResponse } from "axios";
import instance from "data/axios-setup";
import { CurrencyId } from "app/store/models/currency";

class MiscService {
  async getBanks(code: string): Promise<AxiosResponse<{ data: TBank[] }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/misc/banks?code=${code}`);
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getMobileMoneyAgents(
    country: string
  ): Promise<AxiosResponse<{ data: TMobileMoneyAgent[] }>> {
    const params = { country };
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get("/misc/mobile-money-agents", {
          params
        });
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getCountriesOfRegistration(): Promise<AxiosResponse<ICountry[]>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/misc/countries-of-registration`);
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async resolveBank(
    body: IResolveBankBody
  ): Promise<AxiosResponse<{ data: IResolvedBank }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(`/misc/resolve-bank`, body);
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getSupportedFiatWallets(): Promise<
    AxiosResponse<{ data: CurrencyId[] }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get("/currency");
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getSupportedCryptoWallets(): Promise<
    AxiosResponse<{ data: CurrencyId[] }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          "/supported-wallets/supported-currencies/crypto"
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getAppVersion(): Promise<AxiosResponse<{ data: IAppVersion[] }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/app-version`);
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new MiscService();

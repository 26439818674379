import {
  IExtendBody,
  IGetCurrencyBody,
  IGetCurrencyResponse,
  ISubscribeBody,
  IUpdatePaymentMethodBody,
  IUpdateRenewalCycleBody,
  SubscriptionDetails
} from "app/store/models/subscription";
import { AxiosResponse } from "axios";
import instance from "data/axios-setup";
import { getCallSign } from "data/utils";

class SubscriptionService {
  async getSubscription(id: string) {
    return new Promise<
      AxiosResponse<{ data: SubscriptionDetails; message: string }>
    >(async (resolve, reject) => {
      const callSign = getCallSign();
      try {
        const res = await instance.get(`${callSign}/subscription/${id}`);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }

  async subscribeToPlan(body: ISubscribeBody) {
    return new Promise<AxiosResponse<{ message: string }>>(
      async (resolve, reject) => {
        const callSign = getCallSign();
        try {
          const res = await instance.post(`${callSign}/subscription`, body);
          resolve(res);
        } catch (error) {
          reject(error);
        }
      }
    );
  }

  async subscribeToPlanSubBusiness(
    body: ISubscribeBody,
    subBusinessCallSign: string
  ) {
    return new Promise<AxiosResponse<{ message: string }>>(
      async (resolve, reject) => {
        try {
          const res = await instance.post(
            `${subBusinessCallSign}/sub-business/subscription`,
            body
          );
          resolve(res);
        } catch (error) {
          reject(error);
        }
      }
    );
  }

  async extendPlan(body: IExtendBody) {
    return new Promise<AxiosResponse<{ message: string }>>(
      async (resolve, reject) => {
        const callSign = getCallSign();
        try {
          const res = await instance.post(
            `${callSign}/subscription/extend`,
            body
          );
          resolve(res);
        } catch (error) {
          reject(error);
        }
      }
    );
  }

  async updatePaymentMethod(body: IUpdatePaymentMethodBody) {
    return new Promise<AxiosResponse<{ message: string }>>(
      async (resolve, reject) => {
        const callSign = getCallSign();
        try {
          const res = await instance.patch(`${callSign}/subscription`, body);
          resolve(res);
        } catch (error) {
          reject(error);
        }
      }
    );
  }

  async updateRenewalCycle(body: IUpdateRenewalCycleBody) {
    return new Promise<AxiosResponse<{ message: string }>>(
      async (resolve, reject) => {
        const callSign = getCallSign();
        try {
          const res = await instance.patch(
            `${callSign}/subscription/renewal-cycle`,
            body
          );
          resolve(res);
        } catch (error) {
          reject(error);
        }
      }
    );
  }

  async updatePaymentMethodSubBusiness(
    body: IUpdatePaymentMethodBody,
    subBusinessCallSign: string
  ) {
    return new Promise<AxiosResponse<{ message: string }>>(
      async (resolve, reject) => {
        try {
          const res = await instance.patch(
            `${subBusinessCallSign}/sub-business/subscription`,
            body
          );
          resolve(res);
        } catch (error) {
          reject(error);
        }
      }
    );
  }

  async calculateSubscription(body: IGetCurrencyBody) {
    return new Promise<
      AxiosResponse<{ data: IGetCurrencyResponse; message: string }>
    >(async (resolve, reject) => {
      const callSign = getCallSign();
      try {
        const res = await instance.post(`${callSign}/subscription/price`, body);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }

  async calculateSubscriptionSubBusiness(
    body: IGetCurrencyBody,
    subBusinessCallSign: string
  ) {
    return new Promise<
      AxiosResponse<{ data: IGetCurrencyResponse; message: string }>
    >(async (resolve, reject) => {
      try {
        const res = await instance.post(
          `${subBusinessCallSign}/sub-business/subscription/price`,
          body
        );
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new SubscriptionService();

interface AppConfig {
  server: string;
}

function getEnv(variable: string, optional: boolean = false) {
  if (process.env[variable] === undefined) {
    if (optional) {
      console.warn(
        `[@env]: Environmental variable for ${variable} is not supplied. \n So a default value will be generated for you.`
      );
    } else {
      throw new Error(
        `You must create an environment variable for ${variable}`
      );
    }
  }

  return process.env[variable]?.replace(/\\n/gm, "\n");
}

export const configs = {
  PUBLIC_URL: getEnv("PUBLIC_URL", true),
  NODE_ENV: getEnv("REACT_APP_NODE_ENV", true),
  APP_GOOGLE: getEnv("REACT_APP_GOOGLE", true),
  API_BASE_URL: getEnv("REACT_APP_API_ENDPOINT", false),
  DO_SPACES_URL: getEnv("REACT_APP_DO_SPACES_URL", true),
  DO_BUCKET_NAME: getEnv("REACT_APP_DO_BUCKET_NAME", false),
  FLW_PUBLIC_KEY: getEnv("REACT_APP_FLW_PUBLIC_KEY", false),
  FIREBASE_APP_ID: getEnv("REACT_APP_FIREBASE_APP_ID", true),
  CHAT_SUPPORT_SECRET: getEnv("REACT_APP_EBANQO_SECRET", true),
  FIREBASE_API_KEY: getEnv("REACT_APP_FIREBASE_API_KEY", true),
  PUBLIC_VAPID_KEY: getEnv("REACT_APP_PUBLIC_VAPID_KEY", true),
  DO_SPACES_REGION: getEnv("REACT_APP_DO_SPACES_REGION", false),
  FIREBASE_VAPID_KEY: getEnv("REACT_APP_FIREBASE_VAPID_KEY", true),
  FIREBASE_PROJECT_ID: getEnv("REACT_APP_FIREBASE_PROJECT_ID", true),
  FIREBASE_AUTH_DOMAIN: getEnv("REACT_APP_FIREBASE_AUTH_DOMAIN", true),
  DO_SPACES_ACCESS_KEY: getEnv("REACT_APP_DO_SPACES_ACCESS_KEY", false),
  FIREBASE_STORAGE_BUCKET: getEnv("REACT_APP_FIREBASE_STORAGE_BUCKET", true),
  FIREBASE_MEASUREMENT_ID: getEnv("REACT_APP_FIREBASE_MEASUREMENT_ID", true),
  FIREBASE_MESSAGING_SENDER_ID: getEnv(
    "REACT_APP_FIREBASE_MESSAGING_SENDER_ID",
    true
  ),

  DO_SPACES_SECRET_ACCESS_KEY: getEnv(
    "REACT_APP_DO_SPACES_SECRET_ACCESS_KEY",
    false
  ),
  AES_SECRET_KEY: getEnv("REACT_APP_AES_SECRET_KEY", false),
  SENTRY_DSN: getEnv("REACT_APP_SENTRY_DSN", true),

  CHARLA_URL: getEnv("REACT_APP_CHARLA_URL", true),
  CHARLA_ID: getEnv("REACT_APP_CHARLA_ID", true)
};

const config: AppConfig = {
  server: `${configs.API_BASE_URL}/v1`
};

for (const [key, value] of Object.entries(config)) {
  if (!value) {
    console.log(
      `[-] Environmental varialbe ${key} is not set. Application might not funtion properly.`
    );
  }
}
export default config;

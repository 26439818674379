import { useLocation } from "react-router";
import {
  useAppDispatch,
  useAppSelector,
  useSupportV2
  // useLogoutFunction
} from "app/hooks";
import {
  useEffect
  // useLayoutEffect, useState
} from "react";
import {
  getAllBusiness,
  getBusinessInfo,
  getDashboardRecentActivities,
  getUserProfile
} from "app/store";
import Loading from "views/components/loading";
import { useNavigate } from "react-router-dom";
import { getCallSign } from "data/utils";
import { configs } from "data/config";

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  // (window as any)?.Intercom("update", { hide_default_launcher: false });

  // const logOut = useLogoutFunction();
  const navigate = useNavigate();
  const location = useLocation();
  const [user, isLoading, businessInfoLoading, businessId, info] =
    useAppSelector((state) => [
      state.dashboard.userProfile,
      state.dashboard.loading,
      state.businessSetup.businessInfoLoading,
      state.businessSetup.businessInfo?.id,
      state.businessSetup.businessInfo
    ]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getUserProfile(navigate, location));
    dispatch(getBusinessInfo());
    dispatch(getAllBusiness());
  }, []);

  // fetch recent dashboard activities in the background
  useEffect(() => {
    if (!businessId) return;
    dispatch(getDashboardRecentActivities());
  }, [businessId]);

  // redirect if not done with business setup
  // const callSign = getCallSign();

  useEffect(() => {
    const callSign = getCallSign();
    if (isLoading || businessInfoLoading) return;

    if (callSign == null || callSign.length == 0) {
      navigate("auth/choose-business");
    }
    if (info && !info.businessCompleteSetup) {
      navigate("/onboard/business");
    }
  }, [info]);

  const { dispatchUserDetails } = useSupportV2();
  useEffect(() => {
    if (!user || configs.NODE_ENV !== "production") return;
    dispatchUserDetails({
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      phone: user.phone,
      businessEmail: info?.email || undefined,
      businessName: info?.name || undefined,
      callSign: info?.callSign || undefined
    });
  }, [user, info]);

  // Block multiple tabs and windows
  // const [windowIsActive, setWindowIsActive] = useState<boolean>(true);

  // function handleActivity(forcedFlag: any) {
  //   if (typeof forcedFlag === "boolean") {
  //     return forcedFlag ? setWindowIsActive(true) : setWindowIsActive(false);
  //   }

  //   return document.hidden ? setWindowIsActive(false) : setWindowIsActive(true);
  // }

  // useLayoutEffect(() => {
  //   const handleActivityFalse = () => handleActivity(false);
  //   const handleActivityTrue = () => handleActivity(true);

  //   document.addEventListener("focus", handleActivityTrue);
  //   document.addEventListener("blur", handleActivityFalse);
  //   // document.addEventListener("visibilitychange", handleActivity);
  //   // window.addEventListener("blur", handleActivityFalse);
  //   // window.addEventListener("focus", handleActivityTrue);

  //   return () => {
  //     document.removeEventListener("blur", handleActivityFalse);
  //     document.removeEventListener("focus", handleActivityTrue);
  //     // window.removeEventListener("blur", handleActivity);
  //     // window.removeEventListener("focus", handleActivityFalse);
  //     // document.removeEventListener("visibilitychange", handleActivityTrue);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (!windowIsActive) logOut();
  // }, [windowIsActive]);

  if (isLoading || businessInfoLoading) {
    return <Loading />;
  }

  return (
    <>
      <div>{user && <>{children}</>}</div>
    </>
  );
};

export default PrivateRoute;

import { format } from "date-fns";

export function formatDateToUTC(createdAt: any) {
  const newDate = new Date(createdAt);

  const result = newDate.toLocaleTimeString("en-GB", {
    year: "numeric",
    day: "2-digit",
    month: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  });

  return result;
}

export function formatDateOnlyToUTC(createdAt: any) {
  const newDate = new Date(createdAt);

  const result = newDate.toLocaleDateString("en-GB", {
    year: "numeric",
    day: "2-digit",
    month: "2-digit"
  });

  return result;
}

export function formatDateOnlyToDateString(createdAt: any) {
  if (!createdAt) return "";
  const newDate = new Date(createdAt);

  const result = format(newDate, "MMM d, yyyy, h:mm aaa");

  return result;
}

export function formatTimeToUTC(createdAt: any) {
  const newDate = new Date(createdAt);

  const result = newDate.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit"
  });

  return result;
}

export function formatDayOnylToUTC(createdAt: any) {
  const newDate = new Date(createdAt);

  const result = newDate.toLocaleTimeString("en-GB", {
    year: "numeric",
    day: "2-digit",
    month: "2-digit"
  });

  return result;
}

export function convertHMS(value: any) {
  const sec = parseInt(value, 10);
  let hours: any = Math.floor(sec / 3600);
  let minutes: any = Math.floor((sec - hours * 3600) / 60);
  let seconds: any = sec - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }

  if (minutes < 10) {
    minutes = "0" + minutes;
  }

  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
}

export function formatAMPM(date: any) {
  const newDate = new Date(date);
  var hours = newDate.getHours();
  var minutes = newDate.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? 0 + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

export const formatDate = (input: any) => {
  var datePart = input.match(/\d+/g),
    year = datePart[0], // get only two digits
    month = datePart[1],
    day = datePart[2];

  return month + "-" + day + "-" + year;
};

export const formatDateVirtualCards = (input: any) => {
  var datePart = input.match(/\d+/g),
    year = datePart[0], // get only two digits
    month = datePart[1],
    day = datePart[2];

  return year + "/" + month + "/" + day;
};

export const formatDateToLocaleString = (date: any) =>
  new Date(date)?.toLocaleDateString(undefined, { dateStyle: "long" });

export const localISODateString = (date: any) => {
  const timezoneOffset = new Date().getTimezoneOffset() * 60000;

  const localTime = new Date(date).getTime() - timezoneOffset;
  const localISOTime = new Date(localTime).toISOString().slice(0, -1);
  return String(localISOTime) + "Z";
};

export const plusSomeSeconds = (date: Date | null, seconds: number) => {
  const givenDate = date ? new Date(date) : new Date();
  const workingDate = new Date(givenDate);
  workingDate.setSeconds(workingDate.getSeconds() + seconds);

  return workingDate;
};

export const isValidDate = (dateString?: string) => {
  if (!dateString) return false;

  const date = new Date(dateString);

  return !isNaN(date.getTime());
};

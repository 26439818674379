import { AxiosError } from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/transaction.reducer";
import transactionService from "data/services/transaction.service";
import { DEFAULT_ERROR_MESSAGE, onResponseSuccess } from "data/error-mapping";
import { Filter, TTransactionLoading } from "../models/transaction";

export const getTransaction = (signal: AbortSignal, params: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getSingleTransaction"));
      const res = await transactionService.getSingleTransaction(signal, params);
      dispatch(actions.setSingleTransaction(res.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.error({ msg, for: "getSingleTransaction" }));
    }
  };
};

export const getTransactionById = (params: { id: number }) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getTransactionById"));
      const res = await transactionService.getTransactionById(params);
      dispatch(
        actions.setTransactionById({
          data: res.data.data
        })
      );
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      dispatch(actions.error({ msg, for: "getTransactions" }));
    }
  };
};

export const getConversionTransactions = (callSign: string, params?: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getConversionTransactions"));
      const res = await transactionService.getConversionTransactions(
        callSign,
        params
      );
      dispatch(
        actions.setConversionTransactions({
          data: res.data.data,
          pagination: res.data.pagination
        })
      );
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      dispatch(actions.error({ msg, for: "getConversionTransactions" }));
    }
  };
};

export const getAirtimeDataTransactions = (callSign: string, params?: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getAirtimeDataTransactions"));
      const res = await transactionService.getAirtimeDataTransactions(
        callSign,
        params
      );
      dispatch(
        actions.setAirtimeDataTransactions({
          data: res.data.data,
          pagination: res.data.pagination
        })
      );
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      dispatch(actions.error({ msg, for: "getConversionTransactions" }));
    }
  };
};

export const getTransactions = (params: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getTransactions"));
      const res = await transactionService.getTransactions(params);
      dispatch(
        actions.setTransactions({
          data: res.data.data,
          pagination: res.data.pagination
        })
      );
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      dispatch(actions.error({ msg, for: "getTransactions" }));
    }
  };
};

export const getRecentTransactions = (params: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getRecentTransactions"));
      const res = await transactionService.getTransactions(params);
      onResponseSuccess(res, () => {
        dispatch(
          actions.setRecentTransactions({
            data: res.data.data,
            pagination: res.data.pagination
          })
        );
      });
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      dispatch(actions.error({ msg, for: "getRecentTransactions" }));
    }
  };
};

export const selectTransaction = (id: number) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.selectTransaction(id));
  };
};

export const setTransactionFilter = (data: Partial<Filter>) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setTransactionFilter({ data }));
  };
};

export const setTransactionIdleStatus = (data: TTransactionLoading) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.idle(data));
  };
};

export const setSingleWalletTransactionFilter = (data: Partial<Filter>) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setSingleWalletTransactionFilter({ data }));
  };
};

export const getTransactionSummary = (currency: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getTransactionSummaryBegin());
      const res = await transactionService.getTransactionSummary({
        params: { currency }
      });
      onResponseSuccess(res, () => {
        dispatch(actions.getTransactionSummarySuccess(res.data.data));
      });
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      dispatch(actions.getTransactionSummaryFailed(msg));
    }
  };
};

export const getSubBusinessTransactions = (callSign: string, params: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getTransactions"));
      const res = await transactionService.getSubBusinessTransactions(
        callSign,
        params
      );
      dispatch(
        actions.setTransactions({
          data: res.data.data,
          pagination: res.data.pagination
        })
      );
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      dispatch(actions.error({ msg, for: "getTransactions" }));
    }
  };
};

export const getSubBusinessTransactionById = (
  callSign: string,
  params: { id: number }
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getTransactionById"));
      const res = await transactionService.getSubBusinessTransactionById(
        callSign,
        params
      );
      dispatch(
        actions.setTransactionById({
          data: res.data.data
        })
      );
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      dispatch(actions.error({ msg, for: "getTransactions" }));
    }
  };
};

export const getSubBusinessTransaction = (
  callSign: string,
  signal: AbortSignal,
  params: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getSingleTransaction"));
      const res = await transactionService.getSingleSubBusinessTransaction(
        callSign,
        signal,
        params
      );
      dispatch(actions.setSingleTransaction(res.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.error({ msg, for: "getSingleTransaction" }));
    }
  };
};

import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/bank.reducer";
import bankService from "data/services/bank.service";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import { IAddBankBody, TBankLoading } from "../models/bank";

export const getUserBanks = (signal: AbortSignal, callSign: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getUserBanks"));
      const res = await bankService.getUserBanks(signal, callSign);
      dispatch(actions.setBanksInfo(res.data.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "getUserBanks" }));
    }
  };
};

export const addBank = (callSign: string, body: IAddBankBody) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("addBank"));
      const res = await bankService.addBank(callSign, body);
      dispatch(actions.addBankInfo(res.data.data));
      toast.success(res.data.message);
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "addBank" }));
    }
  };
};

export const deleteBank = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("deleteBank"));
      const res = await bankService.delete(id);
      dispatch(actions.removeBankInfo(id));
      toast.success(res.data.message);
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "deleteBank" }));
    }
  };
};

export const setBankIdleStatus = (value: TBankLoading) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.idle(value));
  };
};

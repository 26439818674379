import { ISIngleCard } from "app/store/models/card";
import { AxiosResponse } from "axios";
import instance, { instanceV2 } from "data/axios-setup";
import { IPagination } from "data/models/base";
import {
  CreateVirtualCard,
  ICardFeesParam,
  IEditViirtualCardStatus,
  IFee,
  IFundVirtualCard,
  IGetCardCreationFees,
  IGetCardFundingFees,
  IGetVirtualCardsParam,
  VirtualCard
} from "data/models/virtual-cards";
import { getCallSign } from "data/utils";

class CardService {
  async createVirtualCard(
    data: CreateVirtualCard
  ): Promise<AxiosResponse<{ message: string }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/${callSign}/virtual-cards`,
          data
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async createVirtualCardV2(
    data: CreateVirtualCard
  ): Promise<AxiosResponse<{ message: string }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instanceV2.post(
          `/${callSign}/virtual-cards`,
          data
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getAllVirtualCards(
    params: Partial<IGetVirtualCardsParam>
  ): Promise<AxiosResponse<{ data: VirtualCard[]; pagination: IPagination }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/${callSign}/virtual-cards`, {
          params
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getAVirtualCard(
    id: number
  ): Promise<AxiosResponse<{ data: ISIngleCard }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/${callSign}/virtual-cards/${id}`);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async editCardStatus(
    id: number,
    data: IEditViirtualCardStatus
  ): Promise<AxiosResponse<{ message: string }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.put(
          `/${callSign}/virtual-cards/${id}`,
          data
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async fundVirtualCard(
    id: number,
    data: IFundVirtualCard
  ): Promise<AxiosResponse<{ message: string }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/${callSign}/virtual-cards/${id}/fund`,
          data
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async withdrawFromVirtualCard(
    id: number,
    data: Omit<IFundVirtualCard, "walletType">
  ): Promise<AxiosResponse<{ message: string }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/${callSign}/virtual-cards/${id}/withdraw`,
          data
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async withdrawFromVirtualCardV2(
    id: number,
    data: Omit<IFundVirtualCard, "walletType">
  ): Promise<AxiosResponse<{ message: string }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instanceV2.post(
          `/${callSign}/virtual-cards/${id}/withdraw`,
          data
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getCardCreationFees(
    body: ICardFeesParam
  ): Promise<AxiosResponse<IGetCardCreationFees>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/${callSign}/virtual-cards/calculate-card-creation-fees`,
          body
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getFee(name: string): Promise<AxiosResponse<[IFee]>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/fee?name=${name}`);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getCardFundingFees(
    body: ICardFeesParam
  ): Promise<AxiosResponse<IGetCardFundingFees>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/${callSign}/virtual-cards/calculate-card-funding-fees`,
          body
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async liquidateCard(
    id: number,
    data: Pick<IFundVirtualCard, "currency" | "pin">
  ): Promise<AxiosResponse<{ message: string }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/${callSign}/virtual-cards/liquidate/${id}`,
          data
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new CardService();

import {
  IRecipient,
  IUSDBeneficiary,
  IAddRecipientBody,
  IGetRecipientsParams,
  IAddUSDBeneficiaryBody
} from "app/store/models/recipient.model";
import { AxiosResponse } from "axios";
import instance from "data/axios-setup";
import { getCallSign } from "data/utils";
import { IPagination } from "data/models/base";

class RecipientService {
  async getRecipients(
    params: IGetRecipientsParams
  ): Promise<AxiosResponse<{ data: IRecipient[]; pagination: IPagination }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const callSign = getCallSign();
        const response = await instance.get(`/${callSign}/recipient`, {
          params
        });
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async addRecipient(body: IAddRecipientBody) {
    return new Promise<AxiosResponse<{ data: any; message: string }>>(
      async (resolve, reject) => {
        try {
          const callSign = getCallSign();
          const res = await instance.post(`${callSign}/recipient`, body);
          resolve(res);
        } catch (error) {
          reject(error);
        }
      }
    );
  }

  async addUSDBeneficiary(body: IAddUSDBeneficiaryBody) {
    return new Promise<
      AxiosResponse<{ data: IUSDBeneficiary[]; message: string }>
    >(async (resolve, reject) => {
      try {
        const callSign = getCallSign();
        const res = await instance.post(`${callSign}/recipient/usd`, body);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new RecipientService();

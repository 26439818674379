import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TBankLoading, IBank, IBankState, IBankError } from "../models/bank";

const initialState: IBankState = {
  banks: [],
  error: null,
  status: {
    addBank: "idle",
    deleteBank: "idle",
    getUserBanks: "idle"
  }
};

export const BankSlice = createSlice({
  initialState,
  name: "bank",
  reducers: {
    idle: (state: IBankState, action: PayloadAction<TBankLoading>) => {
      state.status[action.payload] = "idle";
    },
    loading: (state: IBankState, action: PayloadAction<TBankLoading>) => {
      state.status[action.payload] = "loading";
    },
    error: (state: IBankState, action: PayloadAction<IBankError>) => {
      state.error = action.payload.msg;
      state.status[action.payload.for] = "failed";
    },
    setBanksInfo: (state: IBankState, action: PayloadAction<IBank[]>) => {
      state.banks = action.payload;
      state.status.getUserBanks = "succeeded";
    },
    removeBankInfo: (state: IBankState, action: PayloadAction<number>) => {
      const banks = state.banks.filter((b) => b.id !== action.payload);

      state.banks = banks;
      state.status.deleteBank = "succeeded";
    },
    addBankInfo: (state: IBankState, action: PayloadAction<IBank>) => {
      state.status.addBank = "succeeded";
      state.banks = [...state.banks, action.payload];
    }
  }
});

export const actions = BankSlice.actions;
export default BankSlice.reducer;

import {
  CreateVirtualAccountEurBody,
  CreateVirtualAccountReqBody,
  IEurFee,
  VirtualAccountResData
} from "app/store/models/virtual-accounts";
import { AxiosResponse } from "axios";
import instance, { instanceV3 } from "data/axios-setup";
import { getCallSign } from "data/utils";

class VirtualAccountService {
  async getAllVirtualAccounts(): Promise<
    AxiosResponse<{ data: VirtualAccountResData[] }>
  > {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/${callSign}/virtual-accounts`);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async createVirtualAccount(
    data: CreateVirtualAccountReqBody
  ): Promise<AxiosResponse<{ message: string }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/${callSign}/virtual-account`,
          data
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async createVirtualAccountEUR(
    data: CreateVirtualAccountEurBody
  ): Promise<AxiosResponse<{ message: string }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/${callSign}/virtual-account/gbp-eur`,
          data
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async createVirtualUSDAccount(
    data: any
  ): Promise<AxiosResponse<{ message: string }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/${callSign}/virtual-account/usd`,
          data
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async createVirtualAccountProvidus(data: {
    bvn: string;
  }): Promise<AxiosResponse<{ message: string }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/${callSign}/virtual-account/providus`,
          data
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async createVirtualAccountSafeHaven(data: {
    bvn: string;
  }): Promise<AxiosResponse<{ message: string }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/${callSign}/virtual-account/safe-haven`,
          data
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async createVirtualAccountMiden(data: {
    bvn: string;
  }): Promise<AxiosResponse<{ message: string }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/${callSign}/virtual-account/miden`,
          data
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async createVirtualAccountLucrestack(data: {
    bvn: string;
    bank: string;
    ubo: {
      id: number;
      middleName: string;
      gender: string;
      dateOfBirth: string;
    };
  }): Promise<AxiosResponse<{ message: string }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instanceV3.post(
          `/${callSign}/virtual-account`,
          data
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getSubBusinessVirtualAccounts(
    callSign: string
  ): Promise<AxiosResponse<{ data: VirtualAccountResData[] }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/${callSign}/sub-business/virtual-accounts`
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async createSubBusinessVirtualAccount(
    callSign: string,
    data: CreateVirtualAccountReqBody
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/${callSign}/sub-business/virtual-accounts`,
          data
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async createSubBusinessVirtualAccountProvidus(
    callSign: string,
    data: {
      bvn: string;
    }
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/${callSign}/sub-business/virtual-account/providus`,
          data
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async createSubBusinessVirtualAccountSafeHaven(
    callSign: string,
    data: {
      bvn: string;
    }
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/${callSign}/sub-business/virtual-accounts`,
          data
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getEurVbaFee(): Promise<AxiosResponse<{ data: IEurFee }>> {
    const callSign = getCallSign();
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/${callSign}/virtual-account/fee/gbp-eur`
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getUsdFee(): Promise<AxiosResponse<{ data: IEurFee }>> {
    const callSign = getCallSign();
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/${callSign}/virtual-account/fee/usd`
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getUSDVirtualAccountFee(): Promise<AxiosResponse<{ data: IEurFee }>> {
    const callSign = getCallSign();
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/${callSign}/virtual-account/fee/usd`
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new VirtualAccountService();

import { ReactComponent as TopLeftIcon } from "app/assets/svg/topLeft.svg";
import { ReactComponent as TopRightIcon } from "app/assets/svg/topRight.svg";
// import { ReactComponent as ButtomLeftIcon } from "app/assets/svg/leftButtom.svg";
// import { ReactComponent as ButtomRightIcon } from "app/assets/svg/rightButtom.svg";
import { ReactComponent as RightUp } from "app/assets/svg/topRightUp.svg";
import { ReactComponent as Right } from "app/assets/svg/right.svg";
export const BackDecoration = () => {
  return (
    <div className="">
      <div className="absolute top-0 left-0">
        <TopLeftIcon />
      </div>
      <div className="absolute top-0 right-0">
        <TopRightIcon />
      </div>
      <div className="absolute right-0 top-5">
        <Right />
      </div>
      <div className="absolute top-0 right-0">
        <RightUp />
      </div>
      {/* <div className="absolute bottom-0 left-0">
        <ButtomLeftIcon />
      </div>
      <div className="absolute bottom-0 right-0">
        <ButtomRightIcon />
      </div> */}
    </div>
  );
};

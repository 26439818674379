import {
  encodeAssertResponse,
  encodeAttestationResponse
} from "data/utils/webauthn";
import { AxiosResponse } from "axios";
import instance from "data/axios-setup";
import { T2FASetup } from "app/store/models/account";

class AuthService {
  async logout(): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.put(`/auth/logout`);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async webAuthnAttestationBegin(): Promise<
    AxiosResponse<{ data: PublicKeyCredentialCreationOptions }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get("/webauthn/attestate/begin");
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async webAuthnAssertionBegin(
    email: string
  ): Promise<AxiosResponse<{ data: PublicKeyCredentialRequestOptions }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/webauthn/assert/begin/${email}`);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async webAuthnAttestationEnd(
    credential: PublicKeyCredential
  ): Promise<AxiosResponse<PublicKeyCredentialCreationOptions>> {
    return new Promise(async (resolve, reject) => {
      try {
        const body = encodeAttestationResponse(credential);
        const res = await instance.post("/webauthn/attestate/end", body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async webAuthnAssertionEnd(
    email: string,
    credential: PublicKeyCredential
  ): Promise<
    AxiosResponse<{
      token: string;
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const body = encodeAssertResponse(email, credential);
        const res = await instance.post("/webauthn/assert/end", body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async disableWebAuthn(code: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.delete(`/webauthn/disable/${code}`);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async login2FA(body: { code: string; email: string }): Promise<
    AxiosResponse<{
      token: string;
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post("/auth/login-two-fa", body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async setup2FA(email: string): Promise<
    AxiosResponse<{
      data: T2FASetup;
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post("/auth/generate-two-fa", { email });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async disable2FA(pin: string): Promise<
    AxiosResponse<{
      data: T2FASetup;
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.patch("/auth/disable-two-fa", { pin });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new AuthService();

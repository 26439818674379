import { InputGroup, InputLeftElement, Button, Input } from "@chakra-ui/react";
import { UilEnvelope } from "@iconscout/react-unicons";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { forgetPassword } from "app/store";
import { useEffect, useState } from "react";

export const ForgetPasswordContainer = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.auth.loading);
  const emailSent = useAppSelector((state) => state.auth.passwordResetLintSent);
  const [values, setValues] = useState({
    email: ""
  });
  const { email } = values;
  const handleChange =
    (name: any) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setValues({ ...values, [name]: e.target.value });
    };
  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(forgetPassword(values));
    setCounter(59);
  };

  const [counter, setCounter] = useState(59);
  useEffect(() => {
    const timer = setInterval(() => {
      setCounter(counter - 1);
    }, 1000);
    return () => clearInterval(timer);
  });
  return (
    <div className="w-11/12 p-6 bg-gray-100 mdx:w-5/12 sm:w-9/12">
      <p className="text-gray-900 text-heading100 mdx:text-heading300">
        Forgot Password
      </p>
      <p className="mt-1 text-gray-700">
        No worries! Enter your registered email address and we’ll send you a
        link to reset your password
      </p>
      <div className="mt-4 mdx:mt-10 authForm">
        <form onSubmit={handleSubmit}>
          <div className="my-4">
            <InputGroup className="items-center">
              <InputLeftElement
                top="auto"
                pointerEvents="none"
                children={<UilEnvelope color="#B1B4BF" />}
              />
              <Input
                value={email}
                onChange={handleChange("email")}
                focusBorderColor="none"
                type="email"
                placeholder="Email"
                color="#444B59"
                isRequired
                _placeholder={{ opacity: 1, color: "#667085" }}
              />
            </InputGroup>
          </div>
          <div className="mt-4 mdx:mt-8">
            <Button
              isDisabled={emailSent && counter > 1}
              isLoading={isLoading}
              className="bg-primary-600"
              type="submit"
              textColor="white"
              bgColor="#111834"
              w="full"
              padding="6"
              _hover={{ bgColor: "#3B435E" }}
            >
              {" "}
              Send Link
            </Button>
          </div>
        </form>
      </div>

      <div>
        {emailSent && (
          <>
            {counter < 1 ? (
              <>{}</>
            ) : (
              <p className="mt-3 font-light text-center text-primary-600">
                Didn’t receive a link?{" "}
                <span className="font-bold">Resend in 00:{counter}</span>
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

import {
  Button,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useDisclosure
} from "@chakra-ui/react";
import { UilEnvelope } from "@iconscout/react-unicons";
import { UilPadlock } from "@iconscout/react-unicons";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as RoundIcon } from "app/assets/svg/roundIcon.svg";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { loginUser } from "app/store";
import { FingerScan } from "iconsax-react";
import PasswordlessLogin from "./passwordless-login";
import { encryptKey } from "data/utils/encryption";
import { configs } from "data/config";

// interface LocationState {
//   from?: {
//     pathname?: string;
//   };
// }
export const LoginContainer = () => {
  const navigate = useNavigate();
  const {
    isOpen: isOpenPasswordlessLogin,
    onOpen: onOpenPasswordlessLogin,
    onClose: onClosePasswordlessLogin
  } = useDisclosure();
  // const location = useLocation();
  // const from = (location.state as LocationState)?.from?.pathname || "/";
  const dispatch = useAppDispatch();
  const [isLoading] = useAppSelector((state) => [state.auth.loading]);

  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const inviteEmail = searchParams.get("email");
  const teamInvite = searchParams.get("teaminvite");
  const [values, setValues] = useState({
    email: teamInvite ? inviteEmail! : "",
    password: ""
  });

  const { email, password } = values;
  const handleChange =
    (name: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [name]: e.target.value });
    };

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    let { password, email } = values;
    const encryptedPassword = encryptKey(password);
    const data = { email, password: encryptedPassword };

    dispatch(loginUser(navigate, data, teamInvite, token));
  };

  useEffect(() => {
    setValues({ ...values });
  }, []);

  return (
    <>
      <div className="w-11/12 p-4 bg-gray-100 lg:p-8 mdx:w-5/12 sm:w-9/12">
        <p className="text-gray-900 text-heading100 mdx:text-heading300">
          Welcome Back!
        </p>

        {teamInvite ? (
          <>
            <p className="mt-1 text-gray-700">
              Sign in to your Changera Business account to access Businesses you
              have been invited to.
            </p>
          </>
        ) : (
          <>
            <p className="mt-1 text-gray-700">
              Enter your details below to sign into your Changera account
            </p>
          </>
        )}

        <div className="mt-6 mdx:mt-10 authForm">
          <form onSubmit={handleSubmit}>
            <div className="my-4">
              <InputGroup className="items-center">
                <InputLeftElement
                  top="auto"
                  pointerEvents="none"
                  children={<UilEnvelope color="#B1B4BF" />}
                />
                <Input
                  focusBorderColor="none"
                  type="email"
                  placeholder="Email"
                  size="lg"
                  isDisabled={teamInvite ? true : false}
                  color="#444B59"
                  value={email}
                  onChange={handleChange("email")}
                  name="email"
                  isRequired
                  _placeholder={{ opacity: 1, color: "#667085" }}
                />
              </InputGroup>
            </div>
            <div className="my-4">
              <InputGroup className="items-center">
                <InputLeftElement
                  top="auto"
                  pointerEvents="none"
                  children={<UilPadlock color="#B1B4BF" />}
                />
                <Input
                  focusBorderColor="none"
                  type={show ? "text" : "password"}
                  placeholder="Password"
                  size="lg"
                  name="password"
                  color="#667085"
                  isRequired
                  value={password}
                  onChange={handleChange("password")}
                  _placeholder={{ opacity: 1, color: "#667085" }}
                />
                <InputRightElement width="4.5rem" top="auto">
                  <Button size="sm" onClick={handleClick}>
                    {show ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </div>
            <div>
              <Link to="/auth/forgot-password">
                <p className="text-sm font-bold text-right lg:text-base text-primary-600">
                  Forgot Password?
                </p>
              </Link>
            </div>
            <div className="flex gap-4 mt-8 mdx:mt-12">
              <Button
                isDisabled={!values.email || !values.password}
                isLoading={isLoading}
                className="mdx:p-6 bg-primary-600"
                textColor="white"
                type="submit"
                bgColor="#111834"
                w="full"
                _hover={{ bgColor: "#3B435E" }}
              >
                <div className="flex items-center">
                  <p>Sign In</p>
                  <RoundIcon className="ml-2" />
                </div>
              </Button>

              {configs.NODE_ENV !== "production" ? (
                <IconButton
                  w={[16, 20, 24]}
                  className="mdx:!h-12"
                  colorScheme="primary"
                  _hover={{ bgColor: "#3B435E" }}
                  aria-label="passwordless login"
                  onClick={onOpenPasswordlessLogin}
                >
                  <FingerScan />
                </IconButton>
              ) : null}
            </div>
          </form>
        </div>

        <div>
          <p className="mt-3 text-sm font-light text-center lg:text-base text-primary-600">
            New to Changera?{" "}
            <span className="font-bold">
              <span className="font-bold">
                <Link to={"/auth/register"}>Create account here</Link>
              </span>
            </span>
          </p>
        </div>
      </div>

      <PasswordlessLogin
        isOpen={isOpenPasswordlessLogin}
        onClose={onClosePasswordlessLogin}
      />
    </>
  );
};

import { Avatar } from "@chakra-ui/react";
import ReactCountryFlag from "react-country-flag";

const usdtAvatar =
  "https://changera-biz-assets.fra1.cdn.digitaloceanspaces.com/usdt.svg";
const cusdAvatar =
  "https://changera-biz-assets.fra1.cdn.digitaloceanspaces.com/cusd.svg";
const usdcAvatar =
  "https://changera-biz-assets.fra1.cdn.digitaloceanspaces.com/usdc.svg";

/**
 * Custom hook to get a changera supported country flag or crypto symbol
 * @returns a function "CurrencyFlag" that is called to get the flags or currency
 */
export const useCurrencyFlag = () => {
  /**
   * Returns a changera supported country flag or crypto symbol
   * @param currency - A supported fiat or crypto currency code
   * @returns - country flag or crypto symbol
   */
  const CurrencyFlag = (currency: string = "") => {
    return currency.toLowerCase() === "usdt" ? (
      <Avatar src={usdtAvatar} h="5" w="5" fontSize="xs" />
    ) : currency.toLowerCase() === "usdc" ? (
      <Avatar src={usdcAvatar} h="5" w="5" fontSize="xs" />
    ) : currency.toLowerCase() === "cusd" ? (
      <Avatar src={cusdAvatar} h="5" w="5" fontSize="xs" />
    ) : currency.toLowerCase() === "usdt-tron" ? (
      <Avatar src={usdtAvatar} h="5" w="5" fontSize="xs" />
    ) : (
      <ReactCountryFlag
        countryCode={currency.substring(0, 2).toUpperCase()}
        svg
        style={{
          height: "18px",
          borderRadius: "4px"
        }}
      />
    );
  };

  return CurrencyFlag;
};

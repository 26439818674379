import {
  SendMoneyState,
  SendMoneyError,
  SendMoneyLoading,
  SendMoneyModalState,
  SendMoneyDrawerState,
  IGetExchangeRate
} from "../models/send-money";
import { supportedCountries } from "data/utils";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: SendMoneyState = {
  fee: 0,
  error: null,
  status: {
    sendMoney: "idle",
    getSendMoneyFee: "idle",
    getExchangeRate: "idle"
  },
  drawer: {
    amount: 0,
    isOpen: false,
    view: "method",
    wallet: undefined,
    recipient: undefined,
    method: "bank-account",
    recipientCountry: supportedCountries?.[0]
  },
  modal: { isOpen: false, view: "transaction-pin" },
  exchangeRate: { amount: 0, destinationAmount: 0, rate: 0 }
};

export const SendMoneySlice = createSlice({
  initialState,
  name: "send-money",
  reducers: {
    loading: (
      state: SendMoneyState,
      action: PayloadAction<SendMoneyLoading>
    ) => {
      state.status[action.payload] = "loading";
    },

    resetState: (state: SendMoneyState) => {
      state.fee = initialState.fee;
      state.modal = initialState.modal;
      state.drawer = initialState.drawer;
      state.status = initialState.status;
      state.exchangeRate = initialState.exchangeRate;
    },

    resetDrawerState: (state: SendMoneyState) => {
      state.drawer = initialState.drawer;
    },

    setModalState: (
      state: SendMoneyState,
      action: PayloadAction<Partial<SendMoneyModalState>>
    ) => {
      state.modal.isOpen =
        action.payload.isOpen !== undefined
          ? action.payload.isOpen
          : state.modal.isOpen;
      state.modal.view = action.payload.view || state.modal.view;
    },

    setDrawerState: (
      state: SendMoneyState,
      action: PayloadAction<Partial<SendMoneyDrawerState>>
    ) => {
      state.drawer = { ...state.drawer, ...action.payload };
    },

    setFee: (state: SendMoneyState, action: PayloadAction<number>) => {
      state.fee = action.payload;
      state.status.getSendMoneyFee = "succeeded";
    },

    setExchangeRate: (
      state: SendMoneyState,
      action: PayloadAction<IGetExchangeRate>
    ) => {
      state.exchangeRate = action.payload;
      state.status.getExchangeRate = "succeeded";
    },

    error: (state: SendMoneyState, action: PayloadAction<SendMoneyError>) => {
      state.error = action.payload.msg;
      state.status[action.payload.for] = "failed";
    }
  }
});

export const actions = SendMoneySlice.actions;

export default SendMoneySlice.reducer;

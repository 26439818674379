import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPagination } from "data/models/base";

interface ConvertFundsState {
  conversions: {
    data: any[];
    pagination: IPagination;
  };
  converting: boolean;
  convertSuccess: boolean;
  gettingTransactionFee: boolean;
  transactionFee: number;
  openDrawer: boolean;
  closeDrawer: boolean;
  gettingConversions: boolean;
}

const initialState: ConvertFundsState = {
  converting: false,
  convertSuccess: false,
  gettingTransactionFee: false,
  transactionFee: 0,
  openDrawer: false,
  closeDrawer: false,
  gettingConversions: false,
  conversions: {
    data: [],
    pagination: {
      next: 2,
      total: 0,
      prevPage: 0,
      lastPage: 0,
      pageSize: 10,
      hasNext: true,
      currentPage: 1,
      hasPrevious: false
    }
  }
};

export const ConvertFundsSlice = createSlice({
  name: "airtimeAndData",
  initialState,
  reducers: {
    error: (state: ConvertFundsState) => {
      state.converting = false;
    },
    converting: (state: ConvertFundsState) => {
      state.converting = true;
    },
    convertSuccess: (state: ConvertFundsState) => {
      state.convertSuccess = true;
      state.converting = false;
    },

    gettingConversions: (
      state: ConvertFundsState,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.gettingConversions =
        action?.payload !== undefined ? action?.payload : true;
    },

    settingConversions: (state: ConvertFundsState, action) => {
      state.conversions.data = action.payload.data;
      state.conversions.pagination = action.payload.pagination;
      state.gettingConversions = false;
    },

    gettingTransactionFee: (state: ConvertFundsState) => {
      state.gettingTransactionFee = true;
    },
    settingTransactionFee: (state: ConvertFundsState, action) => {
      state.gettingTransactionFee = false;
      state.transactionFee = action.payload;
    },
    openConvertDrawer: (state: ConvertFundsState) => {
      state.openDrawer = true;
    },
    closeConvertDrawer: (state: ConvertFundsState) => {
      state.openDrawer = false;
    }
  }
});

export const actions = ConvertFundsSlice.actions;

export default ConvertFundsSlice.reducer;

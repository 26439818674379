import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Team, TeamManagementState } from "../models/team-management";
const pagination = {
  next: 0,
  total: 0,
  prevPage: 0,
  lastPage: 0,
  pageSize: 10,
  hasNext: false,
  currentPage: 1,
  hasPrevious: false
};

const initialState: TeamManagementState = {
  teamAudit: null,
  teams: null,
  pagination: pagination,
  teamAuditPagination: pagination,
  loading: false,
  createTeammateSuccess: false,
  team: null,
  error: "",
  changingRole: false,
  assigning: false,
  assignSuccess: false,
  deleteSuccess: false,
  deleting: false,
  gettingTeam: false,
  gettingAllTeams: false,
  acceptingInvite: true,
  gettingTeamAudit: false
};

export const TeamManagementSlice = createSlice({
  name: "teammanagement",
  initialState,
  reducers: {
    error: (state: TeamManagementState) => {
      state.loading = false;
      state.deleting = false;
      state.gettingAllTeams = false;
      state.gettingTeam = false;
      state.assigning = false;
      state.acceptingInvite = false;
      state.gettingTeamAudit = false;
      state.changingRole = false;
    },
    gettingAllTeams: (state: TeamManagementState) => {
      state.gettingAllTeams = true;
    },
    gettingTeams: (state: TeamManagementState) => {
      state.loading = true;
    },
    gettingSingleTeam: (state: TeamManagementState) => {
      state.gettingTeam = true;
    },
    gettingTeamAudit: (state: TeamManagementState) => {
      state.gettingTeamAudit = true;
    },
    changingRole: (state: TeamManagementState) => {
      state.changingRole = true;
    },
    roleChanged: (state: TeamManagementState) => {
      state.changingRole = false;
    },
    createTeammateSuccess: (state: TeamManagementState) => {
      state.createTeammateSuccess = true;
      state.loading = false;
    },
    settingTeams: (state: TeamManagementState, action: PayloadAction<any>) => {
      state.teams = action.payload.data;
      state.pagination = action.payload.pagination;
      state.loading = false;
      state.gettingAllTeams = false;
    },
    settingSingleTeam: (
      state: TeamManagementState,
      action: PayloadAction<Team>
    ) => {
      state.team = action.payload;
      state.gettingTeam = false;
    },
    settingTeamAudit: (state: TeamManagementState, action: any) => {
      state.teamAudit = action.payload.data;
      state.gettingTeamAudit = false;
      state.teamAuditPagination = action.payload.pagination;
    },
    assigningFeature: (state: TeamManagementState) => {
      state.assigning = true;
    },
    featureAssignSUccess: (state: TeamManagementState) => {
      state.assignSuccess = true;
      state.assigning = false;
    },
    deletingTeamMate: (state: TeamManagementState) => {
      state.deleting = true;
    },
    deleteTeamMateSuccess: (state: TeamManagementState) => {
      state.deleteSuccess = true;
      state.deleting = false;
    },
    acceptedInvite: (state: TeamManagementState) => {
      state.acceptingInvite = false;
    }
  }
});

export const actions = TeamManagementSlice.actions;
export default TeamManagementSlice.reducer;

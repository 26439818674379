import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { CardModalState, CardType, VirtualCardBrand } from "../models/card";
import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/card.reducer";
import { DEFAULT_ERROR_MESSAGE, onResponseSuccess } from "data/error-mapping";
import virtualCardsService from "data/services/virtual-cards.service";
import {
  ICardFeesParam,
  IGetVirtualCardsParam
} from "data/models/virtual-cards";

export const createCard = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.createCard());
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "createCard" }));
    }
  };
};

export const withdrawFund = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.withdrawFund());
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "createCard" }));
    }
  };
};

export const liquidateCard = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.liquidateCard());
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "createCard" }));
    }
  };
};

export const fundCard = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fundCard());
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "createCard" }));
    }
  };
};

export const deactivateCard = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setDeactivateCard());
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "createCard" }));
    }
  };
};

export const reactivateCard = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setReactivateCard());
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "createCard" }));
    }
  };
};

export const setAcceptCardTerms = (val?: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.acceptCardTerms(val));
  };
};

export const resetCardDrawerState = () => {
  return (dispatch: Dispatch) => {
    dispatch(actions.resetDrawerState());
  };
};

export const resetCardModalState = () => {
  return (dispatch: Dispatch) => {
    dispatch(actions.resetModalState());
  };
};

export const setCardModalState = (value: Partial<CardModalState>) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setModalState(value));
  };
};

export const openCardDetailsDrawer = (id: number) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setDrawerState({ isOpen: true, action: "details", id }));
  };
};

export const openRequestCardDrawer = (type?: CardType) => {
  return (dispatch: Dispatch) => {
    dispatch(
      actions.setDrawerState({
        isOpen: true,
        action: "request",
        type: type ?? "virtual"
      })
    );
  };
};

export const setCardBrand = (brand: VirtualCardBrand) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setCardState(brand));
  };
};

export const openWithdrawDrawer = (type?: CardType) => {
  return (dispatch: Dispatch) => {
    dispatch(
      actions.setDrawerState({
        action: "withdraw",
        type: type ?? "virtual"
      })
    );
  };
};

export const openFundCardDrawer = (type?: CardType) => {
  return (dispatch: Dispatch) => {
    dispatch(
      actions.setDrawerState({
        action: "fund",
        type: type ?? "virtual"
      })
    );
  };
};

export const openLiquidateDrawer = (type?: CardType) => {
  return (dispatch: Dispatch) => {
    dispatch(
      actions.setDrawerState({
        action: "liquidate",
        type: type ?? "virtual"
      })
    );
  };
};

// API actions
export const getAllVirtualCards = (params: Partial<IGetVirtualCardsParam>) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllVirtualCardsBegin());
      const res = await virtualCardsService.getAllVirtualCards(params);
      onResponseSuccess(res, () => {
        dispatch(
          actions.getAllVirtualCardsSuccess({
            data: res.data.data,
            pagination: res.data.pagination
          })
        );
      });
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      dispatch(actions.getAllVirtualCardsError(msg));
      toast.error(msg);
    }
  };
};

export const getSingleVirtualCard = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getSingleVirtualCardBegin());
      const res = await virtualCardsService.getAVirtualCard(id);
      onResponseSuccess(res, () => {
        dispatch(
          actions.getSingleVirtualCardSuccess({
            data: res.data.data
          })
        );
      });
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      dispatch(actions.getSingleVirtualCardError(msg));
      toast.error(msg);
    }
  };
};

export const getCardCreationFees = (params: ICardFeesParam) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getCardCreationFeesBegin());
      const res = await virtualCardsService.getCardCreationFees(params);
      onResponseSuccess(res, () => {
        dispatch(
          actions.getCardCreationFeesSuccess({
            data: res.data
          })
        );
      });
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      dispatch(actions.getCardCreationFeesError(msg));
      toast.error(msg);
    }
  };
};

export const getCardFundingFees = (params: ICardFeesParam) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getCardFundingFeesBegin());
      const res = await virtualCardsService.getCardFundingFees(params);
      onResponseSuccess(res, () => {
        dispatch(
          actions.getCardFundingFeesSuccess({
            data: res.data
          })
        );
      });
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      dispatch(actions.getCardFundingFeesError(msg));
      toast.error(msg);
    }
  };
};

export const getCardFees = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getCardFeesBegin());
      const [creation, funding] = await Promise.all([
        await virtualCardsService.getFee("card-creation-fee"),
        await virtualCardsService.getFee("card-funding-fee")
      ]);

      dispatch(
        actions.getCardFeesSuccess({
          funding: funding.data?.[0],
          creation: creation.data?.[0]
        })
      );
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      dispatch(actions.getCardFeesError(msg));
      toast.error(msg);
    }
  };
};

/* DIGITAL OCEAN FILE UPLOAD SETUP */
import S3 from "react-aws-s3-typescript";
import { s3Config } from ".";
const client = new S3(s3Config);

export default async function uploadFile(
  file: File | any,
  fileName: string | null = null
) {
  return await client.uploadFile(file, fileName ?? file.name);
}

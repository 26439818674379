/* AWS S3 config options */
/* Highly recommended to declare the config object in an external file import it when needed */

/* s3Config.ts */

import { configs } from "data/config";

export const s3Config = {
  bucketName: configs.DO_BUCKET_NAME!,
  region: configs.DO_SPACES_REGION!,
  accessKeyId: configs.DO_SPACES_ACCESS_KEY!,
  secretAccessKey: configs.DO_SPACES_SECRET_ACCESS_KEY!,
  s3Url: configs.DO_SPACES_URL /* Optional */
};

/* End of s3Config.ts */

import { useCallback, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

const useInlineScript = (scriptAttrTag: string) => {
  const loaded = useRef(false);
  const scriptId = useRef("");

  const injectScript = useCallback(
    (props: { inlineScript: string }) => {
      let script: HTMLScriptElement | null = null;

      let existingInjectedScript = document.querySelector(
        `script[inline-attr='${scriptAttrTag}']`
      ) as HTMLScriptElement;
      if (existingInjectedScript) {
        existingInjectedScript.innerHTML = props.inlineScript;
        script = existingInjectedScript;
        scriptId.current = script.id;
        loaded.current = true;
      } else {
        scriptId.current = uuidv4();
        const script = document.createElement("script");
        if (!loaded.current) {
          script.id = scriptId.current;
          script.setAttribute("inline-attr", scriptAttrTag);
          script.setAttribute("charset", "utf-8");
          script.type = "text/javascript";
          script.innerHTML = props.inlineScript;
          document.body.appendChild(script);
          loaded.current = true;
        }
      }
      return script;
    },
    [scriptAttrTag]
  );

  // useEffect(() => {
  //   if (skip === true) {
  //   } else {
  //     const script = injectScript();

  //     return () => {
  //       if (loaded.current && script) document.body.removeChild(script);
  //     };
  //   }
  // }, [inlineScript, skip, scriptAttrTag, injectScript]);

  const removeScript = useCallback(() => {
    let scriptInjected = document.querySelector(`script#${scriptId}`);
    if (scriptInjected) {
      scriptInjected.parentNode?.removeChild(scriptInjected);
    }
  }, []);

  return {
    scriptId,
    injectScript,
    removeScript
  };
};

export default useInlineScript;

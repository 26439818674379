import { createSlice } from "@reduxjs/toolkit";
import { IPagination } from "data/models/base";
import {
  DiscountCode,
  Event,
  ITicketDetails,
  Metrics,
  RefundedTicket,
  Ticket
} from "../models/events";

const pagination: IPagination = {
  next: 2,
  total: 0,
  prevPage: 0,
  lastPage: 0,
  pageSize: 10,
  hasNext: true,
  currentPage: 1,
  hasPrevious: false
};

interface EventsState {
  allEvents: Event[];
  gettingEvents: boolean;
  error: boolean;
  loading: boolean;
  event: Event | null;
  createEventSuccess: boolean;
  addedTickets: boolean;
  publishEventSuccess: boolean;
  creatingDraft: boolean;
  gettingMetrics: boolean;
  metrics: Metrics | null;
  tickets: { data: Ticket[]; pagination: IPagination };
  refundedTickets: { data: RefundedTicket[]; pagination: IPagination };
  gettingTickets: boolean;
  refundingTicket: boolean;
  ticketAmount: string;
  ticketDetails: ITicketDetails | null;
  gettingTicketDetails: boolean;
  verifyingTicket: boolean;
  generatingCouponCode: boolean;
  couponCode: string;
  creatingDiscountCode: boolean;
  gettingAllDiscountCodes: boolean;
  allDiscountCodes: DiscountCode[] | [];
  allDiscountCodesPagination: IPagination;
}

const initialState: EventsState = {
  allEvents: [],
  gettingEvents: false,
  error: false,
  event: null,
  loading: false,
  createEventSuccess: false,
  addedTickets: true,
  publishEventSuccess: false,
  creatingDraft: false,
  gettingMetrics: false,
  metrics: null,
  tickets: { data: [], pagination: pagination },
  refundedTickets: { data: [], pagination: pagination },
  gettingTickets: false,
  refundingTicket: false,
  ticketAmount: "",
  gettingTicketDetails: false,
  ticketDetails: null,
  verifyingTicket: false,
  generatingCouponCode: false,
  couponCode: "",
  creatingDiscountCode: false,
  gettingAllDiscountCodes: false,
  allDiscountCodes: [],
  allDiscountCodesPagination: pagination
};

export const EventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    error: (state: EventsState) => {
      state.gettingEvents = false;
      state.error = true;
      state.loading = false;
      state.creatingDraft = false;
      state.gettingTickets = false;
      state.gettingMetrics = false;
      state.refundingTicket = false;
      state.gettingTicketDetails = false;
      state.verifyingTicket = false;
      state.generatingCouponCode = false;
      state.creatingDiscountCode = false;
      state.gettingAllDiscountCodes = false;
    },

    gettingEvent: (state: EventsState) => {
      state.gettingEvents = true;
    },
    gettingTickets: (state: EventsState) => {
      state.gettingTickets = true;
    },
    settingTickets: (state: EventsState, action) => {
      state.tickets.data = action.payload.data;
      state.tickets.pagination = action.payload.pagination;
      state.gettingTickets = false;
    },
    settingTicketsRefund: (state: EventsState, action) => {
      state.refundedTickets.data = action.payload.data;
      state.refundedTickets.pagination = action.payload.pagination;
      state.gettingTickets = false;
    },
    gettingMetrics: (state: EventsState) => {
      state.gettingMetrics = true;
    },
    settingMetrics: (state: EventsState, action) => {
      state.gettingMetrics = false;
      state.metrics = action.payload;
    },
    gettingTicketDetails: (state: EventsState) => {
      state.gettingTicketDetails = true;
    },
    settingTicketDetails: (state: EventsState, action) => {
      state.gettingTicketDetails = false;
      state.ticketDetails = action.payload;
    },
    verifyingTicket: (state: EventsState) => {
      state.verifyingTicket = true;
    },
    verifiedTicket: (state: EventsState) => {
      state.verifyingTicket = false;
    },
    settingAllEvents: (state: EventsState, action) => {
      state.allEvents = action.payload;
      state.gettingEvents = false;
    },
    settingEvents: (state: EventsState, action) => {
      state.event = action.payload;
      state.gettingEvents = false;
    },
    resetSingleEvent: (state: EventsState) => {
      state.event = null;
      state.publishEventSuccess = false;
    },
    loadingBegin: (state: EventsState) => {
      state.loading = true;
    },
    createEventSuccess: (state: EventsState) => {
      state.createEventSuccess = true;
      state.loading = false;
      state.error = false;
      state.creatingDraft = false;
    },
    publishEventSuccess: (state: EventsState) => {
      state.publishEventSuccess = true;
      state.loading = false;
      state.error = false;
    },
    addedTickets: (state: EventsState) => {
      state.addedTickets = true;
      state.loading = false;
    },
    deletingTicket: (state: EventsState) => {
      state.loading = true;
    },
    deletedTicket: (state: EventsState) => {
      state.loading = false;
    },
    creatingDraft: (state: EventsState) => {
      state.creatingDraft = true;
    },
    refundingTicket: (state: EventsState) => {
      state.refundingTicket = true;
    },
    refundedTicket: (state: EventsState) => {
      state.refundingTicket = false;
    },
    gottenTicketAmount: (state: EventsState, action) => {
      state.ticketAmount = action.payload.data;
    },
    generatingCouponCode: (state: EventsState) => {
      state.generatingCouponCode = true;
    },
    setCouponCode: (state: EventsState, action) => {
      state.couponCode = action.payload;
      state.generatingCouponCode = false;
    },
    creatingDiscountCode: (state: EventsState) => {
      state.creatingDiscountCode = true;
    },
    createdDiscountCode: (state: EventsState) => {
      state.creatingDiscountCode = false;
      state.allDiscountCodes;
    },
    gettingAllDiscountCodes: (state: EventsState) => {
      state.gettingAllDiscountCodes = true;
    },
    settingAllDiscountCodes: (state: EventsState, action) => {
      state.allDiscountCodes = action.payload.data;
      state.allDiscountCodesPagination = action.payload.pagination;
      state.gettingAllDiscountCodes = false;
    }
  }
});

export const actions = EventsSlice.actions;
export default EventsSlice.reducer;

export const industries = [
  {
    value: "Manufacturing"
  },
  {
    value: "Wholesale and Retail Trade"
  },
  {
    value: "Accommodation and Food Service Activities "
  },
  {
    value: "Mining and Quarrying "
  },
  {
    value: "Construction"
  },
  {
    value: "Water Supply, Sewerage, Waste Management and Remediation"
  },
  {
    value: "Agriculture, Forestry, Fishing and Hunting"
  },
  {
    value: "Transport, Storage and Logistics"
  },
  {
    value: "Technology"
  },
  {
    value: "Administrative and Support Services Activities"
  },
  {
    value: "Education"
  },
  {
    value: "Art, Entertainment and Recreation"
  },
  {
    value: "Fashion"
  },
  {
    value: "E-commerce"
  },
  {
    value: "Travel and Tours"
  },
  {
    value: "Others"
  }
];

import { AxiosError } from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/wallet.reducer";
import { getWalletsWithVirtualAccount } from "data/utils";
import walletsService from "data/services/wallets.service";
import { CreateCryptoWalletParams } from "../models/wallet";
import virtualAccountService from "data/services/virtual-account.service";
import { actions as vAccActions } from "../reducers/virtual-accounts.reducer";
import { DEFAULT_ERROR_MESSAGE, onResponseSuccess } from "data/error-mapping";

export const getAllFiatWallets = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchFiatWalletsBegin());
      dispatch(vAccActions.getAllVirtualAccountsBegin());
      const [res, vAccRes] = await Promise.all([
        walletsService.getAllFiatWallets(),
        virtualAccountService.getAllVirtualAccounts()
      ]);
      // format wallet to include the virtual accounts before dispatching the data
      const walletRes = getWalletsWithVirtualAccount(
        res.data.data,
        vAccRes.data.data
      );
      dispatch(actions.fetchFiatWalletsSuccess(walletRes));
      dispatch(vAccActions.getAllVirtualAccountsSuccess(vAccRes.data.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      dispatch(actions.fetchFiatWalletsError(msg));
    }
  };
};

export const getAllCryptoWallets = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchCryptoWalletsBegin());
      const res = await walletsService.getAllCryptoWalletsV2();
      onResponseSuccess(res, () => {
        dispatch(actions.fetchCryptoWalletsSuccess(res.data.data));
      });
    } catch (error: any) {
      dispatch(
        actions.fetchCryptowalletError(
          error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
        )
      );
    }
  };
};

export const getSingleFiatWallet = (currency: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchSingleWalletetsBegin());
      const res = await walletsService.getSingleFiatWallet(currency);
      onResponseSuccess(res, () => {
        dispatch(actions.fetchSingleWalletSuccess(res.data.data));
      });
    } catch (error: any) {
      dispatch(
        actions.fetchSingleWalletError(
          error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
        )
      );
    }
  };
};

export const getSingleCryptoWallet = (params: CreateCryptoWalletParams) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchSingleWalletetsBegin());
      const res = await walletsService.getSingleCryptoWalletV2(params);
      onResponseSuccess(res, () => {
        dispatch(actions.fetchSingleWalletSuccess(res.data.data));
      });
    } catch (error: any) {
      dispatch(
        actions.fetchSingleWalletError(
          error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
        )
      );
    }
  };
};

export const onFundWalletModalOpen = (wallet: string) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.onFundWalletModalOpen(wallet));
  };
};

export const onFundWalletModalClose = () => {
  return (dispatch: Dispatch) => {
    dispatch(actions.onFundWalletModalClose());
  };
};

export const onSelectFundWalletModalOpen = () => {
  return (dispatch: Dispatch) => {
    dispatch(actions.onSelectFundWalletModalOpen());
  };
};

export const onSelectFundWalletModalClose = () => {
  return (dispatch: Dispatch) => {
    dispatch(actions.onSelectFundWalletModalClose());
  };
};

export const setCreateSubWalletStatus = (value: string) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setCreateSubWalletStatus(value));
  };
};

export const setSelectedFiatWallet = (value: string) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setSelectedFiatWallet(value));
  };
};

export const setFiatWallet = (value: any) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setSelectedFiatWallet(value));
  };
};

export const setSelectedCryptoWallet = (
  value: CreateCryptoWalletParams | null
) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setSelectedCryptoWallet(value));
  };
};

export const getAllSubBusinessCryptoWallets = (callSign: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchSubBusinessCryptoWalletsBegin());
      const res = await walletsService.getAllSubBusinessCryptoWallets(callSign);
      onResponseSuccess(res, () => {
        dispatch(actions.fetchSubBusinessCryptoWalletsSuccess(res.data.data));
      });
    } catch (error: any) {
      dispatch(
        actions.fetchSubBusinessCryptowalletError(
          error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
        )
      );
    }
  };
};

export const getAllSubBusinessFiatWallets = (callSign: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchSubBusinessFiatWalletsBegin);
      const res = await walletsService.getAllSubBusinessFiatWallets(callSign);
      dispatch(actions.fetchSubBusinessFiatWalletsSuccess(res.data.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      dispatch(actions.fetchSubBusinessFiatWalletsError(msg));
    }
  };
};
export const getSingleSubBusinessFiatWallet = (
  callSign: string,
  currency: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchSubBusinessSingleWalletsBegin());
      const res = await walletsService.getSubBusinessSingleFiatWallet(
        callSign,
        currency
      );
      onResponseSuccess(res, () => {
        dispatch(actions.fetchSubBusinessSingleWalletSuccess(res.data.data));
      });
    } catch (error: any) {
      dispatch(
        actions.fetchSubBusinessSingleWalletError(
          error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
        )
      );
    }
  };
};

export const getSingleSubBusinessCryptoWallet = (
  callSign: string,
  params: CreateCryptoWalletParams
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchSubBusinessSingleWalletsBegin());
      const res = await walletsService.getSingleSubBusinessCryptoWallet(
        callSign,
        params
      );
      onResponseSuccess(res, () => {
        dispatch(actions.fetchSubBusinessSingleWalletSuccess(res.data.data));
      });
    } catch (error: any) {
      dispatch(
        actions.fetchSubBusinessSingleWalletSuccess(
          error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
        )
      );
    }
  };
};

import { Outlet } from "react-router-dom";
import { ReactComponent as TopRightIcon } from "app/assets/svg/backRound.svg";

const OpenSecuredLayout = () => {
  return (
    <div className="relative">
      <div className="h-screen overflow-y-scroll">
        <Outlet />
        <div className="hidden lg:block absolute bottom-0 left-0">
          <TopRightIcon />
        </div>
      </div>
    </div>
  );
};

export default OpenSecuredLayout;

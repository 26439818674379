import {
  Pin,
  Amount,
  Method,
  WalletType,
  TWithdrawalLoading,
  USDWithdrawalBody,
  TCryptoWithdrawalNetworkFees,
  SubWalletWithdrawalRequestData,
  FiatWalletWithdrawalRequestBody,
  CryptoWalletWithdrawalRequestBody
} from "../models/withdrawal";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/withdrawal.reducer";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import withdrawalService from "data/services/withdrawal.service";
import { actions as walletActions } from "../reducers/wallet.reducer";

export const fiatWalletWithdrawal = (
  body: FiatWalletWithdrawalRequestBody,
  handleClose: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.walletWithdrawalBegin());
      const res = await withdrawalService.fiatWalletWithdrawal(body);
      dispatch(actions.walletWithdrawalSuccess());

      if (body.bankId) {
        dispatch(
          walletActions.withdrawFiatWallet({
            currency: body.currency,
            amount: body.amount
          })
        );
      }

      toast.success(res.data.message);
      handleClose();
    } catch (error: any) {
      const msg = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.walletWithdrawalError(msg));
    }
  };
};

export const cryptoWalletWithdrawal = (
  body: CryptoWalletWithdrawalRequestBody,
  handleClose: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.walletWithdrawalBegin());
      const res = await withdrawalService.cryptoWalletWithdrawal(body);
      dispatch(actions.walletWithdrawalSuccess());
      dispatch(actions.walletWithdrawalReceiptSave(res.data));
      handleClose();
      toast.success(res.data.message);
    } catch (error: any) {
      const msg = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.walletWithdrawalError(msg));
    }
  };
};

export const subWalletWithdrawal = (data: SubWalletWithdrawalRequestData) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.walletWithdrawalBegin());
      const res = await withdrawalService.subWalletWithdrawal(data);
      dispatch(actions.walletWithdrawalSuccess());
      toast.success(res.data.message);
    } catch (error: any) {
      dispatch(
        actions.walletWithdrawalError(
          error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
        )
      );
    }
  };
};

export const getAllWithdrawals = (params?: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getAllWithdrawals"));
      const res = await withdrawalService.getAllWithdrawals(params);

      if (params?.type === "withdrawal")
        dispatch(actions.setWithdrawalTransactionsInfo(res.data));

      if (params?.type === "pay-money")
        dispatch(actions.setWithdrawalSendMoneyInfo(res.data));

      dispatch(actions.setWithdrawalsInfo(res.data.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "getAllWithdrawals" }));
    }
  };
};

export const getWithdrawalFee = (callSign: string, params?: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getWithdrawalFee"));
      const res = await withdrawalService.getWithdrawalFee(callSign, params);
      dispatch(actions.setWithdrawalFee(res.data.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "getAllWithdrawals" }));
    }
  };
};

export const getCryptoWithdrawalNetworkFee = (
  body: TCryptoWithdrawalNetworkFees
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getCryptoNetworkFee"));
      const res = await withdrawalService.getCryptoWithdrawalNetworkFee(body);
      dispatch(actions.setCryptoNetworkFee(res.data.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "getCryptoNetworkFee" }));
    }
  };
};

export const getSubBusinessCryptoWithdrawalNetworkFee = (
  body: TCryptoWithdrawalNetworkFees
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getCryptoNetworkFee"));
      const res =
        await withdrawalService.getSubBusinessCryptoWithdrawalNetworkFee(body);
      dispatch(actions.setCryptoNetworkFee(res.data.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "getCryptoNetworkFee" }));
    }
  };
};

export const setWalletWithdrawalModal = (value: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setWalletWithdrawalModal(value));
  };
};

export const setWalletWithdrawalPin = (value: Pin) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setWalletWithdrawalPin(value));
  };
};

export const setWalletWithdrawalAmount = (value: Amount) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setWalletWithdrawalAmount(value));
  };
};

export const setWalletWithdrawalNarration = (value: string) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setWalletWithdrawalNarration(value));
  };
};

export const setWalletWithdrawalMethod = (value: Method) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setWalletWithdrawalMethod(value));
  };
};

export const setWalletWithdrawalSubWallet = (value: number) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setWalletWithdrawalSubWallet(value));
  };
};

export const setWalletWithdrawalWalletType = (value: WalletType) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setWalletWithdrawalWalletType(value));
  };
};

export const setWalletWithdrawalCurrency = (value: string) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setWalletWithdrawalCurrency(value));
  };
};

export const setWithdrawalCryptoWallet = (value?: {
  token: string;
  network: string;
}) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setWithdrawalCryptoWallet(value!));
  };
};

export const setWalletWithdrawalAddress = (value: string) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setWalletWithdrawalAddress(value));
  };
};

export const setWalletWithdrawalStep = (value: number) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setWalletWithdrawalStep(value));
  };
};

export const setWalletWithdrawalWallet = (value: any) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setWithdrawalWallet(value));
  };
};

export const setWalletWithdrawalBank = (value?: any) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setWalletWithdrawalBank(value));
  };
};

export const resetWalletWithdrawal = () => {
  return (dispatch: Dispatch) => {
    dispatch(actions.resetWalletWithdrawal());
  };
};

export const setWithdrawalIdleStatus = (value: TWithdrawalLoading) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.idle(value));
  };
};

export const getSubBusinessWithdrawals = (callSign: string, params?: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getAllWithdrawals"));
      const res = await withdrawalService.getSubBusinessWithdrawals(
        callSign,
        params
      );

      if (params?.type === "withdrawal")
        dispatch(actions.setWithdrawalTransactionsInfo(res.data));

      if (params?.type === "pay-money")
        dispatch(actions.setWithdrawalSendMoneyInfo(res.data));

      dispatch(actions.setWithdrawalsInfo(res.data.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "getAllWithdrawals" }));
    }
  };
};

export const getSubBusinessWithdrawalFee = (callSign: string, params?: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getWithdrawalFee"));
      const res = await withdrawalService.getSubBusinessWithdrawalFee(
        callSign,
        params
      );
      dispatch(actions.setWithdrawalFee(res.data.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "getAllWithdrawals" }));
    }
  };
};

export const subBusinessCryptoWalletWithdrawal = (
  callSign: string,
  body: CryptoWalletWithdrawalRequestBody,
  handleClose: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.walletWithdrawalBegin());
      const res = await withdrawalService.subBusinessCryptoWalletWithdrawal(
        callSign,
        body
      );
      dispatch(actions.walletWithdrawalSuccess());
      dispatch(actions.walletWithdrawalReceiptSave(res.data));
      handleClose();
      toast.success(res.data.message);
    } catch (error: any) {
      const msg = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.walletWithdrawalError(msg));
    }
  };
};

export const usdWalletWithdrawal = (
  body: USDWithdrawalBody,
  cb?: () => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.walletWithdrawalBegin());
      const res = await withdrawalService.usdWalletWithdrawal(body);
      dispatch(actions.walletWithdrawalSuccess());
      dispatch(actions.walletWithdrawalReceiptSave(res.data));
      if (cb) cb();
      toast.success(res.data.message);
      return true;
    } catch (error: any) {
      const msg = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.walletWithdrawalError(msg));
    }
  };
};

import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/feature-management.reducer";
import { DEFAULT_ERROR_MESSAGE, onResponseSuccess } from "data/error-mapping";
import featureManagementService from "data/services/feature-management.service";

export const getSingleFeature = (feature: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getSingleFeatureBegin());
      const res = await featureManagementService.getSingleFeature(feature);
      onResponseSuccess(res, () => {
        dispatch(
          actions.getSingleFeatureSuccess({
            data: res.data.data
          })
        );
      });
      return res.data.data;
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      dispatch(actions.getSingleFeatureError(msg));
      toast.error(msg);
    }
  };
};

class LocalStorage {
  static get = (key: string) => {
    if (typeof window === "undefined") return;

    const value = localStorage.getItem(key);

    return value ? JSON.parse(value) : null;
  };

  static set = (key: string, value: any) => {
    if (typeof window === "undefined") return;

    localStorage.setItem(key, JSON.stringify(value));
  };

  static remove = (key: string) => {
    if (typeof window === "undefined") return;

    window.localStorage.removeItem(key);
  };
}

export default LocalStorage;

export const saveToken = (token: string) => {
  window.localStorage.setItem("changeraToken", token);
};
export const removeToken = () =>
  window.localStorage.removeItem("changeraToken");
export const getToken = () => window.localStorage.getItem("changeraToken");

export const saveCallSign = (callSign: string) => {
  window.localStorage.setItem("selectedBusiness", callSign);
};

export const getCallSign = () =>
  window.localStorage.getItem("selectedBusiness");
export const removeCallSign = () =>
  window.localStorage.removeItem("selectedBusiness");

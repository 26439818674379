import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUpdateNotificationBody } from "../models/account";
type TNotification =
  | "fundingNotification"
  | "updatesNotification"
  | "transfersNotification"
  | "withdrawalNotification";

export type Role = "admin" | "viewer" | "finance" | "event-manager" | "support";

export interface IKyc {
  id: number;
  status: string;
  certificateOfRegistration?: string;
  cacRegistrationForm?: string;
  certificateOfIncorporation?: string;
  certificateOfRegistrationII?: string;
  memorandumAndArticlesOfAssociation?: string;
  particularsOfDirectors?: string;
  particularsOfShareholders?: string;
  boardResolution?: string;
  utilityBill?: string;
  bankStatement?: string;
  others?: string[];
  termsAndConditionsOfPartnership?: string;
  partnershipConsentForm?: string;
  verificationComment?: string;
  latestKyc: boolean;
  createdAt: string;
  updatedAt: string;
  articleOfIncorporationUrl: string;
}

export interface IOwner {
  id: number;
  email: string;
  idUrl: string;
  phone: string;
  idType: string;
  version: string;
  position: string;
  lastName: string;
  idNumber: string;
  createdAt: string;
  updatedAt: string;
  firstName: string;
  ownership: string;
  ssn: string | null;
  nationality: string;
  dateOfBirth: string;
  idIssuedDate: string;
  idExpirationDate: string;
  politicsInclined: boolean;
}

export interface BusinessInfo {
  kyc: IKyc;
  id: number;
  tin: string;
  ein: string;
  nin: string;
  type: string;
  city: string;
  name: string;
  logo: string;
  email: string;
  state: string;
  phone: string;
  tinUrl: string;
  zipCode: string;
  country: string;
  website: string;
  address: string;
  callSign: string;
  serviceId: string;
  serviceIdII: string;
  industry: string;
  legalName: string;
  description: string;
  dateOfBirth: string;
  addressNumber: string;
  sourceOfFunds: string;
  otherSourceOfFunds: string;
  countryRegisteredIn: string;
  updatesNotification: string;
  fundingNotification: string;
  countryRegisteredInII: string;
  transfersNotification: string;
  withdrawalNotification: string;
  businessCompleteSetup: boolean;
  dateOfIncorporation: string | null;
  businessRegistrationNumber: string;
  isRegisteredInAnotherCountry: boolean;
  role: "partner" | "business";
  owners: IOwner[];
  member: {
    role: Role | null;
    status: string;
    type: string;
    email: string;
    firstName: string;
  };
  qrCode: string;
  subscriptionPlan: string;
  subscription: {
    billingRetryCount: number;
    billingWallet: number;
    createdAt: string;
    id: number;
    lastBillingDate: string;
    lastBillingplan: string;
    nextBillingDate: string;
    nextBillingplan: string;
    updatedAt: string;
    renewalCycle: string;
    nextRenewalCycle: string;
  };
}

interface BusinessSetupState {
  singleBusinessInfo: any;
  allBusiness: any;
  businessInfo: BusinessInfo;
  businessInfoLoading: boolean;
  loading: boolean;
  businessError: boolean;
  createBusinessSuccess: boolean;
  createBusinessCompleted: boolean;
  addedBusinessOwner: boolean;
  confirmedCallSign: boolean;
  confirmingCallSign: boolean;
  supportedCountries: [];
  allBusinessInfoLoading: boolean;
}

const initialState: BusinessSetupState = {
  businessInfo: null as any,
  allBusiness: null,
  businessInfoLoading: false,
  loading: false,
  businessError: false,
  createBusinessSuccess: false,
  createBusinessCompleted: false,
  singleBusinessInfo: null,
  addedBusinessOwner: false,
  confirmedCallSign: false,
  confirmingCallSign: false,
  supportedCountries: [],
  allBusinessInfoLoading: false
};

export const BusinessSetupSlice = createSlice({
  name: "businessSetup",
  initialState,
  reducers: {
    error: (state: BusinessSetupState) => {
      state.loading = false;
      state.businessError = true;
      state.businessInfoLoading = false;
      state.allBusinessInfoLoading = false;
      state.confirmingCallSign = false;
    },
    gettingAllBusinesses: (state: BusinessSetupState) => {
      state.allBusinessInfoLoading = true;
    },
    gettingBusinessInfo: (state: BusinessSetupState) => {
      state.businessInfoLoading = true;
    },

    settingAllBusiness: (state: BusinessSetupState, action) => {
      state.allBusiness = action.payload;
      state.allBusinessInfoLoading = false;
    },

    settingBusinessInfo: (state: BusinessSetupState, action) => {
      state.businessInfo = action.payload;
      state.businessInfoLoading = false;
    },

    businessError: (state: BusinessSetupState) => {
      state.loading = false;
      state.businessInfoLoading = false;
      state.businessError = true;
    },
    loadingBegin: (state: BusinessSetupState) => {
      state.loading = true;
    },
    loadingStop: (state: BusinessSetupState) => {
      state.loading = false;
    },
    createBusinessSuccess: (state: BusinessSetupState) => {
      state.createBusinessSuccess = true;
      state.loading = false;
      state.businessError = false;
    },
    addedBusinessOwnerDetails: (state: BusinessSetupState) => {
      state.addedBusinessOwner = true;
      state.loading = false;
    },
    finishBusinessRegistration: (state: BusinessSetupState) => {
      state.createBusinessSuccess = true;
      state.createBusinessCompleted = true;
      state.loading = false;
      state.businessError = false;
    },
    confimingCallSign: (state: BusinessSetupState) => {
      state.confirmingCallSign = true;
    },
    callSignConfirmed: (
      state: BusinessSetupState,
      action: PayloadAction<any>
    ) => {
      state.confirmingCallSign = false;
      state.confirmedCallSign = action.payload;
    },
    settingSupportedCountries: (
      state: BusinessSetupState,
      action: PayloadAction<any>
    ) => {
      state.supportedCountries = action.payload;
    },
    updateBusinessLogo: (
      state: BusinessSetupState,
      action: PayloadAction<string>
    ) => {
      if (state.businessInfo) state.businessInfo.logo = action.payload;
    },
    updateNotification: (
      state: BusinessSetupState,
      action: PayloadAction<IUpdateNotificationBody>
    ) => {
      const name = `${action.payload.type}Notification` as TNotification;
      state.businessInfo[name] = action.payload.method;
    }
  }
});

export const {
  gettingAllBusinesses,
  gettingBusinessInfo,
  settingBusinessInfo,
  settingAllBusiness,
  businessError,
  loadingBegin,
  loadingStop,
  createBusinessSuccess,
  addedBusinessOwnerDetails,
  error,
  finishBusinessRegistration,
  confimingCallSign,
  callSignConfirmed,
  settingSupportedCountries,
  updateBusinessLogo,
  updateNotification
} = BusinessSetupSlice.actions;

export default BusinessSetupSlice.reducer;

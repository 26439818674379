import {
  IFX,
  IFXFee,
  IFXState,
  IFXDrawer,
  IFXExternalAccount,
  IReceiveFXResponse,
  IFXBeneficiary
} from "../models/fx";
import { IPagination } from "data/models/base";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const pagination = {
  next: 0,
  total: 0,
  prevPage: 0,
  lastPage: 0,
  pageSize: 10,
  hasNext: false,
  currentPage: 1,
  hasPrevious: false
};

const initialState: IFXState = {
  recipient: { add: { error: "", loading: false } },

  drawer: {
    file: "",
    amount: null,
    wallet: null,
    beneficiary: null,
    paymentRail: null,
    totalAmount: null,
    fxCurrency: "usd",
    feeBearer: "sender",
    recipientAmount: null,
    externalAccount: null,
    equivalentAmount: null,
    subBusinessWallet: null
  },

  beneficiary: {
    add: { error: "", loading: false },
    single: { data: null, error: "", loading: false },
    all: { data: [], error: "", loading: false, pagination }
  },

  externalAccount: {
    add: { error: "", loading: false },
    single: { data: null, error: "", loading: false },
    all: { data: [], error: "", loading: false, pagination }
  },

  fx: {
    delete: { error: "", loading: false },
    request: { error: "", loading: false },
    activate: { error: "", loading: false },
    uploadProof: { error: "", loading: false },
    fee: { error: "", loading: false, data: null },
    single: { data: null, error: "", loading: false },
    receive: { error: "", loading: false, data: null },
    send: { error: "", loading: false, response: null },
    all: { data: [], error: "", loading: false, pagination }
  }
};

export const FXSlice = createSlice({
  initialState,
  name: "fx",
  reducers: {
    requestFXBegin: (state) => {
      state.fx.request.error = "";
      state.fx.request.loading = true;
    },
    requestFXEnd: (state, action: PayloadAction<string | undefined>) => {
      state.fx.request.loading = false;
      state.fx.request.error = action.payload || "";
    },
    addFXRecipientBegin: (state) => {
      state.recipient.add.error = "";
      state.recipient.add.loading = true;
    },
    addFXRecipientEnd: (state, action: PayloadAction<string | undefined>) => {
      state.recipient.add.loading = false;
      state.recipient.add.error = action.payload || "";
    },
    deleteFXBegin: (state) => {
      state.fx.delete.error = "";
      state.fx.delete.loading = true;
    },
    deleteFXEnd: (state, action: PayloadAction<string | undefined>) => {
      state.fx.delete.loading = false;
      state.fx.delete.error = action.payload || "";
    },
    fetchSingleFXBegin: (state) => {
      state.fx.single.error = "";
      state.fx.single.loading = true;
    },
    fetchSingleFXFailed: (state, action: PayloadAction<string>) => {
      state.fx.single.loading = false;
      state.fx.single.error = action.payload;
    },
    setSingleFX: (state, action: PayloadAction<IFX>) => {
      state.fx.single.error = "";
      state.fx.single.loading = false;
      state.fx.single.data = action.payload;
    },
    getFXFeeBegin: (state) => {
      state.fx.fee.error = "";
      state.fx.fee.data = null;
      state.fx.fee.loading = true;
    },
    getFXFeeFailed: (state, action: PayloadAction<string>) => {
      state.fx.fee.data = null;
      state.fx.fee.loading = false;
      state.fx.fee.error = action.payload;
    },
    setFXFee: (state, action: PayloadAction<IFXFee>) => {
      state.fx.fee.error = "";
      state.fx.fee.loading = false;
      state.fx.fee.data = action.payload;
    },
    fetchSingleFXExternalAccountBegin: (state) => {
      state.externalAccount.single.error = "";
      state.externalAccount.single.loading = true;
    },
    fetchSingleFXExternalAccountFailed: (
      state,
      action: PayloadAction<string>
    ) => {
      state.externalAccount.single.loading = false;
      state.externalAccount.single.error = action.payload;
    },
    setSingleFXExternalAccount: (
      state,
      action: PayloadAction<IFXExternalAccount>
    ) => {
      state.externalAccount.single.error = "";
      state.externalAccount.single.loading = false;
      state.externalAccount.single.data = action.payload;
    },
    fetchAllFXsBegin: (state) => {
      state.fx.all.error = "";
      state.fx.all.loading = true;
    },
    fetchAllFXsFailed: (state, action: PayloadAction<string>) => {
      state.fx.all.loading = false;
      state.fx.all.error = action.payload;
    },
    setAllFXs: (
      state,
      action: PayloadAction<{ data: IFX[]; pagination: IPagination }>
    ) => {
      state.fx.all.error = "";
      state.fx.all.loading = false;
      state.fx.all.data = action.payload.data;
    },
    fetchAllFXExternalAccountsBegin: (state) => {
      state.externalAccount.all.error = "";
      state.externalAccount.all.loading = true;
    },
    fetchAllFXExternalAccountsFailed: (
      state,
      action: PayloadAction<string>
    ) => {
      state.externalAccount.all.loading = false;
      state.externalAccount.all.error = action.payload;
    },
    setAllFXExternalAccounts: (
      state,
      action: PayloadAction<{
        data: IFXExternalAccount[];
        pagination: IPagination;
      }>
    ) => {
      state.externalAccount.all.error = "";
      state.externalAccount.all.loading = false;
      state.externalAccount.all.data = action.payload.data;
      state.externalAccount.all.pagination = action.payload.pagination;
    },
    addFXExternalAccountBegin: (state) => {
      state.externalAccount.add.error = "";
      state.externalAccount.add.loading = true;
    },
    addFXExternalAccountEnd: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.externalAccount.add.loading = false;
      state.externalAccount.add.error = action.payload || "";
    },
    sendFXBegin: (state) => {
      state.fx.send.error = "";
      state.fx.send.loading = true;
    },
    sendFXSuccess: (state, action: PayloadAction<any>) => {
      state.fx.send.loading = false;
      state.fx.send.response = action.payload;
    },
    sendFXFailed: (state, action: PayloadAction<string>) => {
      state.fx.send.loading = false;
      state.fx.send.error = action.payload;
    },
    receiveFXBegin: (state) => {
      state.fx.receive.error = "";
      state.fx.receive.loading = true;
    },
    receiveFXSuccess: (state, action: PayloadAction<IReceiveFXResponse>) => {
      state.fx.receive.error = "";
      state.fx.receive.loading = false;
      state.fx.receive.data = action.payload;
    },
    receiveFXFailed: (state, action: PayloadAction<string>) => {
      state.fx.receive.loading = false;
      state.fx.receive.error = action.payload;
    },
    setFXDrawerState: (
      state,
      action: PayloadAction<undefined | Partial<IFXDrawer>>
    ) => {
      if (action.payload) {
        state.drawer = { ...state.drawer, ...action.payload };
      } else {
        state.drawer = initialState.drawer;
      }
    },
    uploadFXPaymentProofBegin: (state) => {
      state.fx.uploadProof.error = "";
      state.fx.uploadProof.loading = true;
    },
    uploadFXPaymentProofEnd: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.fx.uploadProof.loading = false;
      state.fx.uploadProof.error = action.payload || "";
    },
    addFXBeneficiaryBegin: (state) => {
      state.beneficiary.add.error = "";
      state.beneficiary.add.loading = true;
    },
    addFXBeneficiaryEnd: (state, action: PayloadAction<string | undefined>) => {
      state.beneficiary.add.loading = false;
      state.beneficiary.add.error = action.payload || "";
    },
    fetchAllFXBeneficiariesBegin: (state) => {
      state.beneficiary.all.error = "";
      state.beneficiary.all.loading = true;
    },
    fetchAllFXBeneficiariesFailed: (state, action: PayloadAction<string>) => {
      state.beneficiary.all.loading = false;
      state.beneficiary.all.error = action.payload;
    },
    setAllFXBeneficiaries: (
      state,
      action: PayloadAction<{
        data: IFXBeneficiary[];
        pagination: IPagination;
      }>
    ) => {
      state.beneficiary.all.error = "";
      state.beneficiary.all.loading = false;
      state.beneficiary.all.data = action.payload.data;
      state.beneficiary.all.pagination = action.payload.pagination;
    },
    activeFxTypeBegin: (state) => {
      state.fx.activate.error = "";
      state.fx.activate.loading = true;
    },
    activateFxTypeSuccess: (state) => {
      state.fx.activate.error = "";
      state.fx.activate.loading = false;
    },
    activateFxTypeFailed: (state, action: PayloadAction<string>) => {
      state.fx.activate.loading = false;
      state.fx.activate.error = action.payload;
    }
  }
});

export const actions = FXSlice.actions;
export default FXSlice.reducer;

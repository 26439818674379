import {
  IChangeraUser,
  PaySomeoneSuccessData
} from "app/store/models/pay-someone";
import instance from "data/axios-setup";
import { AxiosResponse } from "axios";
import { IBusiness } from "app/store/models/business";

class PaySomeoneService {
  async paySomeone(callSign: string, body: any) {
    return new Promise<
      AxiosResponse<{ data: PaySomeoneSuccessData; message: string }>
    >(async (resolve, reject) => {
      try {
        const res = await instance.post(`${callSign}/pay-money`, body);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getPaySomeoneFee(
    callSign: string,
    params?: any
  ): Promise<AxiosResponse<{ data: number; message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/${callSign}/withdrawal-fee`, {
          params
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  getPaySomeoneBusinessRecipient(
    callSign: string
  ): Promise<AxiosResponse<{ data: IBusiness; message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/business/${callSign}`);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }

  getPaySomeonePersonalRecipient(
    callSign: string,
    username: string
  ): Promise<
    AxiosResponse<{ data: { data: IChangeraUser; message: string } }>
  > {
    return new Promise(async (resolve, reject) => {
      const body = { changeraUsername: username };
      try {
        const res = await instance.post(
          `${callSign}/pay-money/resolve-changera-username`,
          body
        );
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new PaySomeoneService();

import { AxiosError } from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/virtual-accounts.reducer";
import virtualAccountService from "data/services/virtual-account.service";
import { DEFAULT_ERROR_MESSAGE, onResponseSuccess } from "data/error-mapping";
import { CreateVirtualAccountEurBody } from "../models/virtual-accounts";
import toast from "react-hot-toast";

export const getAllVirtualAccounts = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllVirtualAccountsBegin());
      const res = await virtualAccountService.getAllVirtualAccounts();
      onResponseSuccess(res, () => {
        dispatch(actions.getAllVirtualAccountsSuccess(res.data.data));
      });
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      dispatch(actions.getAllVirtualAccountsError(msg));
    }
  };
};

export const getAllSubBusinessVirtualAccounts = (callSign: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllVirtualAccountsBegin());
      const res = await virtualAccountService.getSubBusinessVirtualAccounts(
        callSign
      );
      onResponseSuccess(res, () => {
        dispatch(actions.getSubBusinessVirtualAccountsSuccess(res.data.data));
      });
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      dispatch(actions.getAllVirtualAccountsError(msg));
    }
  };
};

export const createEurVirtualAccount = (
  body: CreateVirtualAccountEurBody,
  callback?: () => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.createVBA(true));
      const res = await virtualAccountService.createVirtualAccountEUR(body);
      toast.success(res.data.message);
      dispatch(actions.createVBA(false));
      if (callback) callback();
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.createVBA(false));
    }
  };
};

export const getEurVBAFee = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllVirtualAccountsFee(true));
      const res = await virtualAccountService.getEurVbaFee();
      onResponseSuccess(res, () => {
        dispatch(actions.getEurFeeSuccess(res.data.data));
      });
    } catch (err: any) {
      dispatch(actions.getAllVirtualAccountsFee(false));
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      dispatch(actions.getAllVirtualAccountsError(msg));
    }
  };
};

export const getUsdFee = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllVirtualAccountsFee(true));
      const res = await virtualAccountService.getUsdFee();
      onResponseSuccess(res, () => {
        dispatch(actions.getUsdFeeSuccess(res.data.data));
      });
    } catch (err: any) {
      dispatch(actions.getAllVirtualAccountsFee(false));
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      dispatch(actions.getAllVirtualAccountsError(msg));
    }
  };
};

export const getUSDVirtualAccountFee = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllVirtualAccountsFee(true));
      const res = await virtualAccountService.getUSDVirtualAccountFee();
      onResponseSuccess(res, () => {
        dispatch(actions.getUsdFeeSuccess(res.data.data));
      });
    } catch (err: any) {
      dispatch(actions.getAllVirtualAccountsFee(false));
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      dispatch(actions.getAllVirtualAccountsError(msg));
    }
  };
};

export const createUsdVirtualAccount = (body: any, callback?: () => void) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.createVBA(true));
      const res = await virtualAccountService.createVirtualUSDAccount(body);
      toast.success(res.data.message);
      dispatch(actions.createVBA(false));
      if (callback) callback();
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.createVBA(false));
    }
  };
};

import { Dispatch } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { DEFAULT_ERROR_MESSAGE, onResponseSuccess } from "data/error-mapping";
import {
  IExtendBody,
  IGetCurrencyBody,
  ISubscribeBody,
  IUpdatePaymentMethodBody,
  IUpdateRenewalCycleBody
} from "../models/subscription";
import { actions } from "../reducers/subscription.reducer";
import toast from "react-hot-toast";
import subscriptionService from "data/services/subscription.service";
import { reloadBusinessInfo } from "./business-setup.actions";
import { reloadSubBusinessInfo } from "./sub-businesses.action";

export const subscribeToPlan = (
  body: ISubscribeBody,
  disp: any,
  setTransactionPin: any,
  callback?: () => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.makingSubscribtion());
      const response = await subscriptionService.subscribeToPlan(body);
      onResponseSuccess(response, () => {
        dispatch(actions.subscribed());
        toast.success(response.data.message);
        disp(reloadBusinessInfo());
        setTransactionPin("");
        if (callback) callback();
      });
    } catch (error) {
      setTransactionPin("");
      dispatch(actions.error());
      const axiosError = error as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
    }
  };
};

export const subscribeToPlanSubBusiness = (
  body: ISubscribeBody,
  disp: any,
  setTransactionPin: any,
  subBusinessCallSign: string,
  callback?: () => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.makingSubscribtion());
      const response = await subscriptionService.subscribeToPlanSubBusiness(
        body,
        subBusinessCallSign
      );
      onResponseSuccess(response, () => {
        dispatch(actions.subscribed());
        toast.success(response.data.message);
        disp(reloadSubBusinessInfo(subBusinessCallSign));
        setTransactionPin("");
        if (callback) callback();
      });
    } catch (error) {
      setTransactionPin("");
      dispatch(actions.error());
      const axiosError = error as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
    }
  };
};

export const extendPlan = (
  body: IExtendBody,
  disp: any,
  setTransactionPin: any,
  callback?: () => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.makingSubscribtion());
      const response = await subscriptionService.extendPlan(body);
      onResponseSuccess(response, () => {
        dispatch(actions.subscribed());
        toast.success(response.data.message);
        disp(reloadBusinessInfo());
        setTransactionPin("");
        if (callback) callback();
      });
    } catch (error) {
      setTransactionPin("");
      dispatch(actions.error());
      const axiosError = error as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
    }
  };
};

export const updatePaymentMethod = (
  body: IUpdatePaymentMethodBody,
  disp: any,
  setTransactionPin: any,
  callback?: () => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.updatingPaymentMethod());
      const response = await subscriptionService.updatePaymentMethod(body);
      onResponseSuccess(response, () => {
        dispatch(actions.updatedPaymentMethod());
        toast.success(response.data.message);
        disp(reloadBusinessInfo());
        setTransactionPin("");
        if (callback) callback();
      });
    } catch (error) {
      setTransactionPin("");
      const axiosError = error as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
    }
  };
};

export const updateRenewalCycle = (
  body: IUpdateRenewalCycleBody,
  disp: any,
  setTransactionPin: any,
  callback?: () => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.updatingPaymentMethod());
      const response = await subscriptionService.updateRenewalCycle(body);
      onResponseSuccess(response, () => {
        dispatch(actions.updatedPaymentMethod());
        toast.success(response.data.message);
        disp(reloadBusinessInfo());
        setTransactionPin("");
        if (callback) callback();
      });
    } catch (error) {
      setTransactionPin("");
      const axiosError = error as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
    }
  };
};

export const updatePaymentMethodSubBusiness = (
  body: IUpdatePaymentMethodBody,
  disp: any,
  setTransactionPin: any,
  subBusinessCallSign: string,
  callback?: () => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.updatingPaymentMethod());
      const response = await subscriptionService.updatePaymentMethodSubBusiness(
        body,
        subBusinessCallSign
      );
      onResponseSuccess(response, () => {
        dispatch(actions.updatedPaymentMethod());
        toast.success(response.data.message);
        disp(reloadSubBusinessInfo(subBusinessCallSign));
        setTransactionPin("");
        if (callback) callback();
      });
    } catch (error) {
      setTransactionPin("");
      const axiosError = error as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
    }
  };
};

export const getSubscriptionPrice = (
  body: IGetCurrencyBody,
  callback?: () => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.settingSubscriptionPrice(null));
      dispatch(actions.gettingSubscriptionPrice());
      const response = await subscriptionService.calculateSubscription(body);
      onResponseSuccess(response, () => {
        dispatch(actions.settingSubscriptionPrice(response.data.data));
        if (callback) callback();
      });
    } catch (error) {
      dispatch(actions.error());
      dispatch(actions.settingSubscriptionPrice(null));
      const axiosError = error as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
    }
  };
};

export const getSubscriptionPriceSubBusiness = (
  body: IGetCurrencyBody,
  subBusinessCallSign: string,
  callback?: () => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.settingSubscriptionPrice(null));
      dispatch(actions.gettingSubscriptionPrice());
      const response =
        await subscriptionService.calculateSubscriptionSubBusiness(
          body,
          subBusinessCallSign
        );
      onResponseSuccess(response, () => {
        dispatch(actions.settingSubscriptionPrice(response.data.data));
        if (callback) callback();
      });
    } catch (error) {
      dispatch(actions.error());
      dispatch(actions.settingSubscriptionPrice(null));
      const axiosError = error as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
    }
  };
};

export const cancelSubscriptionPrice = () => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.settingSubscriptionPrice(null));
  };
};

export const isPasswordInValid = (password: string) => {
  if (password.trim() === "" || password.trim().length < 7) {
    return true;
  }
  return false;
};

export const isFieldsInvalid = (obj: object) => {
  let returnValue = false;

  Object.values(obj).map((el: string) => {
    if (!el?.trim()) return (returnValue = true);
  });

  return returnValue;
};

export const stringValueOfObjectTrimmer = (obj: any) => {
  const res = { ...obj };

  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === "string") {
      res[key] = value.trim();
    } else {
      res[key] = value;
    }
  }

  return res;
};

export const isFormValid = (data: any): boolean => {
  if (typeof data === "string") {
    return data.trim().length > 0;
  }

  if (Array.isArray(data)) {
    return data.every(isFormValid);
  }

  if (typeof data === "object" && data !== null) {
    return Object.values(data).every(isFormValid);
  }

  return false;
};

export const createableVirtualAccounts = [
  {
    currency: "ngn",
    name: "Nigerian Naira"
  },
  {
    currency: "usd",
    name: "United States Dollar"
  },
  {
    currency: "gbp_eur",
    name: "GBP and EUR"
  }
  // {
  //   currency: "gbp",
  //   name: "British Pounds"
  // },
  // {
  //   currency: "eur",
  //   name: "European Euro"
  // }
];

export const getNameFromCurrency = (currency: string) => {
  switch (currency) {
    case "ngn":
      return "Nigerian Naira";
    case "gbp":
      return "British Pounds";
    case "eur":
      return "European Euro";
    case "usd":
      return "US Dollar";
    case "ghs":
      return "Ghanian Cedis";
    case "kes":
      return "Kenyan Shillings";
    case "gbp_eur":
    case "eur_gbp":
      return "GBP and EUR";
    default:
      return "";
  }
};

export const createableNGNVirtualAccounts = [
  {
    bank: "Providus",
    active: true
  },
  {
    bank: "VFD",
    active: false
  },
  {
    bank: "safe-haven",
    active: false
  },
  {
    bank: "Wema",
    active: true
  }
];

import {
  IChangePasswordBody,
  IChangeTransactionPinBody,
  ICreateTransactionPinBody,
  IResetTransactionPinBody,
  IUpdateNotificationBody
} from "app/store/models/account";
import { AxiosResponse } from "axios";
import instance from "data/axios-setup";

class AccountService {
  async changePassword(
    body: IChangePasswordBody
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post("account/change-password", body);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async createTransactionPin(
    body: ICreateTransactionPinBody
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post(`account/transaction-pin`, body);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }

  async updateTransactionPin(
    body: IChangeTransactionPinBody
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put(`account/transaction-pin`, body);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }

  async resetTransactionPin(
    body: IResetTransactionPinBody
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put(`account/reset-pin`, body);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getResetPinCode(): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get("account/transaction-pin");
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }

  async validateResetPinCode(
    code: string
  ): Promise<AxiosResponse<{ message: string; state: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post("account/validate-code", { code });
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }

  async updateNotificationSettings(
    callSign: string,
    body: IUpdateNotificationBody
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.put(
          `/account/${callSign}/notification-setting`,
          body
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async uploadProfileImage(
    image: string
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.put(`/account/profile-picture`, {
          image
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new AccountService();

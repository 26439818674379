import { Box, Text, Center } from "@chakra-ui/react";
import { useLogoutFunction } from "app/hooks";
import { ReactComponent as ErrorInfo } from "app/assets/svg/errorInfo.svg";

export default function Forbidden() {
  const logout = useLogoutFunction();
  return (
    <Center h="70vh">
      <Box textAlign="center" py={10} px={6} color={"primary.800"}>
        <div className="flex items-center justify-center my-2">
          <ErrorInfo />
        </div>
        <Text fontSize="18px" mt={3} mb={2} fontWeight="bold">
          Permission Denied
        </Text>
        <Text fontSize="18px" color={"gray.800"} mb={6}>
          The action / page you're looking for is restricted from you
        </Text>

        <Text fontSize="16px" mt={8} mb={2} color={"gray.800"}>
          If this persist, please contact your business admin, or{" "}
          <span
            onClick={logout}
            className="text-primary-800 underline font-semibold cursor-pointer mt-8"
          >
            log back in
          </span>
        </Text>
      </Box>
    </Center>
  );
}

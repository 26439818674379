import {
  TBank,
  ICountry,
  IMiscError,
  IMiscState,
  TMiscLoading,
  IResolvedBank,
  INotifications,
  TMobileMoneyAgent,
  IAppVersion
} from "../models/misc";
import { CurrencyId } from "../models/currency";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGetExchangeRateV2 } from "data/models/virtual-cards";

const initialState: IMiscState = {
  banks: [],
  error: null,
  resolvedBank: null,
  mobileMoneyAgents: [],
  countriesOfRegistration: [],
  transactionStatement: {
    card: [],
    ticket: [],
    funding: [],
    withdrawal: [],
    conversion: [],
    paySomeone: [],
    airtimeData: []
  },

  status: {
    getBanks: "idle",
    resolveBank: "idle",
    getMobileMoneyAgents: "idle",
    getTransactionStatement: "idle",
    getCountriesOfRegistration: "idle"
  },

  supportedFiatWallets: {
    loading: false,
    data: [],
    error: ""
  },

  supportedCryptoWallets: {
    loading: false,
    data: [],
    error: ""
  },

  notifications: {
    loading: false,
    data: [],
    error: ""
  },

  firebaseNotificationToken: "",

  appVersion: {
    loading: false,
    data: null,
    error: ""
  },

  buildVersion: {
    loading: false,
    data: null,
    error: ""
  },

  exchangeRates: {
    loading: false,
    data: [],
    error: ""
  }
};

export const MiscSlice = createSlice({
  initialState,
  name: "misc",
  reducers: {
    idle: (state: IMiscState, action: PayloadAction<TMiscLoading>) => {
      state.status[action.payload] = "idle";
    },

    loading: (state: IMiscState, action: PayloadAction<TMiscLoading>) => {
      state.status[action.payload] = "loading";
    },
    error: (state: IMiscState, action: PayloadAction<IMiscError>) => {
      state.error = action.payload.msg;
      state.status[action.payload.for] = "failed";
    },

    setBanksInfo: (state: IMiscState, action: PayloadAction<TBank[]>) => {
      state.banks = action.payload;
      state.status.getBanks = "succeeded";
    },

    setMobileMoneyAgents: (
      state: IMiscState,
      action: PayloadAction<TMobileMoneyAgent[]>
    ) => {
      state.mobileMoneyAgents = action.payload;
      state.status.getMobileMoneyAgents = "succeeded";
    },

    setTransactionStatement: (
      state: IMiscState,
      action: PayloadAction<{
        data: any[];
        type:
          | "card"
          | "ticket"
          | "withdrawal"
          | "paySomeone"
          | "funding"
          | "conversion"
          | "airtimeData";
      }>
    ) => {
      state.status.getTransactionStatement = "succeeded";
      state.transactionStatement[action.payload.type] = action.payload.data;
    },

    setResolvedBankInfo: (
      state: IMiscState,
      action: PayloadAction<IResolvedBank>
    ) => {
      state.resolvedBank = action.payload;
      state.status.resolveBank = "succeeded";
    },
    setCountriesOfRegistration: (
      state: IMiscState,
      action: PayloadAction<ICountry[]>
    ) => {
      state.countriesOfRegistration = action.payload;
      state.status.getCountriesOfRegistration = "succeeded";
    },
    getsupportedFiatWalletsBegin: (state: IMiscState) => {
      state.supportedFiatWallets.loading = true;
    },
    getsupportedFiatWalletsSuccess: (
      state: IMiscState,
      action: PayloadAction<CurrencyId[]>
    ) => {
      state.supportedFiatWallets.data = action.payload;
      state.supportedFiatWallets.loading = false;
      state.supportedFiatWallets.error = "";
    },
    getsupportedFiatWalletsError: (
      state: IMiscState,
      action: PayloadAction<string>
    ) => {
      state.supportedFiatWallets.loading = false;
      state.supportedFiatWallets.error = action.payload;
    },
    getsupportedCryptoWalletsBegin: (state: IMiscState) => {
      state.supportedCryptoWallets.loading = true;
    },
    getsupportedCryptoWalletsSuccess: (
      state: IMiscState,
      action: PayloadAction<CurrencyId[]>
    ) => {
      state.supportedCryptoWallets.data = action.payload;
      state.supportedCryptoWallets.loading = false;
      state.supportedCryptoWallets.error = "";
    },
    getsupportedCryptoWalletsError: (
      state: IMiscState,
      action: PayloadAction<string>
    ) => {
      state.supportedCryptoWallets.loading = false;
      state.supportedCryptoWallets.error = action.payload;
    },
    getNotificationsBegin: (state) => {
      state.notifications.loading = true;
    },
    getNotificationsSuccess: (
      state,
      action: PayloadAction<INotifications[]>
    ) => {
      state.notifications.loading = false;
      state.notifications.data = action.payload;
    },
    getNotificationError: (state, action: PayloadAction<string>) => {
      state.notifications.loading = false;
      state.notifications.error = action.payload;
    },
    setFirebaseNotificationToken: (state, action: PayloadAction<string>) => {
      state.firebaseNotificationToken = action.payload;
    },
    getAppVersionBegin: (state) => {
      state.appVersion.loading = true;
    },
    getAppVersionSuccess: (state, action: PayloadAction<IAppVersion[]>) => {
      state.appVersion.loading = false;
      state.appVersion.data =
        action.payload.find((el) => el.type === "app") || null;
      state.buildVersion.data =
        action.payload.find((el) => el.type === "build") || null;
    },
    getAppVersionError: (state, action: PayloadAction<string>) => {
      state.appVersion.loading = false;
      state.appVersion.error = action.payload;
    },
    getExchangeRatesBegin: (state) => {
      state.exchangeRates.loading = true;
    },
    getExchangeRatesSuccess: (
      state,
      action: PayloadAction<IGetExchangeRateV2[]>
    ) => {
      state.exchangeRates.loading = false;
      state.exchangeRates.data = action.payload;
    },
    getExchangeRatesError: (state, action: PayloadAction<string>) => {
      state.exchangeRates.loading = false;
      state.exchangeRates.error = action.payload;
    }
  }
});

export const actions = MiscSlice.actions;
export default MiscSlice.reducer;

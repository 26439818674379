import {
  INotification,
  IGetNotificationsParam
} from "app/store/models/notification";
import { AxiosResponse } from "axios";
import instance from "data/axios-setup";
import { IPagination } from "data/models/base";

class NotificationService {
  async getNotifications(
    callSign: string,
    params: IGetNotificationsParam
  ): Promise<
    AxiosResponse<{ data: INotification[]; pagination: IPagination }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/${callSign}/notifications`, {
          params
        });
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async updateNotification(
    callSign: string,
    id: number
  ): Promise<AxiosResponse<string>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.put(`/${callSign}/notifications/${id}`);
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async readAllNotification(
    callSign: string,
    data: { ids: number[] }
  ): Promise<AxiosResponse<string>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.patch(
          `/${callSign}/notifications`,
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new NotificationService();

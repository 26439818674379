import { getAppVersion } from "app/store";
import { useAppDispatch, useAppSelector } from "./useReduxState";

/**
 * Custom hook for app version
 * @returns -
 * - `appVersionState` - app version state in the store
 * - `dispatchAppVersion` - function to fetch app version
 */
export const useAppVersion = () => {
  const appVersionState = useAppSelector((state) => state.misc.appVersion);
  const buildVersion = useAppSelector((state) => state.misc.buildVersion.data);

  const dispatch = useAppDispatch();

  const dispatchAppVersion = () => {
    dispatch(getAppVersion());
  };

  return { appVersionState, dispatchAppVersion, buildVersion };
};

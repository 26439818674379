import {
  ISendFX,
  IRequestFX,
  IGetFXFees,
  IReceiveFXBody,
  IFXAddRecipient,
  IActivateFxType,
  IFXAddBeneficiary,
  IFXAddExternalAccount,
  IFXUploadPaymentProof
} from "../models/fx";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/fx.reducer";
import fxService from "data/services/fx.service";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";

export const getAllFXs = (callSign: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchAllFXsBegin());
      const res = await fxService.getAllFXs(callSign);
      dispatch(actions.setAllFXs(res.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.fetchAllFXsFailed(msg));
    }
  };
};

export const getFXFees = (
  callSign: string,
  body: IGetFXFees,
  signal?: AbortSignal
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getFXFeeBegin());
      const res = await fxService.getFXFee(callSign, body, signal);
      dispatch(actions.setFXFee(res.data.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.getFXFeeFailed(msg));
    }
  };
};

export const getAllFXExternalAccounts = (callSign: string, params: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchAllFXExternalAccountsBegin());
      const res = await fxService.getAllFXExternalAccounts(callSign, params);
      dispatch(actions.setAllFXExternalAccounts(res.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.fetchAllFXExternalAccountsFailed(msg));
    }
  };
};

export const getAllFXBeneficiaries = (callSign: string, params: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchAllFXBeneficiariesBegin());
      const res = await fxService.getAllFXBeneficiaries(callSign, params);
      dispatch(actions.setAllFXBeneficiaries(res.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.fetchAllFXBeneficiariesFailed(msg));
    }
  };
};

export const getSingleFX = (id: number, callSign: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchSingleFXBegin());
      const res = await fxService.getSingleFX(id, callSign);
      dispatch(actions.setSingleFX(res.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.fetchSingleFXFailed(msg));
    }
  };
};

export const getSingleFXExternalAccount = (id: number, callSign: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchSingleFXExternalAccountBegin());
      const res = await fxService.getSingleFXExternalAccount(id, callSign);
      dispatch(actions.setSingleFXExternalAccount(res.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.fetchSingleFXExternalAccountFailed(msg));
    }
  };
};

export const sendFX = (callSign: string, body: ISendFX) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.sendFXBegin());
      const res = await fxService.sendFX(callSign, body);
      dispatch(actions.sendFXSuccess(res.data.data));
      toast.success("FX transaction initiated successfully");
      return res.data.message;
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.sendFXFailed(msg));
    }
  };
};

export const activateFxType = (
  fxId: number,
  callSign: string,
  body: IActivateFxType
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.activeFxTypeBegin());
      const res = await fxService.activateFxType(fxId, callSign, body);
      dispatch(actions.activateFxTypeSuccess());
      toast.success("FX feature successfully activated");
      return res.data.message;
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.activateFxTypeFailed(msg));
    }
  };
};

export const receiveFX = (callSign: string, body: IReceiveFXBody) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.receiveFXBegin());
      const res = await fxService.receiveFX(callSign, body);
      dispatch(actions.receiveFXSuccess(res.data.data));
      toast.success("FX transaction initiated successfully");
      return res.data.message;
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.receiveFXFailed(msg));
    }
  };
};

export const deleteFX = (id: number, callSign: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchSingleFXBegin());
      const res = await fxService.getSingleFX(id, callSign);
      dispatch(actions.setSingleFX(res.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.fetchSingleFXFailed(msg));
    }
  };
};

export const addFXRecipient = (callSign: string, body: IFXAddRecipient) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.addFXRecipientBegin());
      await fxService.addRecipient(callSign, body);
      dispatch(actions.addFXRecipientEnd());
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.addFXRecipientEnd(msg));
    }
  };
};

export const requestFX = (
  callSign: string,
  body: IRequestFX,
  callback?: () => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.requestFXBegin());
      const res = body?.id
        ? await fxService.reInitiateFXRequest(body.id, callSign, body)
        : await fxService.requestFX(callSign, body);
      toast.success(res.data.message);
      dispatch(actions.requestFXEnd());
      if (callback) callback();
      return true;
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.requestFXEnd(msg));
    }
  };
};

export const addFXExternalAccount = (
  callSign: string,
  body: IFXAddExternalAccount
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.addFXExternalAccountBegin());
      const res = await fxService.addExternalAccount(callSign, body);
      dispatch(actions.addFXExternalAccountEnd());
      toast.success(res.data.message);
      return res.data.message;
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.addFXExternalAccountEnd(msg));
    }
  };
};

export const addFXBeneficiary = (callSign: string, body: IFXAddBeneficiary) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.addFXBeneficiaryBegin());
      const res = await fxService.addBeneficiary(callSign, body);
      dispatch(actions.addFXBeneficiaryEnd());
      toast.success(res.data.message);
      return res.data.message;
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.addFXBeneficiaryEnd(msg));
    }
  };
};

export const uploadReceiveFXPaymentProof = (
  callSign: string,
  body: IFXUploadPaymentProof
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.uploadFXPaymentProofBegin());
      const res = await fxService.uploadPaymentProof(callSign, body);
      dispatch(actions.uploadFXPaymentProofEnd());
      toast.success(res.data.message);
      return res.data.message;
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.uploadFXPaymentProofEnd(msg));
    }
  };
};

export const getSubBusinessFXs = (callSign: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchAllFXsBegin());
      const res = await fxService.getSubBusinessFXs(callSign);
      dispatch(actions.setAllFXs(res.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.fetchAllFXsFailed(msg));
    }
  };
};

export const subBusinessRequestFX = (
  callSign: string,
  body: IRequestFX,
  callback?: () => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.requestFXBegin());

      const res = body.id
        ? await fxService.subBusinessReinitiateFXRequest(
            body.id,
            callSign,
            body
          )
        : await fxService.subBusinessSRequestFX(callSign, body);

      toast.success(res.data.message);
      dispatch(actions.requestFXEnd());
      if (callback) callback();
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.requestFXEnd(msg));
    }
  };
};

export const getSubBusinessFXExternalAccounts = (
  callSign: string,
  params: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchAllFXExternalAccountsBegin());
      const res = await fxService.getSubBusinessFXExternalAccounts(
        callSign,
        params
      );
      dispatch(actions.setAllFXExternalAccounts(res.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.fetchAllFXExternalAccountsFailed(msg));
    }
  };
};

export const subBusinessAddFXExternalAccount = (
  callSign: string,
  body: IFXAddExternalAccount
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.addFXExternalAccountBegin());
      const res = await fxService.subBusinessAddExternalAccount(callSign, body);
      dispatch(actions.addFXExternalAccountEnd());
      toast.success(res.data.message);
      return res.data.message;
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.addFXExternalAccountEnd(msg));
    }
  };
};

export const subBusinessGetFXFees = (
  callSign: string,
  body: IGetFXFees,
  signal?: AbortSignal
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getFXFeeBegin());
      const res = await fxService.subBusinessGetFXFee(callSign, body, signal);
      dispatch(actions.setFXFee(res.data.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.getFXFeeFailed(msg));
    }
  };
};

export const subBusinessReceiveFX = (
  callSign: string,
  body: IReceiveFXBody
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.receiveFXBegin());
      const res = await fxService.subBusinessReceiveFX(callSign, body);
      dispatch(actions.receiveFXSuccess(res.data.data));
      toast.success("FX transaction initiated successfully");
      return res.data.message;
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.receiveFXFailed(msg));
    }
  };
};

export const subBusinessUploadReceiveFXPaymentProof = (
  callSign: string,
  body: IFXUploadPaymentProof
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.uploadFXPaymentProofBegin());
      const res = await fxService.subBusinessUploadPaymentProof(callSign, body);
      dispatch(actions.uploadFXPaymentProofEnd());
      toast.success(res.data.message);
      return res.data.message;
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.uploadFXPaymentProofEnd(msg));
    }
  };
};

export const subBusinessSendFX = (callSign: string, body: ISendFX) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.sendFXBegin());
      const res = await fxService.subBusinessSendFX(callSign, body);
      dispatch(actions.sendFXSuccess(res.data.data));
      toast.success("FX transaction initiated successfully");
      return res.data.message;
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.sendFXFailed(msg));
    }
  };
};

export const subBusinessGetSingleFX = (id: number, callSign: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchSingleFXBegin());
      const res = await fxService.subBusinessGetSingleFX(id, callSign);
      dispatch(actions.setSingleFX(res.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.fetchSingleFXFailed(msg));
    }
  };
};

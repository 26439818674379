import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QuotesResponse } from "../models/quote";

const pagination = {
  next: 0,
  total: 0,
  prevPage: 0,
  lastPage: 0,
  pageSize: 10,
  hasNext: false,
  currentPage: 1,
  hasPrevious: false
};

const initialState: QuotesResponse = {
  loading: false,
  quotes: null,
  pagination
};

export const QuoteSlice = createSlice({
  name: "quotes",
  initialState,
  reducers: {
    error: (state: QuotesResponse) => {
      state.loading = false;
    },
    gettingQuotes: (state: QuotesResponse) => {
      state.loading = true;
    },
    setallQuotes: (state: QuotesResponse, action: PayloadAction<any>) => {
      state.loading = false;
      state.quotes = action.payload?.data;
    }
  }
});

export const actions = QuoteSlice.actions;
export default QuoteSlice.reducer;

import { useMemo } from "react";
import { useLocation } from "react-router-dom";

/**
 * Custom hook
 * @returns an array of objects containing breadcrumb route information
 */
export const useBreadCrumbs = () => {
  const location = useLocation();

  /**
   * Route object
   * @returns - breadcrumb route object `{to, title}`:
   * - `to` - route path;
   * - `title` - route title;
   */
  const breadcrumbs = useMemo(() => {
    const paths = location.pathname.split("/").filter((v) => v.length > 0);

    const crumblist = paths.map((subpath, idx) => {
      const to = "/" + paths.slice(0, idx + 1).join("/");
      const title = subpath;
      return { to, title };
    });

    return [{ to: "/", title: "Home" }, ...crumblist];
  }, [location.pathname]);

  return breadcrumbs;
};

import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/quote.reducer";
import instance from "data/axios-setup";
import { onResponseSuccess } from "data/error-mapping";

export const getAllQuotes = (
  params: { page: number; perpage: number },
  subBusinessCallSign: string
) => {
  return async (dispatch: Dispatch) => {
    if (!subBusinessCallSign) return;
    try {
      const res = await instance.get(`/quotes/user/${subBusinessCallSign}`, {
        params
      });
      onResponseSuccess(res, () => {
        dispatch(actions.setallQuotes(res.data));
      });
    } catch (err: any) {
      dispatch(actions.error());
    }
  };
};

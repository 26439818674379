import { createSlice } from "@reduxjs/toolkit";
import { IExchangeRate, PurchaseCountries } from "../models/airtime-data";

interface AirtimeAndDataState {
  gettingCountries: boolean;
  purchaseCountries: PurchaseCountries[];
  gettingMobileOperators: boolean;
  mobileOperators: any;
  purchasing: boolean;
  purchaseSuccess: boolean;
  gettingExchangeRate: boolean;
  exchangeRate: IExchangeRate | null;
}

const initialState: AirtimeAndDataState = {
  gettingCountries: false,
  purchaseCountries: [],
  gettingMobileOperators: false,
  mobileOperators: [],
  purchasing: false,
  purchaseSuccess: false,
  gettingExchangeRate: false,
  exchangeRate: null
};

export const AirtimeAndDataSlice = createSlice({
  name: "airtimeAndData",
  initialState,
  reducers: {
    error: (state: AirtimeAndDataState) => {
      state.gettingCountries = false;
      state.gettingMobileOperators = false;
      state.purchasing = false;
      state.gettingExchangeRate = false;
    },
    gettingPurchaseCountries: (state: AirtimeAndDataState) => {
      state.gettingCountries = true;
    },
    gettingMobileOperators: (state: AirtimeAndDataState) => {
      state.gettingMobileOperators = true;
    },
    settingAllPurchaseCountries: (state: AirtimeAndDataState, action) => {
      state.purchaseCountries = action.payload;
      state.gettingCountries = false;
    },
    settingAllMobileOperators: (state: AirtimeAndDataState, action) => {
      state.mobileOperators = action.payload;
      state.gettingMobileOperators = false;
    },
    purchasingAirtime: (state: AirtimeAndDataState) => {
      state.purchasing = true;
      state.purchaseSuccess = false;
    },
    purchaseSuccessful: (state: AirtimeAndDataState) => {
      state.purchaseSuccess = true;
      state.purchasing = false;
    },
    gettingExchangeRate: (state: AirtimeAndDataState) => {
      state.gettingExchangeRate = true;
    },
    settingExchangeRate: (state: AirtimeAndDataState, action) => {
      state.gettingExchangeRate = false;
      state.exchangeRate = action.payload;
    }
  }
});

export const actions = AirtimeAndDataSlice.actions;

export default AirtimeAndDataSlice.reducer;

import { IPagination } from "data/models/base";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  INotification,
  INotificationState,
  NotificationType
} from "../models/notification";

const pagination: IPagination = {
  next: 2,
  total: 0,
  prevPage: 0,
  lastPage: 0,
  pageSize: 10,
  hasNext: true,
  currentPage: 1,
  hasPrevious: false
};

const initialState: INotificationState = {
  error: "",
  updatingNotification: false,
  notifications: {
    promotion: { data: [], pagination, loading: false },
    transaction: { data: [], pagination, loading: false }
  }
};

export const NotificationSlice = createSlice({
  initialState,
  name: "notification",
  reducers: {
    error: (state: INotificationState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },

    gettingNotifications: (
      state: INotificationState,
      action: PayloadAction<NotificationType>
    ) => {
      state.notifications[action.payload].loading = true;
    },

    updatingNotification: (state: INotificationState) => {
      state.updatingNotification = true;
    },

    updateNotification: (
      state: INotificationState,
      action: PayloadAction<{ type: NotificationType; id: number }>
    ) => {
      const index = state.notifications[action.payload.type].data?.findIndex(
        (noti) => noti.id === action.payload.id
      );
      state.notifications[action.payload.type].data[index].clicked = true;
      state.updatingNotification = false;
    },

    readAllNotification: (
      state: INotificationState,
      action: PayloadAction<NotificationType>
    ) => {
      state.notifications[action.payload].data.forEach((n) => {
        if (!n.clicked) n.clicked = true;
      });
    },

    setNotifications: (
      state: INotificationState,
      action: PayloadAction<{
        data: INotification[];
        type: NotificationType;
        pagination: IPagination;
      }>
    ) => {
      state.notifications[action.payload.type].data = action.payload.data;
      state.notifications[action.payload.type].pagination =
        action.payload.pagination;
      state.notifications[action.payload.type].loading = false;
    }
  }
});

export const actions = NotificationSlice.actions;
export default NotificationSlice.reducer;

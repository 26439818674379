import {
  Filter,
  ITransaction,
  PaginationState,
  ITransactionError,
  ITransactionState,
  TTransactionLoading,
  ITransactionSummary
} from "../models/transaction";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const pagination = {
  next: 0,
  total: 0,
  prevPage: 0,
  lastPage: 0,
  pageSize: 10,
  hasNext: false,
  currentPage: 1,
  hasPrevious: false
};

export const filterInitialState: Filter = {
  dateType: "day",
  from: undefined,
  date: undefined,
  status: undefined,
  dateFormat: "dd/MM/yyyy",
  showMonthYearPicker: false
};

const initialState: ITransactionState = {
  error: null,
  transactionById: null,
  filter: filterInitialState,
  selectedTransactionId: null,
  transaction: { data: [], pagination },
  airtimeData: { data: [], pagination },
  conversions: { data: [], pagination },
  transactions: { data: [], pagination },
  singleWalletFilter: filterInitialState,
  receentTransactions: { data: [], pagination },
  status: {
    getTransactions: "idle",
    getSingleTransaction: "idle",
    getTransactionById: "idle",
    getRecentTransactions: "idle",
    getConversionTransactions: "idle",
    getAirtimeDataTransactions: "idle"
  },
  transactionSummary: {
    error: "",
    loading: false,
    data: null
  }
};

export const TransactionSlice = createSlice({
  initialState,
  name: "transaction",
  reducers: {
    loading: (
      state: ITransactionState,
      action: PayloadAction<TTransactionLoading>
    ) => {
      state.status[action.payload] = "loading";
      if (action.payload === "getTransactions") state.transactions.data = [];
      if (action.payload === "getSingleTransaction") {
        state.transaction.data = [];
      }
    },

    idle: (
      state: ITransactionState,
      action: PayloadAction<TTransactionLoading>
    ) => {
      state.status[action.payload] = "idle";
    },

    error: (
      state: ITransactionState,
      action: PayloadAction<ITransactionError>
    ) => {
      state.error = action.payload.msg;
      state.status[action.payload.for] = "failed";
    },
    setTransactions: (
      state: ITransactionState,
      action: PayloadAction<{
        data: ITransaction[];
        pagination: PaginationState;
      }>
    ) => {
      state.status.getTransactions = "succeeded";
      state.transactions.data = action.payload.data;
      state.transactions.pagination = action.payload.pagination;
    },

    setConversionTransactions: (
      state: ITransactionState,
      action: PayloadAction<{
        data: ITransaction[];
        pagination: PaginationState;
      }>
    ) => {
      state.conversions.data = action.payload.data;
      state.status.getConversionTransactions = "succeeded";
      state.conversions.pagination = action.payload.pagination;
    },

    setAirtimeDataTransactions: (
      state: ITransactionState,
      action: PayloadAction<{
        data: ITransaction[];
        pagination: PaginationState;
      }>
    ) => {
      state.airtimeData.data = action.payload.data;
      state.status.getAirtimeDataTransactions = "succeeded";
      state.airtimeData.pagination = action.payload.pagination;
    },

    setRecentTransactions: (
      state: ITransactionState,
      action: PayloadAction<{
        data: ITransaction[];
        pagination: PaginationState;
      }>
    ) => {
      state.status.getRecentTransactions = "succeeded";
      state.receentTransactions.data = action.payload.data;
      state.receentTransactions.pagination = action.payload.pagination;
    },
    setTransactionById: (
      state: ITransactionState,
      action: PayloadAction<{ data: ITransaction[] }>
    ) => {
      state.status.getTransactionById = "succeeded";
      state.transactionById = action.payload.data?.[0] || action.payload.data;
    },

    setTransactionFilter: (
      state: ITransactionState,
      action: PayloadAction<{ data: Partial<Filter> }>
    ) => {
      state.filter = { ...action.payload.data };
    },

    setSingleWalletTransactionFilter: (
      state: ITransactionState,
      action: PayloadAction<{ data: Partial<Filter> }>
    ) => {
      state.singleWalletFilter = { ...action.payload.data };
    },

    setSingleTransaction: (
      state: ITransactionState,
      action: PayloadAction<{
        data: ITransaction[];
        pagination: PaginationState;
      }>
    ) => {
      state.transaction.data = action.payload.data;
      state.status.getSingleTransaction = "succeeded";
      state.transaction.pagination = action.payload.pagination;
    },
    selectTransaction: (
      state: ITransactionState,
      action: PayloadAction<number>
    ) => {
      state.selectedTransactionId = action.payload;
    },
    getTransactionSummaryBegin: (state) => {
      state.transactionSummary.loading = true;
      state.transactionSummary.error = "";
    },
    getTransactionSummaryFailed: (state, action: PayloadAction<string>) => {
      state.transactionSummary.loading = false;
      state.transactionSummary.error = action.payload;
    },
    getTransactionSummarySuccess: (
      state,
      action: PayloadAction<ITransactionSummary>
    ) => {
      state.transactionSummary.loading = false;
      state.transactionSummary.data = action.payload;
    }
  }
});

export const actions = TransactionSlice.actions;
export default TransactionSlice.reducer;

import { useAppDispatch, useAppSelector } from "./useReduxState";
import { getSingleFeature } from "app/store/action-creators/feature-management.action";

export const useFeatureMangement = () => {
  const dispatch = useAppDispatch();
  const [singleFeature] = useAppSelector((state) => [
    state.featureManagement.singleFeature
  ]);

  const dispatchSingleFeature = (feature: string) => {
    dispatch(getSingleFeature(feature));
  };

  return {
    singleFeature,
    dispatchSingleFeature
  };
};

export const useGetFeatures = (...features: string[]) => {
  const dispatch = useAppDispatch();

  const getFeatures = async (cb: any) => {
    const promises = features.map((f) => dispatch(getSingleFeature(f)));
    const res = await Promise.all(promises);
    cb(res);
  };

  return { getFeatures };
};

import { IObject } from "../../../data/models/base";
import { DEFAULT_STATUS_TYPE } from "data/error-mapping";

type TransactionType = "credit" | "debit" | "both";
type TransactionDateType = "day" | "month" | "year" | "custom";
type TransactionStatus = "success" | "pending" | "failed" | "all";

export type Filter = {
  currency?: string;
  date?: string | Date;
  from?: string | Date;
  fiatWallet?: number;
  dateFormat?: string;
  cryptoWallet?: number;
  type?: TransactionType;
  showYearPicker?: boolean;
  status?: TransactionStatus;
  showMonthYearPicker?: boolean;
  dateType?: TransactionDateType;
  cardSubType?: VirtualCardType;
};

export type TTransactionLoading =
  | "getTransactions"
  | "getSingleTransaction"
  | "getTransactionById"
  | "getRecentTransactions"
  | "getConversionTransactions"
  | "getAirtimeDataTransactions";

export type PaginationState = {
  currentPage: number;
  hasNext?: boolean;
  hasPrevious?: boolean;
  lastPage?: number;
  next?: number;
  pageSize: number;
  prevPage?: number;
  total?: number;
};

export interface ITransaction {
  id: number;
  date: string;
  rate?: number;
  type: string;
  amount: string;
  fee: string;
  reason?: string;
  balanceBefore?: string;
  balanceAfter?: string;
  status: string;
  method: string;
  subType: string;
  wallet?: string;
  metaData?: string;
  fxInvoice?: string;
  fxReceipt?: string;
  fxExternalAccount?: string;
  changeraTransactionType: string;
  currency: string;
  currencyType: string;
  createdAt: Date;
  updatedAt: Date;
  reference?: string;
  bankName?: string;
  description: string;
  phone: null | string;
  bankAccountName?: string;
  bankAccountNumber?: string;
  paymentGateways?: string;
  cardName?: string;
  sourceAccountName?: string;
  sourceAccountNumber?: string;
  sourceBankName?: string;
  fxSourceDepositInstructions?: string;
}

export interface IFXSourceDepositInstructions {
  amount: string;
  currency: string;
  bank_name: string;
  payment_rail: string;
  bank_address: string;
  deposit_message: string;
  bank_routing_number: string;
  bank_account_number: string;
  bank_beneficiary_name: string;
}

export interface ITransactionError {
  msg: string;
  for: TTransactionLoading;
}

export interface ITransactionsState {
  data: ITransaction[];
  pagination: PaginationState;
}

export interface ITransactionState {
  filter: Filter;
  error: null | string;
  singleWalletFilter: Filter;
  transaction: ITransactionsState;
  airtimeData: ITransactionsState;
  conversions: ITransactionsState;
  transactions: ITransactionsState;
  receentTransactions: ITransactionsState;
  transactionById: ITransaction | null;
  selectedTransactionId: number | null;
  transactionSummary: {
    error: string;
    data: ITransactionSummary | null;
    loading: boolean;
  };

  status: {
    getTransactions: DEFAULT_STATUS_TYPE;
    getTransactionById: DEFAULT_STATUS_TYPE;
    getSingleTransaction: DEFAULT_STATUS_TYPE;
    getRecentTransactions: DEFAULT_STATUS_TYPE;
    getConversionTransactions: DEFAULT_STATUS_TYPE;
    getAirtimeDataTransactions: DEFAULT_STATUS_TYPE;
  };
}

export enum ChangeraTransactionType {
  LIEN = "lien",
  AIRTIME_VTU = "airtime-vtu",
  DATA_VTU = "data-vtu",
  PAY_MONEY = "pay-money",
  SEND_MONEY = "send-money",
  EVENT_TICKET = "ticket-purchase",
  FAUCET = "faucet",
  REFERRAL = "referral",
  REWARD = "reward",
  FX = "fx",
  FUNDING_WALLET = "fund-wallet",
  FUNDING_SUB_WALLET = "fund-sub-wallet",
  WITHDRAWAL = "withdrawal",
  FIAT_CONVERSION = "fiat-conversion",
  CRYPTO_CONVERSION = "crypto-conversion",
  VIRTUAL_CARD = "virtual-card",
  VIRTUAL_CARD_FUNDING = "fund-virtual-card",
  VIRTUAL_CARD_WITHDRAWAL = "virtual-card-withdrawal",
  VIRTUAL_CARD_DEBIT = "virtual-card-debit",
  VIRTUAL_CARD_CREDIT = "virtual-card-refund",
  SUBSCRIPTION = "subscription"
}

export enum VirtualCardType {
  VIRTUAL_CARD_FUNDING = "virtual-card-fund",
  VIRTUAL_CARD_WITHDRAWAL = "virtual-card-withdrawal",
  VIRTUAL_CARD_DEBIT = "virtual-card-debit",
  VIRTUAL_CARD_CREDIT = "virtual-card-refund"
}

export interface ITransactionSummary {
  total: [
    {
      type: string;
      total: string;
    },
    {
      type: string;
      total: string;
    }
  ];
  yearlyData: IObject[];
  monthlyData: IObject[];
  weeklyData: IObject[];
  dailyData: IObject[];
}

export type GenerateTransactionStatementBody = {
  type: ChangeraTransactionType;
};

import {
  createableVirtualAccounts,
  createableNGNVirtualAccounts
} from "data/utils";

import {
  getAllVirtualAccounts,
  getAllSubBusinessVirtualAccounts
} from "app/store";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { capitalize } from "lodash";
import { getSingleFeature } from "app/store/action-creators/feature-management.action";
import featureManagementService from "data/services/feature-management.service";
import { onResponseSuccess } from "data/error-mapping";
import { IFeature } from "data/models/feature-management";

/**
 * Custom hook to for virtual accounts
 * @returns -
 * - `virtualAccounts` - created virtual accounts in the store
 * - `remainingVirtualAccounts` - supported virtual accounts that are yet to be created
 */
export const useAvalaibleVirtualAccounts = () => {
  const dispatch = useAppDispatch();
  const [virtualAccounts, features] = useAppSelector((state) => [
    state.virtualAccounts.allVirtualAccounts,

    state.featureManagement.singleFeature.data?.metaData
  ]);

  const [loading, setLoading] = useState(false);
  const [singleFeature, setSingleFeature] = useState<IFeature | null>(null);

  const fetchVbaFeature = async () => {
    try {
      setLoading(true);
      const res = await featureManagementService.getSingleFeature(
        "virtual-account-request"
      );
      onResponseSuccess(res, () => {
        setSingleFeature(res.data.data);
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setSingleFeature(null);
    }
  };

  useEffect(() => {
    fetchVbaFeature();
  }, []);

  const availableNGNBanks: {
    bank: string;
    active: boolean;
    provider: string;
  }[] = useMemo(() => {
    if (loading || !singleFeature) return [];
    return singleFeature?.metaData?.availableNGNBanks;
  }, [features, singleFeature]);

  const createdVirtualAccountsMap = useMemo<Map<string, number>>(() => {
    return virtualAccounts.data
      .filter((filteredVAcc) => filteredVAcc.version !== "v1")
      .reduce((res, { currency }) => {
        res.set(
          currency.toLowerCase(),
          res.has(currency.toLowerCase())
            ? (res.get(currency.toLowerCase()) as number) + 1
            : 1
        );

        return res;
      }, new Map<string, number>());
  }, [virtualAccounts]);

  const createdNGNVirtualAccountsMap = useMemo(() => {
    return virtualAccounts.data
      .filter((filteredNGN) => filteredNGN.currency.toLowerCase() === "ngn")
      .reduce(
        (
          res: {
            [key: string]: any;
          },
          { bankName }
        ) => {
          availableNGNBanks
            .filter(
              (el: { bank: string; active: boolean; provider: string }) =>
                el.active &&
                capitalize(bankName || "")?.startsWith(
                  capitalize(el.bank || ""),
                  0
                )
            )
            .map((el: { bank: string; active: boolean; provider: string }) => {
              res[el.bank] = 1;
            });

          return res;
        },
        {}
      );
  }, [virtualAccounts.data, features, loading, singleFeature]);

  const remainingVirtualAccounts = createableVirtualAccounts.filter((res) => {
    if (res.currency === "ngn") {
      return (
        !createdVirtualAccountsMap.has(res.currency.toLocaleLowerCase()) ||
        (createdVirtualAccountsMap.get(res.currency.toLowerCase()) as number) <
          Object.keys(availableNGNBanks.filter((crtblVAcc) => crtblVAcc.active))
            .length
      );
    } else {
      return !createdVirtualAccountsMap.has(res.currency.toLocaleLowerCase());
    }
  });

  // fetch all virtual accounts
  const dispatchVirtualAccounts = () => {
    dispatch(getAllVirtualAccounts());
  };

  // fetch all vba features

  const dispatchVirtualAccountFeatures = () => {
    dispatch(getSingleFeature("virtual-account-request"));
  };

  return {
    loading,
    singleFeature,
    virtualAccounts,
    remainingVirtualAccounts,
    createdNGNVirtualAccountsMap,
    dispatchVirtualAccounts,
    dispatchVirtualAccountFeatures,
    availableNGNBanks
  };
};

/**
 * Custom hook to for virtual accounts
 * @returns -
 * - `virtualAccounts` - created virtual accounts in the store
 * - `remainingVirtualAccounts` - supported virtual accounts that are yet to be created
 */
export const useSubBusinessAvalaibleVirtualAccounts = () => {
  const dispatch = useAppDispatch();
  const [callSign, virtualAccounts] = useAppSelector((state) => [
    state.subBusinesses?.subBusinessInfo?.callSign,
    state.virtualAccounts.subBusiness.allVirtualAccounts
  ]);

  //

  const createdVirtualAccountsMap = useMemo<Map<string, number>>(() => {
    return virtualAccounts.data
      .filter(
        (filteredVAcc) =>
          !(
            filteredVAcc.bankName?.startsWith("VFD", 0) ||
            filteredVAcc.bankName?.startsWith("Providus", 0)
          )
      )
      .reduce((res, { currency }) => {
        res.set(
          currency.toLowerCase(),
          res.has(currency.toLowerCase())
            ? (res.get(currency.toLowerCase()) as number) + 1
            : 1
        );

        return res;
      }, new Map<string, number>());
  }, [virtualAccounts]);

  const createdNGNVirtualAccountsMap = useMemo(() => {
    return virtualAccounts.data
      .filter((filteredNGN) => filteredNGN.currency.toLowerCase() === "ngn")
      .reduce(
        (
          res: {
            [key: string]: any;
          },
          { bankName }
        ) => {
          // if (currency.toLowerCase() === "ngn" && bankName.startsWith("VFD", 0)) {
          //   res["VFD"] = 1;
          // } else if (
          //   currency.toLowerCase() === "ngn" &&
          //   bankName.startsWith("Providus")
          // ) {
          //   res["providus"] = 1;
          // }

          createableNGNVirtualAccounts
            .filter((el) => el.active && bankName?.startsWith(el.bank, 0))
            .map((el) => {
              res[el.bank] = 1;
            });

          return res;
        },
        {}
      );
  }, [virtualAccounts.data]);

  const remainingVirtualAccounts = createableVirtualAccounts.filter((res) => {
    if (res.currency === "ngn") {
      return (
        !createdVirtualAccountsMap.has(res.currency.toLocaleLowerCase()) ||
        (createdVirtualAccountsMap.get(res.currency.toLowerCase()) as number) <
          Object.keys(
            createableNGNVirtualAccounts.filter((crtblVAcc) => crtblVAcc.active)
          ).length
      );
    } else {
      return !createdVirtualAccountsMap.has(res.currency.toLocaleLowerCase());
    }
  });

  // fetch all virtual accounts
  const dispatchVirtualAccounts = () => {
    dispatch(getAllSubBusinessVirtualAccounts(callSign));
  };

  return {
    virtualAccounts,
    dispatchVirtualAccounts,
    remainingVirtualAccounts,
    createdNGNVirtualAccountsMap
  };
};

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Stack,
  FormControl,
  Select
} from "@chakra-ui/react";
import { useAppDispatch, useAvalaibleSubBusinessCryptoWallet } from "app/hooks";
import { setSelectedCryptoWallet } from "app/store";
import { useState } from "react";
import { FundCryptoWalletOptionsProps } from "../base-models";

const ViewCryptoWalletNetworkOptions = ({
  currency,
  isOpen,
  onClose
}: FundCryptoWalletOptionsProps) => {
  const dispatch = useAppDispatch();
  const { loading, getCreatedNetworks } = useAvalaibleSubBusinessCryptoWallet();

  const [network, setNetwork] = useState("");

  const onFundWalletHandler = () => {
    dispatch(
      setSelectedCryptoWallet({
        currency,
        network
      })
    );
    return onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Network</ModalHeader>
        <ModalBody>
          <Stack>
            {currency === "usdt" ? (
              <FormControl>
                <Select
                  placeholder={loading ? "loading" : "Choose Network"}
                  value={network}
                  onChange={(e) => setNetwork(e.target.value)}
                >
                  {getCreatedNetworks("usdt")
                    .filter((el) => !!el.disabled)
                    .map((el, idx) => (
                      <option
                        key={idx}
                        value={el.network}
                        disabled={!el.disabled}
                      >
                        {el.title}
                      </option>
                    ))}
                </Select>
              </FormControl>
            ) : currency === "usdc" ? (
              <FormControl>
                <Select
                  placeholder={loading ? "loading" : "Choose Network"}
                  value={network}
                  onChange={(e) => setNetwork(e.target.value)}
                >
                  {getCreatedNetworks("usdc")
                    .filter((el) => !!el.disabled)
                    .map((el, idx) => (
                      <option
                        key={idx}
                        value={el.network}
                        disabled={!el.disabled}
                      >
                        {el.title}
                      </option>
                    ))}
                </Select>
              </FormControl>
            ) : null}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            w={"full"}
            colorScheme="primary"
            mr={3}
            variant="outline"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            w="full"
            colorScheme={"primary"}
            disabled={!network}
            onClick={onFundWalletHandler}
          >
            View
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ViewCryptoWalletNetworkOptions;

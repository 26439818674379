import { RecentDashboardActivities } from "app/store/models/dashboard";
import { AxiosResponse } from "axios";
import instance from "data/axios-setup";

class DashboardService {
  async getRecentActivities(
    callSign: string | null
  ): Promise<AxiosResponse<{ data: RecentDashboardActivities }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/dashboard/${callSign}`);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new DashboardService();

import {
  ISendFX,
  IFXDrawer,
  IGetFXFees,
  IRequestFX,
  IReceiveFXBody,
  IActivateFxType,
  IFXAddBeneficiary,
  IFXAddExternalAccount,
  IFXUploadPaymentProof
} from "app/store/models/fx";

import {
  sendFX,
  getAllFXs,
  getFXFees,
  receiveFX,
  requestFX,
  getSingleFX,
  activateFxType,
  addFXBeneficiary,
  getSubBusinessFXs,
  subBusinessSendFX,
  addFXExternalAccount,
  subBusinessRequestFX,
  subBusinessGetFXFees,
  subBusinessReceiveFX,
  getAllFXBeneficiaries,
  subBusinessGetSingleFX,
  getAllFXExternalAccounts,
  uploadReceiveFXPaymentProof,
  subBusinessAddFXExternalAccount,
  getSubBusinessFXExternalAccounts,
  subBusinessUploadReceiveFXPaymentProof
} from "app/store/action-creators/fx.action";
import { getCallSign } from "data/utils";
import { actions } from "app/store/reducers/fx.reducer";
import { useAppDispatch, useAppSelector } from "./useReduxState";

export const useFx = () => {
  const callSign = getCallSign();
  const dispatch = useAppDispatch();

  const [
    allFXState,
    fxFeeState,
    fxDrawerState,
    singleFXState,
    sendFXState,
    receiveFXState,
    requestFXState,
    fxBeneficiaryState,
    fxAddBeneficiaryState,
    fxExternalAccountState,
    fxAddExternalAccountState,
    fxUploadPaymentProofState,
    activateFxTypeState
  ] = useAppSelector((state) => [
    state.fx.fx.all,
    state.fx.fx.fee,
    state.fx.drawer,
    state.fx.fx.single,
    state.fx.fx.send,
    state.fx.fx.receive,
    state.fx.fx.request,
    state.fx.beneficiary.all,
    state.fx.beneficiary.add,
    state.fx.externalAccount.all,
    state.fx.externalAccount.add,
    state.fx.fx.uploadProof,
    state.fx.fx.activate
  ]);

  const usdFX = allFXState.data?.find((fx) => fx.currency === "usd");
  const isUsdFXApproved = usdFX?.changeraStatus === "approved";

  const dispatchAllFXs = () => {
    if (!callSign || allFXState.loading) return;
    dispatch(getAllFXs(callSign));
  };

  const dispatchSendFX = async (body: ISendFX) => {
    if (!callSign) return;
    return await dispatch(sendFX(callSign, body));
  };

  const dispatchActivateFxType = async (
    fxId: number,
    body: IActivateFxType
  ) => {
    if (!callSign) return;
    const isSuccess = await dispatch(activateFxType(fxId, callSign, body));
    if (isSuccess) dispatchAllFXs();
    return isSuccess;
  };

  const dispatchReceiveFX = async (body: IReceiveFXBody) => {
    if (!callSign) return;
    return await dispatch(receiveFX(callSign, body));
  };

  const dispatchSingleFX = (id: number) => {
    if (!id || !callSign || singleFXState.loading) return;
    dispatch(getSingleFX(id, callSign));
  };

  const dispatchFXFee = (body: IGetFXFees, signal?: AbortSignal) => {
    if (!callSign) return;
    dispatch(getFXFees(callSign, body, signal));
  };

  const dispatchFXPaymentProof = async (body: IFXUploadPaymentProof) => {
    if (!callSign) return;
    return await dispatch(uploadReceiveFXPaymentProof(callSign, body));
  };

  const dispatchFXExternalAccounts = (
    page: number = 1,
    perpage: number = 100
  ) => {
    if (!callSign || fxExternalAccountState.loading) return;
    dispatch(getAllFXExternalAccounts(callSign, { page, perpage }));
  };

  const dispatchRequestFX = async (body: IRequestFX, callback?: () => void) => {
    if (!callSign || requestFXState.loading) return;

    const cb = () => {
      if (callback) callback();
      dispatchAllFXs();
    };

    return await dispatch(requestFX(callSign, body, cb));
  };

  const dispatchAddFXExternalAccount = async (body: IFXAddExternalAccount) => {
    if (!callSign || fxAddExternalAccountState.loading) return;
    return await dispatch(addFXExternalAccount(callSign, body));
  };

  const dispatchSetFXDrawer = (data?: Partial<IFXDrawer>) => {
    dispatch(actions.setFXDrawerState(data));
  };

  const dispatchAddFXBeneficiary = async (body: IFXAddBeneficiary) => {
    if (!callSign || fxAddBeneficiaryState.loading) return;
    return await dispatch(addFXBeneficiary(callSign, body));
  };

  const dispatchFXBeneficiaries = (page: number = 1, perpage: number = 100) => {
    if (!callSign || fxBeneficiaryState.loading) return;
    dispatch(getAllFXBeneficiaries(callSign, { page, perpage }));
  };

  return {
    usdFX,
    allFXState,
    fxFeeState,
    singleFXState,
    fxDrawerState,
    sendFXState,
    dispatchFXFee,
    receiveFXState,
    requestFXState,
    dispatchSendFX,
    dispatchAllFXs,
    isUsdFXApproved,
    dispatchSingleFX,
    dispatchReceiveFX,
    dispatchRequestFX,
    fxBeneficiaryState,
    dispatchSetFXDrawer,
    activateFxTypeState,
    fxAddBeneficiaryState,
    dispatchFXPaymentProof,
    fxExternalAccountState,
    dispatchActivateFxType,
    dispatchFXBeneficiaries,
    dispatchAddFXBeneficiary,
    fxUploadPaymentProofState,
    fxAddExternalAccountState,
    dispatchFXExternalAccounts,
    dispatchAddFXExternalAccount
  };
};

export const useSubBusinessFx = () => {
  const dispatch = useAppDispatch();

  const [
    allFXState,
    fxFeeState,
    fxDrawerState,
    singleFXState,
    sendFXState,
    receiveFXState,
    requestFXState,
    fxExternalAccountState,
    fxAddExternalAccountState,
    fxUploadPaymentProofState,
    callSign
  ] = useAppSelector((state) => [
    state.fx.fx.all,
    state.fx.fx.fee,
    state.fx.drawer,
    state.fx.fx.single,
    state.fx.fx.send,
    state.fx.fx.receive,
    state.fx.fx.request,
    state.fx.externalAccount.all,
    state.fx.externalAccount.add,
    state.fx.fx.uploadProof,
    state?.subBusinesses?.subBusinessInfo?.callSign
  ]);

  const usdFX = allFXState.data?.find((fx) => fx.currency === "usd");
  const isUsdFXApproved = usdFX?.changeraStatus === "approved";

  const dispatchAllFXs = () => {
    if (!callSign || allFXState.loading) return;
    dispatch(getSubBusinessFXs(callSign));
  };

  const dispatchRequestFX = (body: IRequestFX, callback?: () => void) => {
    if (!callSign || requestFXState.loading) return;

    const cb = () => {
      if (callback) callback();
      dispatchAllFXs();
    };

    dispatch(subBusinessRequestFX(callSign, body, cb));
  };

  const dispatchSendFX = async (body: ISendFX) => {
    if (!callSign) return;
    return await dispatch(subBusinessSendFX(callSign, body));
  };

  const dispatchReceiveFX = async (body: IReceiveFXBody) => {
    if (!callSign) return;
    return await dispatch(subBusinessReceiveFX(callSign, body));
  };

  const dispatchFXFee = (body: IGetFXFees, signal?: AbortSignal) => {
    if (!callSign) return;
    dispatch(subBusinessGetFXFees(callSign, body, signal));
  };

  const dispatchFXPaymentProof = async (body: IFXUploadPaymentProof) => {
    if (!callSign) return;
    return await dispatch(
      subBusinessUploadReceiveFXPaymentProof(callSign, body)
    );
  };

  const dispatchFXExternalAccounts = (
    page: number = 1,
    perpage: number = 100
  ) => {
    if (!callSign || fxExternalAccountState.loading) return;
    dispatch(getSubBusinessFXExternalAccounts(callSign, { page, perpage }));
  };

  const dispatchAddFXExternalAccount = async (body: IFXAddExternalAccount) => {
    if (!callSign || fxAddExternalAccountState.loading) return;
    return await dispatch(subBusinessAddFXExternalAccount(callSign, body));
  };

  const dispatchSetFXDrawer = (data?: Partial<IFXDrawer>) => {
    dispatch(actions.setFXDrawerState(data));
  };

  const dispatchSingleFX = (id: number) => {
    if (!id || !callSign || singleFXState.loading) return;
    dispatch(subBusinessGetSingleFX(id, callSign));
  };

  return {
    usdFX,
    allFXState,
    fxFeeState,
    singleFXState,
    fxDrawerState,
    sendFXState,
    dispatchFXFee,
    receiveFXState,
    requestFXState,
    dispatchSendFX,
    dispatchAllFXs,
    isUsdFXApproved,
    dispatchSingleFX,
    dispatchReceiveFX,
    dispatchRequestFX,
    dispatchSetFXDrawer,
    dispatchFXPaymentProof,
    fxExternalAccountState,
    fxUploadPaymentProofState,
    fxAddExternalAccountState,
    dispatchFXExternalAccounts,
    dispatchAddFXExternalAccount
  };
};

import { Dispatch } from "@reduxjs/toolkit";
import {
  EmailCodeGotten,
  error,
  forgetPasswordSuccess,
  loadingBegin,
  emailVerified,
  phoneVerified,
  GettingPhoneCode,
  loginSuccess,
  logoutBegin,
  logoutSuccess,
  PhoneCodeGotten,
  registerSuccess,
  resetPasswordSuccess,
  success,
  saveLoginEmail,
  loading2FA,
  loadingWebAuthn
} from "../reducers/auth.reducer";
import QRCode from "qrcode";
import instance, { instanceV2 } from "data/axios-setup";
import { removeCallSign, removeToken, saveToken } from "data/utils";
import toast from "react-hot-toast";
import { NavigateFunction } from "react-router-dom";
import { AxiosError } from "axios";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
// import { configs } from "data/config";
import authService from "data/services/auth.service";

export const login2FA = (
  navigate: NavigateFunction,
  data: { email: string; code: string; inviteToken: string }
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loading2FA(true));
      const res = await authService.login2FA(data);
      saveToken(res.data.token);
      dispatch(success());

      if (data.inviteToken) {
        const payload = { inviteToken: data.inviteToken };
        await instance.post(`/team-member/accept`, payload);
        toast.success("You have successfully accepted your invitation.");
      }

      return navigate("/auth/choose-business");
    } catch (err) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(error());
    }
  };
};

export const loginUser = (
  navigate: NavigateFunction,
  data: { email: string; password: string },
  teamInvite: string | null,
  token: string | null
) => {
  return (dispatch: Dispatch) => {
    // const notificationKey = configs.PUBLIC_VAPID_KEY;
    // const notificationKey = undefined;

    // const loginWithNotification = () => {
    //   subscribeUser(
    //     data,
    //     () => {
    //       dispatch(loadingBegin());
    //     },
    //     async (res) => {
    //       if (res?.data?.data?.isTwoFactorAuthEnabled) {
    //         dispatch(saveLoginEmail({ email: data.email, token }));
    //         return navigate("/auth/login/2fa");
    //       }

    //       dispatch(loginSuccess(true));
    //       if (teamInvite) {
    //         const iniviteData = {
    //           inviteToken: token
    //         };
    //         await instance.post(`/team-member/accept`, iniviteData);
    //         toast.success("You have successfully accepted your invitation.");
    //         if (!res.data.data.emailVerified || !res.data.data.phoneVerified) {
    //           navigate("/auth/verify");
    //         } else if (!res.data.data.transactionPin) {
    //           navigate("/auth/set-pin");
    //         } else if (
    //           res.data.data.emailVerified &&
    //           res.data.data.phoneVerified
    //         ) {
    //           navigate("/auth/choose-business");
    //         }
    //       } else {
    //         if (!res.data.data.emailVerified || !res.data.data.phoneVerified) {
    //           navigate("/auth/verify");
    //         } else if (!res.data.data.transactionPin) {
    //           navigate("/auth/set-pin");
    //         } else if (
    //           res.data.data.emailVerified &&
    //           res.data.data.phoneVerified
    //         ) {
    //           navigate("/auth/choose-business");
    //         }
    //       }
    //     },
    //     (err) => {
    //       dispatch(error());
    //       const error_ = err as AxiosError<{ message: string }>;
    //       const msg = error_.response?.data?.message || DEFAULT_ERROR_MESSAGE;
    //       toast.error(msg);
    //     }
    //   );
    // };

    const loginWithoutNotification = async () => {
      try {
        dispatch(loadingBegin());
        const response = await instance.post("/auth/login", data);
        if (response.data.isTwoFactorAuthEnabled) {
          dispatch(saveLoginEmail({ email: data.email, token }));
          return navigate("/auth/login/2fa");
        }

        saveToken(response.data.token);
        dispatch(loginSuccess(true));
        const res = await instance.get("/auth/user");

        if (teamInvite) {
          const iniviteData = {
            inviteToken: token
          };
          //console.log("Hey");
          await instance.post(`/team-member/accept`, iniviteData);
          toast.success("You have successfully accepted your invitation.");
          if (!res.data.data.emailVerified || !res.data.data.phoneVerified) {
            navigate("/auth/verify");
          } else if (!res.data.data.transactionPin) {
            navigate("/auth/set-pin");
          } else if (
            res.data.data.emailVerified &&
            res.data.data.phoneVerified
          ) {
            navigate("/auth/choose-business");
          }
        } else {
          //console.log("NOOOO");
          if (!res.data.data.emailVerified || !res.data.data.phoneVerified) {
            navigate("/auth/verify");
          } else if (!res.data.data.transactionPin) {
            navigate("/auth/set-pin");
          } else if (
            res.data.data.emailVerified &&
            res.data.data.phoneVerified
          ) {
            navigate("/auth/choose-business");
          }
        }
      } catch (err) {
        dispatch(error());
        const error_ = err as AxiosError<{ message: string }>;
        const msg = error_.response?.data?.message || DEFAULT_ERROR_MESSAGE;
        toast.error(msg);
      }
    };

    loginWithoutNotification();

    // Notification.requestPermission()
    //   .then((permission) => {
    //     if (permission === "granted") {
    //       // User has granted permission, you can now subscribe them to push notifications
    //       loginWithNotification();
    //     } else {
    //       loginWithoutNotification();
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     loginWithoutNotification();
    //   });
  };
};

export const signUpUser = (
  navigate: NavigateFunction,
  data: {},
  teamInvite: string | null,
  token: string | null
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingBegin());
      const res = await instance.post("/auth/signup", data);
      if (res.status == 201 || 200 || 202) {
        saveToken(res.data.token);
        dispatch(registerSuccess(true));
        if (teamInvite) {
          const iniviteData = {
            inviteToken: token
          };
          await instance.post(`/team-member/accept`, iniviteData);
          toast.success(
            "You have successfully accepted your invitation. Please proceed to verify your phone and email"
          );
          navigate("/auth/verify");
        } else {
          toast.success(
            "You have successfully registered your account. Please verify your phone and email"
          );
          navigate("/auth/verify");
        }
      }
    } catch (err: any) {
      dispatch(error());
      toast.error(err.response.data.message);
    }
  };
};

export const getPhoneVerificationCode = (channel: string, onClose: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(GettingPhoneCode());
      const res = await instanceV2.get(`/auth/verify-phone/${channel}`);
      if (res.status == 201 || 200 || 202) {
        dispatch(PhoneCodeGotten());
        toast.success("Verification code has been sent to your phone number.");
        onClose();
      }
    } catch (err: any) {
      dispatch(error());
      toast.error(err.response.data.message);
    }
  };
};

export const getEmailVerificationCode = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingBegin());
      const res = await instance.get("/auth/verify-email");
      if (res.status == 201 || 200 || 202) {
        dispatch(EmailCodeGotten());
        toast.success("Verification code has been sent to your email.");
      }
    } catch (err: any) {
      dispatch(error());
      toast.error(err.response.data.message);
    }
  };
};

export const verifyPhoneNumber = (data: any) => {
  const { phoneCode } = data;
  const args = { code: phoneCode };
  return async (dispatch: Dispatch) => {
    try {
      dispatch(GettingPhoneCode());
      const res = await instanceV2.post("/auth/verify-phone", args);
      if (res.status == 201 || 200 || 202) {
        dispatch(phoneVerified());
        saveToken(res.data.token);
        toast.success("Phone number has been verified successfully.");
      }
    } catch (err: any) {
      dispatch(error());
      toast.error(err.response.data.message);
    }
  };
};
export const verifyEmailAddress = (data: any) => {
  const { emailCode } = data;
  const args = { code: emailCode };
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingBegin());
      const res = await instance.post("/auth/verify-email", args);
      if (res.status == 201 || 200 || 202) {
        dispatch(emailVerified());
        saveToken(res.data.token);
        toast.success("Email address has been verified successfully.");
      }
    } catch (err: any) {
      dispatch(error());
      toast.error(err.response.data.message);
    }
  };
};

export const forgetPassword = (data: {}) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingBegin());
      const res = await instance.post("/auth/recover-password", data);
      if (res.status == 201 || 200 || 202) {
        dispatch(forgetPasswordSuccess());
        toast.success("Password reset link has been sent to your email.");
      }
    } catch (err: any) {
      dispatch(error());
      toast.error(err.response.data.message);
    }
  };
};

export const resetPassword = (data: any) => {
  const { password, resetToken, email } = data;
  const args = {
    password: password
  };
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingBegin());
      const res = await instance.post(
        `/auth/reset-password?email=${email}&token=${resetToken}`,
        args
      );
      if (res.status == 201 || 200 || 202) {
        dispatch(resetPasswordSuccess());
        toast.success("Password has been reset successfully.");
      }
    } catch (err: any) {
      dispatch(error());
      toast.error(err.response.data.message);
    }
  };
};

export const logOut = (navigate: NavigateFunction) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(logoutBegin());
      dispatch(logoutSuccess());
      removeToken();
      navigate("/auth/login", { replace: true });
      removeCallSign();
      window.location.reload();
    } catch (err) {
      dispatch(error());
    }
  };
};

export const webAuthnAttestationBegin = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingWebAuthn(true));
      const res = await authService.webAuthnAttestationBegin();
      return res.data.data;
    } catch (err) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(error());
      toast.error(msg);
    }
  };
};

export const webAuthnAssertionBegin = (email: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingWebAuthn(true));
      const res = await authService.webAuthnAssertionBegin(email);
      return res.data.data;
    } catch (err) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(error());
      toast.error(msg);
    }
  };
};

export const webAuthnAttestationEnd = (credential: PublicKeyCredential) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingWebAuthn(true));
      await authService.webAuthnAttestationEnd(credential);
      dispatch(success());
      dispatch(loadingWebAuthn(false));

      toast.success("Success");
      return true;
    } catch (err) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(error());
      toast.error(msg);
    }
  };
};

export const webAuthnAssertionEnd = (
  email: string,
  navigate: NavigateFunction,
  credential: PublicKeyCredential
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingWebAuthn(true));
      const res = await authService.webAuthnAssertionEnd(email, credential);

      saveToken(res.data.token);
      dispatch(loginSuccess(true));
      dispatch(loadingWebAuthn(false));
      navigate("/auth/choose-business");
    } catch (err) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(error());
      toast.error(msg);
    }
  };
};

export const disableWebAuthn = (code: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingWebAuthn(true));
      await authService.disableWebAuthn(code);
      dispatch(success());
      toast.success("Success");
      return true;
    } catch (err) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(error());
      toast.error(msg);
    }
  };
};

export const setup2FA = (email: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loading2FA(true));
      const res = await authService.setup2FA(email);
      const otpPathUrl = await QRCode.toDataURL(res.data.data.otpPathUrl);
      dispatch(success());
      return { otpPathUrl, secret: res.data.data.secret };
    } catch (err) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(error());
      toast.error(msg);
    }
  };
};

export const disable2FA = (pin: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loading2FA(true));
      await authService.disable2FA(pin);
      dispatch(success());
      toast.success("Success");
      return true;
    } catch (err) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(error());
      toast.error(msg);
    }
  };
};

import toast from "react-hot-toast";
import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/team-management.reducer";
import instance from "data/axios-setup";
import { DEFAULT_ERROR_MESSAGE, onResponseSuccess } from "data/error-mapping";
import { AxiosError } from "axios";
import { getCallSign } from "data/utils";

export const AddTeamMember = (
  data: any,
  onClose: any,
  // setStep: any,
  dis: any
) => {
  return async (dispatch: Dispatch) => {
    const callSign = getCallSign();
    try {
      dispatch(actions.gettingTeams());
      const res = await instance.post(`/${callSign}/team-management`, data);
      onResponseSuccess(res, () => {
        dispatch(actions.createTeammateSuccess());
        toast.success("Teammate invite has been sent successfully");
        onClose();
        // setStep(1);
        dis(getTeamMembers({ page: 1, perpage: 10 }));
      });
    } catch (err: any) {
      dispatch(actions.error());
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
    }
  };
};

export const AddBulkTeamMember = (
  data: any,
  onClose: any,
  setStep: any,
  dis: any
) => {
  return async (dispatch: Dispatch) => {
    const callSign = getCallSign();
    try {
      dispatch(actions.gettingTeams());
      const res = await instance.post(
        `/${callSign}/team-management/bulk`,
        data
      );
      onResponseSuccess(res, () => {
        dispatch(actions.createTeammateSuccess());
        toast.success("All teammates has been added successfully");
        onClose();
        setStep(1);
        dis(getTeamMembers({ page: 1, perpage: 10 }));
      });
    } catch (err: any) {
      dispatch(actions.error());
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
    }
  };
};

export const AssignFeature = (
  data: any,
  teamId: any,
  onClose: any,
  dis: any
) => {
  return async (dispatch: Dispatch) => {
    const callSign = getCallSign();
    try {
      dispatch(actions.assigningFeature());
      const res = await instance.put(
        `/${callSign}/team-management/${teamId}/sub-wallet`,
        data
      );
      onResponseSuccess(res, () => {
        dispatch(actions.featureAssignSUccess());
        toast.success("Wallet has been successfully assigned to teammate");
        onClose();
        dis(getTeamMembers({ page: 1, perpage: 10 }));
      });
    } catch (err: any) {
      dispatch(actions.error());
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
    }
  };
};

export const DeleteTeamMate = (
  id: any,
  onClose: any,
  dis: any,
  sOnClose: any
) => {
  const callSign = getCallSign();
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.deletingTeamMate());
      const res = await instance.delete(`/${callSign}/team-management/${id}`);
      onResponseSuccess(res, () => {
        dispatch(actions.deleteTeamMateSuccess());
        toast.success("Team Mate has been successfully deleted");
        onClose();
        sOnClose();
        dis(getTeamMembers({ page: 1, perpage: 10 }));
      });
    } catch (err: any) {
      dispatch(actions.error());
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
    }
  };
};

export const ReactivateTeamMate = (
  id: any,
  onClose: any,
  dis: any,
  sOnClose: any
) => {
  const callSign = getCallSign();
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.deletingTeamMate());
      const res = await instance.patch(
        `/${callSign}/team-management/reactivate/${id}`
      );
      onResponseSuccess(res, () => {
        dispatch(actions.deleteTeamMateSuccess());
        toast.success("Team Mate has been successfully activated");
        onClose();
        sOnClose();
        dis(getTeamMembers({ page: 1, perpage: 10 }));
      });
    } catch (err: any) {
      dispatch(actions.error());
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
    }
  };
};

export const getTeamMembers = (params: { page: number; perpage: number }) => {
  return async (dispatch: Dispatch) => {
    const callSign = getCallSign();
    try {
      dispatch(actions.gettingAllTeams());
      const res = await instance.get(`/${callSign}/team-management`, {
        params
      });
      onResponseSuccess(res, () => {
        dispatch(actions.settingTeams(res.data));
      });
    } catch (err: any) {
      dispatch(actions.error());
    }
  };
};

export const getSingleTeamMember = (id: string) => {
  const callSign = getCallSign();
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.gettingSingleTeam());
      const res = await instance.get(`/${callSign}/team-management/${id}`);
      onResponseSuccess(res, () => {
        dispatch(actions.settingSingleTeam(res.data.data));
      });
    } catch (err: any) {
      dispatch(actions.error());
    }
  };
};

export const acceptTeamInvite = (data: any, setinviteStatus: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.assigningFeature());
      const res = await instance.post(`/team-member/accept`, data);
      onResponseSuccess(res, () => {
        dispatch(actions.acceptedInvite());
        setinviteStatus(true);
      });
    } catch (err: any) {
      dispatch(actions.error());
      setinviteStatus(false);
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
    }
  };
};

export const assignNewRole = (
  teamId: any,
  onClose: any,
  dis: any,
  role: string
) => {
  return async (dispatch: Dispatch) => {
    const callSign = getCallSign();
    try {
      dispatch(actions.changingRole());
      const res = await instance.patch(
        `/${callSign}/team-management/${teamId}`,
        { role: role }
      );
      onResponseSuccess(res, () => {
        dispatch(actions.roleChanged());
        toast.success("Team Mate role has been updated successfully");
        onClose();
        dis(getTeamMembers({ page: 1, perpage: 10 }));
      });
    } catch (err: any) {
      dispatch(actions.error());
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
    }
  };
};

export const assignMonthlyLimit = (teamId: any, dis: any, amount: number) => {
  return async (dispatch: Dispatch) => {
    const callSign = getCallSign();
    try {
      dispatch(actions.changingRole());
      const res = await instance.put(`/${callSign}/team-management/${teamId}`, {
        amount: amount
      });
      onResponseSuccess(res, () => {
        dispatch(actions.roleChanged());
        toast.success(
          "Team Mate withdrawal limit has been updated successfully"
        );
        dis(getSingleTeamMember(teamId));
      });
    } catch (err: any) {
      dispatch(actions.error());
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
    }
  };
};

export const getTeamAudit = (
  id: string,
  params: { page: number; perpage: number }
) => {
  return async (dispatch: Dispatch) => {
    const callSign = getCallSign();
    try {
      dispatch(actions.gettingTeamAudit());
      const res = await instance.get(
        `/${callSign}/team-management-audit/${id}`,
        {
          params
        }
      );
      onResponseSuccess(res, () => {
        dispatch(actions.settingTeamAudit(res.data));
      });
    } catch (err: any) {
      dispatch(actions.error());
    }
  };
};

import { Flex, Text, useToast } from "@chakra-ui/react";

/**
 * `Custom hook` that returns a function that copies a text to the clipboard while also toasting a message to the screen
 * @returns `copyHandler`
 */
export const useCopyToClipboard = () => {
  const toast = useToast();

  /**
   * Function to copy text to the clipboard while also displaying a toast to the screen
   * @param data - string to be copied to the clipboard
   * @param copyTitle - Title to be displayed on the toaster
   */
  const copyHandler = (data: string, copyTitle: string) => {
    navigator.clipboard.writeText(data);
    toast({
      title: (
        <Flex>
          <Text mr="1" textTransform={"capitalize"}>
            {copyTitle}
          </Text>
          <Text>Copied</Text>
        </Flex>
      ),
      description: data,
      status: "info",
      variant: "subtle",
      position: "top-right",
      duration: 3000,
      isClosable: true
    });
  };

  return copyHandler;
};

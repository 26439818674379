import { AxiosResponse } from "axios";
import instance, { instanceV2 } from "data/axios-setup";
import { IBusiness, IBusinessOwners } from "app/store/models/business";
import { getCallSign } from "data/utils";
import { GenerateTransactionStatementBody } from "app/store/models/transaction";

class BusinessService {
  async getSingleBusiness(
    callSign: string
  ): Promise<AxiosResponse<{ data: IBusiness }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/business/${callSign}`);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getSingleStatement(
    id: number,
    callSign: string
  ): Promise<AxiosResponse<{ data: any }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/${callSign}/account-statement/${id}`);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getAllStatement(
    callSign: string
  ): Promise<AxiosResponse<{ data: { data: any[] } }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/${callSign}/account-statement`);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }

  async uploadStatement(
    url: string,
    name: string,
    callSign: string
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const body = { name, url };
        const res = await instance.post(`/${callSign}/account-statement`, body);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }

  async generateTransactionStatementV2(
    body: GenerateTransactionStatementBody
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      const callSign = getCallSign();
      try {
        const res = await instanceV2.post(
          `/${callSign}/account-statement`,
          body
        );
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }

  async uploadLogo(
    logo: string,
    callSign: string
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.put(
          `/business/${callSign}/upload-logo`,
          { logo }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getBusinessOwners(): Promise<
    AxiosResponse<{ data: { data: IBusinessOwners[] } }>
  > {
    return new Promise(async (resolve, reject) => {
      const callSign = getCallSign();
      try {
        const response = await instance.get(`/business/${callSign}/owner`);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new BusinessService();

import { Dispatch } from "@reduxjs/toolkit";
import instance from "data/axios-setup";
import { onResponseSuccess } from "data/error-mapping";
import { getCallSign } from "data/utils";
import toast from "react-hot-toast";
import { NavigateFunction } from "react-router-dom";
import {
  subBusinessError,
  gettingBusinessInfo,
  settingBusinessInfo,
  loadingBegin,
  createSubBusinessSuccess,
  error,
  loadingStop,
  finishBusinessRegistration,
  addedSubBusinessOwnerDetails,
  confimingCallSign,
  callSignConfirmed,
  settingAllBusiness,
  gettingAllBusinesses,
  clearingBusinessInfo
} from "../reducers/sub-buinesses.reducer";
import { GenerateTransactionStatementBody } from "../models/transaction";

export const getSubBusinessInfo = (subBusinessCallSign?: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(gettingBusinessInfo());
      const res = await instance.get(`/sub-business/${subBusinessCallSign}`);
      onResponseSuccess(res, () => {
        dispatch(settingBusinessInfo(res.data.data));
      });
    } catch (err: any) {
      dispatch(subBusinessError());
    }
  };
};
export const getAllSubBusiness = () => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      dispatch(gettingAllBusinesses());
      const res = await instance.get(`/${callSign}/sub-business`);
      onResponseSuccess(res, () => {
        dispatch(settingAllBusiness(res.data));
      });
    } catch (err: any) {
      dispatch(subBusinessError());
    }
  };
};

export const getAllSubBusinessKyc = (params: {
  status: string;
  page: number;
  perpage: number;
}) => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      dispatch(gettingAllBusinesses());
      const res = await instance.get(`/${callSign}/sub-business/kycs`, {
        params
      });
      onResponseSuccess(res, () => {
        dispatch(settingAllBusiness(res.data));
      });
    } catch (err: any) {
      dispatch(subBusinessError());
    }
  };
};

export const reloadSubBusinessInfo = (subBusinessCallSign: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await instance.get(`/sub-business/${subBusinessCallSign}`);
      onResponseSuccess(res, () => {
        dispatch(settingBusinessInfo(res.data.data));
      });
    } catch (err: any) {
      dispatch(subBusinessError());
    }
  };
};

export const createSubBusiness = (
  data: any,
  navigate: NavigateFunction,
  dis: any
) => {
  return async (dispatch: Dispatch) => {
    const {
      name,
      countryRegisteredIn,
      countryRegisteredInII,
      newIsRegisteredInAnotherCountry,
      legalName,
      businessRegistrationNumber,
      dateOfIncorporation,
      type,
      industry,
      description,
      callSign,
      email,
      phone,
      sourceOfFunds,
      otherSourceOfFunds
    } = data;
    const isRegisteredInAnotherCountry =
      newIsRegisteredInAnotherCountry === "true" ? true : false;
    const args = {
      name,
      countryRegisteredIn,
      countryRegisteredInII,
      isRegisteredInAnotherCountry,
      legalName,
      businessRegistrationNumber,
      dateOfIncorporation,
      type,
      industry,
      description,
      callSign: callSign.trim(),
      email,
      phone,
      sourceOfFunds:
        sourceOfFunds !== "other" ? sourceOfFunds : otherSourceOfFunds
    };
    try {
      const callSign = getCallSign();
      dispatch(loadingBegin());
      const res = await instance.post(`/${callSign}/sub-business`, args);
      onResponseSuccess(res, () => {
        dispatch(createSubBusinessSuccess());
        toast.success("Business has been created successfully");
        dis(reloadSubBusinessInfo(res.data.data?.callSign));
        navigate(`/sub-businesses/modify/${res.data.data?.callSign}`);
      });
    } catch (err: any) {
      dispatch(error());
      toast.error(err.response.data.message);
    }
  };
};

export const updateSubBusiness = (
  data: any,
  setActiveSetup: any,
  dis: any,
  subBusinessCallSign: string
) => {
  return async (dispatch: Dispatch) => {
    const {
      name,
      countryRegisteredIn,
      newIsRegisteredInAnotherCountry,
      legalName,
      businessRegistrationNumber,
      dateOfIncorporation,
      type,
      industry,
      description,
      callSign,
      email,
      phone,
      sourceOfFunds,
      otherSourceOfFunds
    } = data;
    const isRegisteredInAnotherCountry =
      newIsRegisteredInAnotherCountry === "true" ? true : false;
    const args = {
      name,
      countryRegisteredIn,
      isRegisteredInAnotherCountry,
      legalName,
      businessRegistrationNumber,
      dateOfIncorporation,
      type,
      industry,
      description,
      callSign,
      email,
      phone,
      sourceOfFunds:
        sourceOfFunds !== "other" ? sourceOfFunds : otherSourceOfFunds
    };
    try {
      dispatch(loadingBegin());
      const res = await instance.patch(
        `/${subBusinessCallSign}/sub-business`,
        args
      );
      onResponseSuccess(res, () => {
        dispatch(createSubBusinessSuccess());
        toast.success("Business has been updated successfully");
        setActiveSetup(2);
        dis(reloadSubBusinessInfo(subBusinessCallSign));
      });
    } catch (err: any) {
      dispatch(error());
      toast.error(err.response.data.message);
    }
  };
};

export const confirmSubBusinessCallSign = (data: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(confimingCallSign());
      const res = await instance.get(`/business/exists-callsign/${data}`);
      onResponseSuccess(res, () => {
        dispatch(callSignConfirmed(res.data.data));
      });
    } catch (err: any) {
      dispatch(error());
    }
  };
};

export const addSubBusinessContactDetails = (
  data: any,
  setActiveSetup: any,
  dis: any,
  subBusinessCallSign: string
) => {
  return async (dispatch: Dispatch) => {
    const {
      country,
      address,
      state,
      city,
      zipCode,
      website,
      phone,
      addressNumber
    } = data;
    const args = {
      country,
      address,
      addressNumber,
      state,
      city,
      zipCode,
      website,
      phone
    };
    try {
      dispatch(loadingBegin());
      const res = await instance.patch(
        `/${subBusinessCallSign}/sub-business`,
        args
      );
      onResponseSuccess(res, () => {
        dispatch(createSubBusinessSuccess());
        toast.success("Business contact details has been added successfully");
        setActiveSetup(3);
        dis(reloadSubBusinessInfo(subBusinessCallSign));
      });
    } catch (err: any) {
      dispatch(error());
      toast.error(err.response.data.message);
    }
  };
};

export const addSubBusinessOwnerDetails = (
  data: any,
  setDisplayForm: any,
  dis: any,
  subBusinessCallSign: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingBegin());
      const res = await instance.post(
        `${subBusinessCallSign}/sub-business/owners`,
        data
      );
      onResponseSuccess(res, () => {
        toast.success("Business owner details has been added successfully");
        dis(reloadSubBusinessInfo(subBusinessCallSign));
        setDisplayForm(false);
        dispatch(createSubBusinessSuccess());
        dispatch(addedSubBusinessOwnerDetails());
      });
    } catch (err: any) {
      dispatch(error());
      toast.error(err.response.data.message);
    }
  };
};

export const updateSubBusinessOwnerDetails = (
  data: any,
  setDisplayForm: any,
  businessOwnerId: any,
  dis: any,
  subBusinessCallSign: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingBegin());
      const res = await instance.patch(
        `/${subBusinessCallSign}/sub-business/owners/${businessOwnerId}`,
        data
      );
      onResponseSuccess(res, () => {
        toast.success("Business owner details has been updated successfully");
        dis(reloadSubBusinessInfo(subBusinessCallSign));
        setDisplayForm(false);
        dispatch(createSubBusinessSuccess());
      });
    } catch (err: any) {
      dispatch(error());
      toast.error(err.response.data.message);
    }
  };
};

export const addSubBusinessDocuments = (
  data: any,
  dis: any,
  subBusinessCallSign: string
) => {
  return async (dispatch: Dispatch) => {
    const {
      certificateOfIncorporation,
      memorandumAndArticlesOfAssociation,
      utilityBill,
      bankStatement,
      termsAndConditionsOfPartnership,
      partnershipConsentForm
    } = data;
    const args = {
      certificateOfIncorporation,
      memorandumAndArticlesOfAssociation,
      utilityBill,
      bankStatement,
      termsAndConditionsOfPartnership,
      partnershipConsentForm
    };
    try {
      dispatch(loadingBegin());
      const res = await instance.post(
        `/${subBusinessCallSign}/sub-business/upload-documents`,
        args
      );
      onResponseSuccess(res, () => {
        dispatch(finishBusinessRegistration());
        toast.success("Documents has been added successfully");
        dis(reloadSubBusinessInfo(subBusinessCallSign));
      });
    } catch (err: any) {
      dispatch(error());
      toast.error(err.response.data.message);
    }
  };
};

export const generateSubBusinessTransactionStatementV2 = (
  callSign: string,
  body: GenerateTransactionStatementBody,
  onOpen: () => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingBegin());
      const res = await instance.post(
        `/${callSign}/sub-business/account-statement`,
        body
      );

      onResponseSuccess(res, () => {
        dispatch(loadingStop());
        toast.success(res.data.message);
      });
      onOpen();
    } catch (err: any) {
      dispatch(error());
      toast.error(err?.response?.data?.message);
    }
  };
};

export const clearingSubBusinessInfo = () => {
  return async (dispatch: Dispatch) => {
    dispatch(clearingBusinessInfo());
  };
};

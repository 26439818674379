import {
  setup2FA,
  disable2FA,
  getUserProfile,
  disableWebAuthn,
  webAuthnAssertionEnd,
  webAuthnAssertionBegin,
  webAuthnAttestationEnd,
  webAuthnAttestationBegin
} from "app/store";
import { error } from "app/store/reducers/auth.reducer";
import { useAppDispatch, useAppSelector } from "./useReduxState";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";

export const useAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [isLoading2FA, isLoadingWebAuthn, businessInfo] = useAppSelector(
    (state) => [
      state.auth.isLoading2FA,
      state.auth.isLoadingWebAuthn,
      state.businessSetup.businessInfo
    ]
  );

  const dispatchWebAuthnFailed = () => {
    dispatch(error());
  };

  const dispatchGetUserProfile = async () => {
    await dispatch(getUserProfile(navigate, location));
  };

  const dispatchSetup2FA = async (email: string) => {
    return await dispatch(setup2FA(email));
  };

  const dispatchDisable2FA = async (pin: string) => {
    return await dispatch(disable2FA(pin));
  };

  const dispatchDisableWebAuthn = async (code: string) => {
    return await dispatch(disableWebAuthn(code));
  };

  const dispatchWebAuthnAttestationBegin = async () => {
    return await dispatch(webAuthnAttestationBegin());
  };

  const dispatchWebAuthnAssertionBegin = async (email: string) => {
    return await dispatch(webAuthnAssertionBegin(email));
  };

  const dispatchWebAuthnAttestationEnd = async (
    credential: PublicKeyCredential
  ) => {
    return await dispatch(webAuthnAttestationEnd(credential));
  };

  const dispatchWebAuthnAssertionEnd = async (
    email: string,
    navigate: NavigateFunction,
    credential: PublicKeyCredential
  ) => {
    return await dispatch(webAuthnAssertionEnd(email, navigate, credential));
  };

  return {
    businessInfo,
    isLoading2FA,
    dispatchSetup2FA,
    isLoadingWebAuthn,
    dispatchDisable2FA,
    dispatchGetUserProfile,
    dispatchWebAuthnFailed,
    dispatchDisableWebAuthn,
    dispatchWebAuthnAssertionEnd,
    dispatchWebAuthnAttestationEnd,
    dispatchWebAuthnAssertionBegin,
    dispatchWebAuthnAttestationBegin
  };
};

import { IPagination } from "data/models/base";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IBid, IBidHistory, IBidState } from "../models/bid.model";

const pagination = {
  next: 0,
  total: 0,
  prevPage: 0,
  lastPage: 0,
  pageSize: 10,
  hasNext: false,
  currentPage: 1,
  hasPrevious: false
};

const initialState: IBidState = {
  create: { error: "", loading: false },
  single: { data: null, error: "", loading: false },
  process: { error: "", loading: false, status: "" },
  all: { error: "", loading: false, data: [], pagination },
  history: { error: "", loading: false, data: [], pagination }
};

export const BidSlice = createSlice({
  initialState,
  name: "fx",
  reducers: {
    getAllBidsBegin: (state) => {
      state.all.error = "";
      state.all.loading = true;
    },
    getAllBidsSuccess: (
      state,
      action: PayloadAction<{ data: IBid[]; pagination: IPagination }>
    ) => {
      state.all.error;
      state.all.loading = false;
      state.all.data = action.payload.data;
      state.all.pagination = action.payload.pagination;
    },
    getAllBidsFailed: (state, action: PayloadAction<string>) => {
      state.all.loading = false;
      state.all.error = action.payload;
    },

    getBidHistoryBegin: (state) => {
      state.history.error = "";
      state.history.loading = true;
    },
    getBidHistorySuccess: (
      state,
      action: PayloadAction<{ data: IBidHistory[]; pagination: IPagination }>
    ) => {
      state.history.error;
      state.history.loading = false;
      state.history.data = action.payload.data;
      state.history.pagination = action.payload.pagination;
    },
    getBidHistoryFailed: (state, action: PayloadAction<string>) => {
      state.history.loading = false;
      state.history.error = action.payload;
    },

    processBidBegin: (state, action: PayloadAction<string>) => {
      state.process.error = "";
      state.process.loading = true;
      state.process.status = action.payload;
    },
    processBidSuccess: (state) => {
      state.process.error;
      state.process.loading = false;
    },
    processBidFailed: (state, action: PayloadAction<string>) => {
      state.process.loading = false;
      state.process.error = action.payload;
    },

    createBidBegin: (state) => {
      state.create.error = "";
      state.create.loading = true;
    },
    createBidSuccess: (state) => {
      state.create.error = "";
      state.create.loading = false;
    },
    createBidFailed: (state, action: PayloadAction<string | undefined>) => {
      state.create.loading = false;
      state.create.error = action.payload || "";
    }
  }
});

export const actions = BidSlice.actions;
export default BidSlice.reducer;

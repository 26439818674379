import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { Dispatch } from "@reduxjs/toolkit";
import uploadFile from "data/utils/fileUpload";
import { actions } from "../reducers/business.reducer";
import { TBusinessLoading } from "../models/business";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import businessService from "data/services/business.service";
import { updateBusinessLogo } from "../reducers/business-setup.reducers";
import { GenerateTransactionStatementBody } from "../models/transaction";

export const uploadTransactionStatement = (
  file: File,
  name: string,
  callSign: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("uploadTransactionStatement"));
      const response = await uploadFile(file, name);

      const regex = /.com\/\//gi;
      let url = response.location;
      url = url.replaceAll(regex, ".com/");

      await businessService.uploadStatement(url, name, callSign);
      dispatch(actions.success({ for: "uploadTransactionStatement" }));
    } catch (err: any) {
      const msg = err?.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error(msg));
    }
  };
};

export const generateTransactionStatementV2 = (
  body: GenerateTransactionStatementBody,
  onOpen: () => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("generateTransactionStatement"));
      const res = await businessService.generateTransactionStatementV2(body);
      dispatch(actions.success({ for: "generateTransactionStatement" }));
      onOpen();
      toast.success(res.data.message);
    } catch (err: any) {
      const msg = err?.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.idle("generateTransactionStatement"));
      dispatch(actions.error({ for: "generateTransactionStatement", msg }));
    }
  };
};

export const getSingleBusiness = (callSign: string, throwError = true) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getSingleBusiness"));
      const res = await businessService.getSingleBusiness(callSign);
      dispatch(actions.setSingleBusiness(res.data.data));
    } catch (err: any) {
      const msg = err?.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (throwError) toast.error(msg);
      dispatch(actions.error({ for: "getSingleBusiness", msg }));
    }
  };
};

export const getSingleStatement = (id: number, callSign: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getSingleTransactionStatement"));
      const res = await businessService.getSingleStatement(id, callSign);
      dispatch(actions.setSingleBusiness(res.data.data));
    } catch (err: any) {
      const msg = err?.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ for: "getSingleTransactionStatement", msg }));
    }
  };
};

export const getAllStatements = (callSign: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getTransactionStatement"));
      const res = await businessService.getAllStatement(callSign);
      dispatch(actions.setTransactionStatement(res.data.data.data));
    } catch (err: any) {
      const msg = err?.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ for: "getTransactionStatement", msg }));
    }
  };
};

export const uploadLogo = (file: File, filename: string, callSign: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("uploadLogo"));
      const response = await uploadFile(file, filename);

      const regex = /.com\/\//gi;
      let logo = response.location;
      logo = logo.replaceAll(regex, ".com/");

      const data = { logo };
      const res = await businessService.uploadLogo(logo, callSign);
      dispatch(actions.updateSingleBusiness({ for: "uploadLogo", data }));
      dispatch(updateBusinessLogo(logo));
      toast.success(res.data.message);
    } catch (err: any) {
      const msg = err?.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ for: "uploadLogo", msg }));
    }
  };
};

export const setBusinessIdleStatus = (value: TBusinessLoading) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.idle(value));
  };
};

export const getBusinessOwners = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getBusinessOwners"));
      const res = await businessService.getBusinessOwners();
      dispatch(actions.setBusinessOwners(res.data.data.data));
    } catch (error: any) {
      const err = error as AxiosError<{ message: string }>;
      const msg = err?.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "getBusinessOwners" }));
    }
  };
};

import { useToast } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  deactivateCard,
  fundCard,
  getAllVirtualCards,
  getBusinessInfo,
  getCardCreationFees,
  getCardFees,
  getCardFundingFees,
  getDashboardRecentActivities,
  getSingleVirtualCard,
  getTransactions,
  liquidateCard,
  openCardDetailsDrawer,
  openFundCardDrawer,
  openLiquidateDrawer,
  openRequestCardDrawer,
  openWithdrawDrawer,
  reactivateCard,
  resetCardDrawerState,
  resetCardModalState,
  setAcceptCardTerms,
  setCardBrand,
  setCardModalState,
  withdrawFund
} from "app/store";
import { actions } from "app/store/reducers/card.reducer";
import { DEFAULT_ERROR_MESSAGE, onResponseSuccess } from "data/error-mapping";
import {
  ICardFeesParam,
  IEditViirtualCardStatus,
  IFundVirtualCard,
  IGetVirtualCardsParam
} from "data/models/virtual-cards";
import { CreateVirtualCard as ICreateVirtualCardReqBody } from "data/models/virtual-cards";
import virtualCardsService from "data/services/virtual-cards.service";
import React, { useState } from "react";
import { AxiosError } from "axios";
import { useCrypto } from "app/hooks";
import { useSearchParams } from "react-router-dom";
import { localISODateString } from "data/utils";
import { ITransaction, VirtualCardType } from "app/store/models/transaction";
import transactionService from "data/services/transaction.service";
import { VirtualCardBrand } from "app/store/models/card";

/**
 *
 * @returns functions state functions and variables generally relating to viewing and fetching virtual cards
 */
export const useVirtualCards = () => {
  // State reducers and states
  const [
    allVirtualCards,
    singleVirtualCard,
    cardIdFromDrawer,
    modalAction,
    cardBrand
  ] = useAppSelector((state) => [
    state.card.allVirtualCards,
    state.card.singleVirtualCard,
    state.card.drawer.id,
    state.card.modal.action,
    state.card.drawer.cardBrand
  ]);
  const dispatch = useAppDispatch();

  // Modal and Drawer functions
  const openRequestCard = (brand: VirtualCardBrand) => {
    dispatch(setCardBrand(brand));
    dispatch(openRequestCardDrawer());
  };

  const onOpenCardDetails = (id: number) => {
    dispatch(openCardDetailsDrawer(id));
  };

  const resetDrawer = () => {
    dispatch(resetCardDrawerState());
  };

  const resetModal = () => {
    dispatch(resetCardModalState());
  };

  const handleOpenFundCardDrawer = () => {
    dispatch(openFundCardDrawer());
  };

  const handleOpenWithdrawDrawer = () => {
    dispatch(openWithdrawDrawer());
  };

  const handleOpenLiquidateDrawer = () => {
    dispatch(openLiquidateDrawer());
  };

  const handleSelectCardBrand = () => {
    dispatch(
      setCardModalState({
        isOpen: true,
        view: "select-card-brand"
      })
    );
  };

  // dispatch functions
  const dispatchAllVirtualCards = (params: Partial<IGetVirtualCardsParam>) => {
    dispatch(getAllVirtualCards(params));
  };

  const dispatchSingleVirtualCard = (id: number) => {
    dispatch(getSingleVirtualCard(id));
  };

  // normal api functions
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();
  const { dispatchTransactions } = useCardTransactions();

  const editCardStatusHandler = async (
    data: IEditViirtualCardStatus,
    icon: React.ReactNode
  ) => {
    if (!cardIdFromDrawer) return;
    setLoading(true);
    try {
      const res = await virtualCardsService.editCardStatus(
        cardIdFromDrawer,
        data
      );
      onResponseSuccess(res, () => {
        toast({
          description: res.data.message,
          position: "top",
          status: "success",
          variant: "subtle",
          icon
        });
        if (data.status === "inactive") {
          dispatch(deactivateCard());
        } else {
          dispatch(reactivateCard());
        }
      });

      dispatchSingleVirtualCard(cardIdFromDrawer);
      dispatchTransactions();

      setLoading(false);
    } catch (err) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;

      toast({
        description: msg,
        position: "top",
        status: "error",
        variant: "subtle",
        icon
      });
      setLoading(false);
    }
  };

  const [fundCardAmount, fundCardCurrency] = useAppSelector((state) => [
    state.card.modal.state as { amount: string },
    state.card.createCard.currency
  ]);
  const { isCrypto } = useCrypto();

  const fundCardHandler = async (pin: string, icon: React.ReactNode) => {
    if (!cardIdFromDrawer || !fundCardAmount) return;
    const data: IFundVirtualCard = {
      amount: +fundCardAmount.amount,
      currency: fundCardCurrency,
      pin,
      walletType: isCrypto(fundCardCurrency) ? "crypto" : "fiat"
    };
    setLoading(true);

    try {
      const res = await virtualCardsService.fundVirtualCard(
        cardIdFromDrawer,
        data
      );
      onResponseSuccess(res, () => {
        toast({
          description: res.data.message,
          position: "top",
          status: "success",
          variant: "subtle",
          icon
        });

        dispatch(fundCard());
      });

      dispatchSingleVirtualCard(cardIdFromDrawer);
      dispatchTransactions();

      setLoading(false);
    } catch (err) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;

      toast({
        description: msg,
        position: "top",
        status: "error",
        variant: "subtle",
        icon
      });
      setLoading(false);
    }
  };

  const withdrawFromCardHandler = async (
    pin: string,
    icon: React.ReactNode
  ) => {
    if (!cardIdFromDrawer || !fundCardAmount) return;
    const data: Omit<IFundVirtualCard, "walletType"> = {
      amount: +fundCardAmount.amount,
      currency: fundCardCurrency,
      pin
    };
    setLoading(true);

    try {
      const res =
        cardBrand === "MasterCard"
          ? await virtualCardsService.withdrawFromVirtualCard(
              cardIdFromDrawer,
              data
            )
          : await virtualCardsService.withdrawFromVirtualCardV2(
              cardIdFromDrawer,
              data
            );
      onResponseSuccess(res, () => {
        toast({
          description: res.data.message,
          position: "top",
          status: "success",
          variant: "subtle",
          icon
        });

        dispatch(withdrawFund());
      });

      dispatchSingleVirtualCard(cardIdFromDrawer);
      dispatchTransactions();

      setLoading(false);
    } catch (err) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;

      toast({
        description: msg,
        position: "top",
        status: "error",
        variant: "subtle",
        icon
      });
      setLoading(false);
    }
  };

  const liquidateCardHandler = async (pin: string, icon: React.ReactNode) => {
    if (!cardIdFromDrawer) return;
    const data: Pick<IFundVirtualCard, "currency" | "pin"> = {
      currency: fundCardCurrency,
      pin
    };
    setLoading(true);

    try {
      const res = await virtualCardsService.liquidateCard(
        cardIdFromDrawer,
        data
      );
      onResponseSuccess(res, () => {
        toast({
          description: res.data.message,
          position: "top",
          status: "success",
          variant: "subtle",
          icon
        });

        dispatch(liquidateCard());
      });

      dispatchTransactions();
      resetDrawer();

      setLoading(false);
    } catch (err) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;

      toast({
        description: msg,
        position: "top",
        status: "error",
        variant: "subtle",
        icon
      });
      setLoading(false);
    }
  };

  return {
    openRequestCard,
    resetDrawer,
    handleOpenFundCardDrawer,
    handleOpenWithdrawDrawer,
    handleOpenLiquidateDrawer,
    resetModal,
    onOpenCardDetails,
    allVirtualCards,
    singleVirtualCard,
    dispatchAllVirtualCards,
    dispatchSingleVirtualCard,
    cardIdFromDrawer,
    modalAction,
    isLoading,
    editCardStatusHandler,
    fundCardHandler,
    withdrawFromCardHandler,
    liquidateCardHandler,
    handleSelectCardBrand,
    cardBrand,
    setCardBrand
  };
};

/**
 *
 * @returns functions state functions and variables generally relating to creating virtual cards
 */
export const useCreateVirtualCard = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { dispatchAllVirtualCards, allVirtualCards, resetDrawer, cardBrand } =
    useVirtualCards();

  const currency = useAppSelector((state) => state.card.createCard.currency);

  const setCurrency = (currency: string) => {
    dispatch(actions.setCreateCardCurrency(currency));
  };

  /**
   * Function to trigger the success modal after a virtual card has been created successfully
   */
  const onCreateVirtualCardSuccess = () => {
    dispatch(
      setCardModalState({
        isOpen: true,
        action: "reactivate",
        view: "createSuccess"
      })
    );
  };

  //  Create card handler
  const reqVirtualCardState = useAppSelector(
    (state) => state.card.modal.state as ICreateVirtualCardReqBody
  );

  const [isLoading, setLoading] = useState(false);

  const requestVirtualCardHandler = async (
    pin: string,
    icon: React.ReactNode
  ) => {
    setLoading(true);
    try {
      const res = await virtualCardsService.createVirtualCardV2({
        ...reqVirtualCardState,
        type: cardBrand,
        pin
      });

      onResponseSuccess(res, () => {
        toast({
          description: res.data.message,
          position: "top",
          status: "success",
          variant: "subtle",
          icon
        });
        onCreateVirtualCardSuccess();
        dispatchAllVirtualCards({
          page: allVirtualCards.pagination.currentPage,
          perpage: allVirtualCards.pagination.pageSize
        });
        dispatch(getBusinessInfo());
        resetDrawer();
        dispatch(setAcceptCardTerms(false));
        dispatch(getDashboardRecentActivities());
      });

      setLoading(false);
    } catch (err) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;

      toast({
        description: msg,
        position: "top",
        status: "error",
        variant: "subtle",
        icon
      });
      setLoading(false);
    }
  };

  return {
    setCurrency,
    currency,
    onCreateVirtualCardSuccess,
    requestVirtualCardHandler,
    isLoading
  };
};

export const useCardTransactions = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const cardTransactionType = searchParams.get("transaction-type");

  const [filterState, transactions] = useAppSelector((state) => [
    state?.transaction?.filter,
    state?.transaction?.transactions
  ]);

  const filter = filterState;

  const dispatchTransactions = () => {
    dispatch(
      getTransactions({
        perpage: transactions.pagination.pageSize,
        fiatWallet: filter?.fiatWallet,
        cryptoWallet: filter?.cryptoWallet,
        page: transactions.pagination.currentPage,
        type: filter?.type === "both" ? undefined : filter?.type,
        status: filter?.status === "all" ? undefined : filter?.status,
        to: filter?.date ? localISODateString(filter?.date) : undefined,
        from: filter?.from ? localISODateString(filter?.from) : undefined,
        changeraTransactionType: cardTransactionType
      })
    );
  };

  return { dispatchTransactions };
};

export const useCardCreationFees = () => {
  const cardFeesData = useAppSelector((state) => state.card.cardCreationFees);

  const dispatch = useAppDispatch();

  const { isCrypto } = useCrypto();

  const dispatchCardFees = (params: ICardFeesParam) => {
    if (isCrypto(params.currency)) {
      return dispatch(getCardCreationFees({ ...params, currency: "usd" }));
    }
    dispatch(getCardCreationFees(params));
  };

  const resetCardFees = () => {
    dispatch(actions.getCardCreationFeesReset());
  };

  return { cardFeesData, dispatchCardFees, resetCardFees };
};

export const useCardFundingFees = () => {
  const cardFeesData = useAppSelector((state) => state.card.cardFundingFees);

  const dispatch = useAppDispatch();

  const { isCrypto } = useCrypto();

  const dispatchCardFees = (params: ICardFeesParam) => {
    if (isCrypto(params.currency)) {
      return dispatch(getCardFundingFees({ ...params, currency: "usd" }));
    }
    dispatch(getCardFundingFees(params));
  };

  const resetCardFees = () => {
    dispatch(actions.getCardFundingFeesReset());
  };

  return { cardFeesData, dispatchCardFees, resetCardFees };
};

export const useVirtualCardLimits = () => {
  const { allVirtualCards } = useVirtualCards();
  const [isLoading, setIsLoading] = useState(false);

  const getTotalAmount = (tx: ITransaction[]) => {
    let totalAmount = 0;
    tx.forEach((data) => {
      totalAmount += Number(data.amount || 0);
    });
    return totalAmount;
  };

  const dispatchCardFundingTransactions = async () => {
    const res = await transactionService.getTransactions({
      perpage: 1_000,
      subType: VirtualCardType.VIRTUAL_CARD_FUNDING
    });

    return res.data.data;
  };

  const getVirtualCardLimits = (plan: string) => {
    switch (plan) {
      case "silver":
        return { fund: 5_000, create: 2 };
      case "gold":
        return { create: 50, fund: 50_000 };
      case "platinum":
        return { create: Infinity, fund: 150_000 };
      default:
        return { create: -Infinity, fund: 0 };
    }
  };

  const checkVirtualCardLimits = (plan: string) => {
    const limits = getVirtualCardLimits(plan);

    return {
      create: (allVirtualCards.pagination.total || 0) < limits.create
    };
  };

  const handleCreateVirtualCardLimit = (plan: string) => {
    const { create } = checkVirtualCardLimits(plan);
    return create;
  };

  const handleFundVirtualCardLimit = async (plan: string, amount: number) => {
    setIsLoading(true);
    const data = await dispatchCardFundingTransactions();
    const totalAmount = getTotalAmount(data);
    const { fund } = getVirtualCardLimits(plan);
    setIsLoading(false);

    if (totalAmount + amount < fund) return true;
    return false;
  };

  return {
    isLoading,
    handleFundVirtualCardLimit,
    handleCreateVirtualCardLimit
  };
};

export const useGetFees = () => {
  const [loading, funding, creation] = useAppSelector((state) => [
    state.card.fees.loading,
    state.card.fees.funding,
    state.card.fees.creation
  ]);

  const dispatch = useAppDispatch();

  const dispatchCardFees = () => {
    dispatch(getCardFees());
  };

  return { loading, creation, funding, dispatchCardFees };
};

import { Dispatch } from "@reduxjs/toolkit";
import instance from "data/axios-setup";
import { onResponseSuccess } from "data/error-mapping";
import dashboardService from "data/services/dashboard.service";
import { getCallSign } from "data/utils";
import { NavigateFunction } from "react-router-dom";
import { actions } from "../reducers/dashboard.reducer";

export const getUserProfile = (navigate: NavigateFunction, location: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.gettingUserProfile());
      const res = await instance.get("/auth/user");
      onResponseSuccess(res, () => {
        dispatch(actions.settingUser(res.data.data));
      });
    } catch (err: any) {
      dispatch(actions.dashboardError());
      navigate("/auth/login", { replace: true, state: { from: location } });
    }
  };
};

export const getDashboardRecentActivities = () => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      dispatch(actions.fetchRecentActivities());
      const res = await dashboardService.getRecentActivities(callSign);
      onResponseSuccess(res, () => {
        dispatch(actions.setRecentActivities(res.data.data));
      });
    } catch (error: any) {
      dispatch(actions.dashboardError());
    }
  };
};

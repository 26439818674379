import { ChangeraTransactionType } from "app/store/models/transaction";
import {
  MoneySend,
  Global,
  WalletAdd,
  ReceiveSquare2,
  ConvertCard,
  Calendar,
  Cards,
  Convertshape,
  Repeat,
  RefreshCircle
} from "iconsax-react";
import { useAppDispatch, useAppSelector } from "./useReduxState";
import {
  getAirtimeDataTransactions,
  getAllWithdrawals,
  getRecentTransactions,
  getSubBusinessTransactions,
  getSubBusinessWithdrawals,
  getTransactions,
  getTransactionSummary,
  setSingleWalletTransactionFilter,
  setTransactionFilter
} from "app/store";
import { localISODateString } from "data/utils";
import { getConvertFundsTransactions } from "app/store/action-creators/convert-funds";
import { filterInitialState } from "app/store/reducers/transaction.reducer";

/**
 * Custom Hook to get the icon and color for a transaction.. Created to cater for the UI of the recent transactions on the dashboard
 * @returns `getTransactionIcon`
 */
export const useTransactionIcon = () => {
  /**
   *
   * @param transaction - as `ChangeraTransactionType`
   * @returns - an icon and a color
   */
  const getTransactionIcon = (
    transaction: ChangeraTransactionType,
    type?: string,
    subType?: any,
    desc?: string
  ): { icon: any; color: string } => {
    if (desc === "FX transaction fee" || desc === "Total FX transaction fee") {
      return { icon: Convertshape, color: "red" };
    }

    if (subType === ChangeraTransactionType.FX) {
      return { icon: Convertshape, color: type === "credit" ? "green" : "red" };
    }

    switch (transaction) {
      case ChangeraTransactionType.SEND_MONEY:
        return {
          icon: MoneySend,
          color: "orange"
        };
      case ChangeraTransactionType.PAY_MONEY:
        return {
          icon: MoneySend,
          color: "orange"
        };
      case ChangeraTransactionType.AIRTIME_VTU:
        return {
          icon: Global,
          color: "indigo"
        };
      case ChangeraTransactionType.FUNDING_WALLET:
        return {
          icon: WalletAdd,
          color: "blue"
        };
      case ChangeraTransactionType.WITHDRAWAL:
        return {
          icon: ReceiveSquare2,
          color: "pink"
        };
      case ChangeraTransactionType.FIAT_CONVERSION:
        return {
          icon: ConvertCard,
          color: "orange"
        };
      case ChangeraTransactionType.CRYPTO_CONVERSION:
        return {
          icon: ConvertCard,
          color: "orange"
        };
      case ChangeraTransactionType.EVENT_TICKET:
        return {
          icon: Calendar,
          color: "green"
        };
      case ChangeraTransactionType.VIRTUAL_CARD:
        return {
          icon: Cards,
          color: "purple"
        };
      case ChangeraTransactionType.SUBSCRIPTION:
        return {
          icon: Repeat,
          color: "green"
        };

      default:
        return {
          icon: RefreshCircle,
          color: "primary"
        };
    }
  };

  return { getTransactionIcon };
};

/**
 * returns a function to fetch all transactions from the store
 * @returns `fetchAllTransactions`
 */
export const useGetAllTransactions = () => {
  const dispatch = useAppDispatch();

  const fetchAllTransactions = () => {
    dispatch(getTransactions({ page: 1, perpage: 10 }));
  };

  return { fetchAllTransactions };
};

/**
 * returns a function to fetch recent transactions from the store
 * @returns `fetchRecentTransactions`
 */
export const useGetRecentTransactions = () => {
  const dispatch = useAppDispatch();

  const fetchRecentTransactions = () => {
    dispatch(getRecentTransactions({ page: 1, perpage: 5 }));
  };

  return { fetchRecentTransactions };
};

/**
 * returns a function to fetch transactions' summary from the store
 * @returns `fetchTransactionSummary`
 */
export const useGetTransactionSummary = () => {
  const dispatch = useAppDispatch();

  const fetchTransactionSummary = (currency: string) => {
    dispatch(getTransactionSummary(currency));
  };

  return { fetchTransactionSummary };
};

type IFilter = {
  page?: number;
  perpage?: number;
  status?: string;
  date?: any;
  from?: any;
};

export const useDispatchAllTransactions = (filter: IFilter) => {
  const dispatch = useAppDispatch();
  const [
    callSign,
    getAllTransactionStatus,
    getWithdrawalStatus,
    getFundingStatus,
    getPaySomeoneStatus,
    isLoadingConversions,
    getAirtimeDateStatus,
    getCardTransactionStatus,
    getEventTicketStatus,
    getFXTransactionsStatus
  ] = useAppSelector((state) => [
    state?.businessSetup?.businessInfo?.callSign,
    state?.transaction?.status?.getTransactions,
    state?.withdrawal?.state?.getAllWithdrawals,
    state?.transaction?.status?.getTransactions,
    state?.withdrawal?.state?.getAllWithdrawals,
    state?.convertFunds?.gettingConversions,
    state?.transaction?.status?.getAirtimeDataTransactions,
    state?.transaction?.status?.getTransactions,
    state?.transaction?.status?.getTransactions,
    state?.transaction?.status?.getTransactions
  ]);

  const dispatchResetTransactionFilter = () => {
    dispatch(setTransactionFilter(filterInitialState));
    dispatch(setSingleWalletTransactionFilter(filterInitialState));
  };

  const dispatchAllTransactions = () => {
    dispatch(getTransactions({ page: filter?.page, perpage: filter?.perpage }));
  };

  const params = {
    page: filter?.page,
    perpage: filter?.perpage,
    status: filter?.status === "all" ? undefined : filter?.status,
    to: filter?.date ? localISODateString(filter.date) : undefined,
    from: filter?.from ? localISODateString(filter.from) : undefined
  };

  const dispatchFXTransactions = () => {
    dispatch(
      getTransactions({
        ...params,
        page: filter?.page,
        perpage: filter?.perpage,
        changeraTransactionType: ChangeraTransactionType.FX
      })
    );
  };

  const dispatchFunding = () => {
    dispatch(
      getTransactions({
        ...params,
        changeraTransactionType: ChangeraTransactionType.FUNDING_WALLET
      })
    );
  };

  const dispatchWithdrawals = () => {
    dispatch(
      getAllWithdrawals({ ...params, type: ChangeraTransactionType.WITHDRAWAL })
    );
  };

  const dispatchPaySomeone = () => {
    dispatch(
      getAllWithdrawals({ ...params, type: ChangeraTransactionType.PAY_MONEY })
    );
  };

  const dispatchConverrsions = () => {
    dispatch(getConvertFundsTransactions(callSign, params));
  };

  const dispatchAirtimeData = () => {
    dispatch(getAirtimeDataTransactions(callSign, params));
  };

  const dispatchCardTransactions = () => {
    dispatch(
      getTransactions({
        ...params,
        changeraTransactionType: ChangeraTransactionType.VIRTUAL_CARD
      })
    );
  };

  const dispatchEventTickets = () => {
    dispatch(
      getTransactions({
        ...params,
        changeraTransactionType: ChangeraTransactionType.EVENT_TICKET
      })
    );
  };

  const actions = {
    dispatchAllTransactions,
    dispatchWithdrawals,
    dispatchFunding,
    dispatchPaySomeone,
    dispatchConverrsions,
    dispatchAirtimeData,
    dispatchCardTransactions,
    dispatchEventTickets,
    dispatchFXTransactions,
    dispatchResetTransactionFilter
  };

  const status = {
    getAllTransactionStatus,
    getWithdrawalStatus,
    getFundingStatus,
    getPaySomeoneStatus,
    isLoadingConversions,
    getAirtimeDateStatus,
    getCardTransactionStatus,
    getEventTicketStatus,
    getFXTransactionsStatus
  };

  return { actions, status };
};

export const useDispatchSubBusinessTransactions = (filter: IFilter) => {
  const dispatch = useAppDispatch();
  const [
    callSign,
    getAllTransactionStatus,
    getWithdrawalStatus,
    getFundingStatus,
    getPaySomeoneStatus,
    isLoadingConversions,
    getAirtimeDateStatus,
    getCardTransactionStatus,
    getEventTicketStatus,
    getFXTransactionsStatus
  ] = useAppSelector((state) => [
    state?.subBusinesses?.subBusinessInfo.callSign,
    state?.transaction?.status?.getTransactions,
    state?.withdrawal?.state?.getAllWithdrawals,
    state?.transaction?.status?.getTransactions,
    state?.withdrawal?.state?.getAllWithdrawals,
    state?.convertFunds?.gettingConversions,
    state?.transaction?.status?.getAirtimeDataTransactions,
    state?.transaction?.status?.getTransactions,
    state?.transaction?.status?.getTransactions,
    state?.transaction?.status?.getTransactions
  ]);

  const dispatchResetTransactionFilter = () => {
    dispatch(setTransactionFilter(filterInitialState));
    dispatch(setSingleWalletTransactionFilter(filterInitialState));
  };

  const dispatchAllTransactions = () => {
    dispatch(
      getSubBusinessTransactions(callSign, {
        page: filter?.page,
        perpage: filter?.perpage
      })
    );
  };

  const params = {
    page: filter?.page,
    perpage: filter?.perpage,
    status: filter?.status === "all" ? undefined : filter?.status,
    to: filter?.date ? localISODateString(filter.date) : undefined,
    from: filter?.from ? localISODateString(filter.from) : undefined
  };

  const dispatchFXTransactions = () => {
    dispatch(
      getSubBusinessTransactions(callSign, {
        ...params,
        page: filter?.page,
        perpage: filter?.perpage,
        subType: ChangeraTransactionType.FX
      })
    );
  };

  const dispatchFunding = () => {
    dispatch(
      getSubBusinessTransactions(callSign, {
        ...params,
        changeraTransactionType: ChangeraTransactionType.FUNDING_WALLET
      })
    );
  };

  const dispatchWithdrawals = () => {
    dispatch(
      getSubBusinessWithdrawals(callSign, {
        ...params,
        type: ChangeraTransactionType.WITHDRAWAL
      })
    );
  };

  const dispatchPaySomeone = () => {
    dispatch(
      getSubBusinessWithdrawals(callSign, {
        ...params,
        type: ChangeraTransactionType.PAY_MONEY
      })
    );
  };

  const dispatchConverrsions = () => {
    dispatch(getConvertFundsTransactions(callSign, params));
  };

  const dispatchAirtimeData = () => {
    dispatch(getAirtimeDataTransactions(callSign, params));
  };

  const dispatchCardTransactions = () => {
    dispatch(
      getSubBusinessTransactions(callSign, {
        ...params,
        changeraTransactionType: ChangeraTransactionType.VIRTUAL_CARD
      })
    );
  };

  const dispatchEventTickets = () => {
    dispatch(
      getSubBusinessTransactions(callSign, {
        ...params,
        changeraTransactionType: ChangeraTransactionType.EVENT_TICKET
      })
    );
  };

  const actions = {
    dispatchAllTransactions,
    dispatchWithdrawals,
    dispatchFunding,
    dispatchPaySomeone,
    dispatchConverrsions,
    dispatchAirtimeData,
    dispatchCardTransactions,
    dispatchEventTickets,
    dispatchFXTransactions,
    dispatchResetTransactionFilter
  };

  const status = {
    getAllTransactionStatus,
    getWithdrawalStatus,
    getFundingStatus,
    getPaySomeoneStatus,
    isLoadingConversions,
    getAirtimeDateStatus,
    getCardTransactionStatus,
    getEventTicketStatus,
    getFXTransactionsStatus
  };

  return { actions, status };
};

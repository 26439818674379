import { Dispatch } from "@reduxjs/toolkit";
import instance from "data/axios-setup";
import { onResponseSuccess } from "data/error-mapping";
import { getCallSign } from "data/utils";
// import toast from "react-hot-toast";
import { actions } from "../reducers/convert-funds.reducer";
import toast from "react-hot-toast";
import {
  getAllCryptoWallets,
  getAllFiatWallets,
  getAllSubBusinessFiatWallets
  // getSingleCryptoWallet,
  // getSingleFiatWallet
} from "./wallet.action";
import { getRecentTransactions } from "./transaction.action";

export const fiatToFiatConversion = (
  values: any,
  dispatchAction: any,
  setOpened: any,
  pinOnclose: any
) => {
  return async (dispatch: Dispatch) => {
    const { amount, sourceCurrency, destinationCurrency, transactionPin } =
      values;
    const intAmount = parseFloat(amount);
    const callSign = getCallSign();
    const data = {
      amount: intAmount,
      sourceCurrency,
      destinationCurrency,
      transactionPin
    };
    try {
      dispatch(actions.converting());
      toast.loading(`Converting Funds`);
      const res = await instance.post(`${callSign}/conversion/fiat`, data);
      onResponseSuccess(res, () => {
        toast.dismiss();
        dispatch(actions.convertSuccess());
        toast.success("Funds has been converted successfully");
        setOpened(true);
        pinOnclose();
        dispatchAction(getAllFiatWallets());
        dispatchAction(getRecentTransactions({ page: 1, perpage: 5 }));

        // dispatchAction(getSingleFiatWallet(sourceCurrency));
        // dispatchAction(getSingleFiatWallet(destinationCurrency));
      });
    } catch (err: any) {
      toast.dismiss();
      dispatch(actions.error());
      toast.error(
        err?.response?.data?.message ??
          "There was an error performing your request, Please try again later."
      );
    }
  };
};

export const cryptoToFiatConversion = (
  values: any,
  dispatchAction: any,
  setOpened: any,
  pinOnclose: any
) => {
  return async (dispatch: Dispatch) => {
    const { amount, currency, cryptoWalletId, transactionPin } = values;
    const intAmount = parseFloat(amount);
    const intId = parseInt(cryptoWalletId);
    const callSign = getCallSign();
    const data = {
      amount: intAmount,
      currency,
      cryptoWalletId: intId,
      transactionPin
    };
    try {
      dispatch(actions.converting());
      toast.loading(`Converting Funds`);
      const res = await instance.post(`${callSign}/conversion/crypto`, data);
      onResponseSuccess(res, () => {
        toast.dismiss();
        dispatch(actions.convertSuccess());
        toast.success("Funds has been converted successfully");
        setOpened(true);
        pinOnclose();
        dispatchAction(getAllCryptoWallets());
        dispatchAction(getAllFiatWallets());
        dispatchAction(getRecentTransactions({ page: 1, perpage: 5 }));
        // dispatchAction(getSingleCryptoWallet(cryptoWalletId));
        // dispatchAction(getSingleFiatWallet(currency));
      });
    } catch (err: any) {
      toast.dismiss();
      dispatch(actions.error());
      toast.error(
        err?.response?.data?.message ??
          "There was an error performing your request, Please try again later."
      );
      pinOnclose();
    }
  };
};

export const getTransactionFee = (amount: number | string) => {
  const callSign = getCallSign();
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.gettingTransactionFee());
      const res = await instance.get(
        `${callSign}/conversion/fiat-fee?amount=${amount}`
      );
      onResponseSuccess(res, () => {
        dispatch(actions.settingTransactionFee(res.data.data));
      });
    } catch (err: any) {
      dispatch(actions.error());
    }
  };
};

export const getConvertFundsTransactions = (callSign: string, params?: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.gettingConversions());
      const res = await instance.get(`${callSign}/conversion`, { params });
      onResponseSuccess(res, () => {
        dispatch(actions.settingConversions(res.data));
      });
    } catch (err: any) {
      dispatch(actions.error());
      dispatch(actions.gettingConversions(false));
    }
  };
};

export const getSubBusinessConvertFundsTransactions = (
  callSign: string,
  params?: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.gettingConversions());
      const res = await instance.get(`${callSign}/sub-business/conversion`, {
        params
      });
      onResponseSuccess(res, () => {
        dispatch(actions.settingConversions(res.data));
      });
    } catch (err: any) {
      dispatch(actions.error());
      dispatch(actions.gettingConversions(false));
    }
  };
};

export const subBusinessFiatToFiatConversion = (
  values: any,
  dispatchAction: any,
  setOpened: any,
  pinOnclose: any,
  callSign: string
) => {
  return async (dispatch: Dispatch) => {
    const {
      amount,
      sourceCurrency,
      destinationCurrency,
      transactionPin,
      quoteId
    } = values;
    const intAmount = parseFloat(amount);

    const data = {
      transactionPin,
      sourceCurrency,
      amount: intAmount,
      destinationCurrency,
      quoteId
    };
    try {
      dispatch(actions.converting());
      toast.loading(`Converting Funds`);
      const res = await instance.post(
        `/${callSign}/sub-business/conversion/fiat`,
        data
      );
      onResponseSuccess(res, () => {
        toast.dismiss();
        dispatch(actions.convertSuccess());
        toast.success("Funds has been converted successfully");
        setOpened(true);
        pinOnclose();
        dispatchAction(getAllSubBusinessFiatWallets(callSign));
        dispatchAction(getRecentTransactions({ page: 1, perpage: 5 }));
      });
    } catch (err: any) {
      toast.dismiss();
      dispatch(actions.error());
      toast.error(
        err?.response?.data?.message ??
          "There was an error performing your request, Please try again later."
      );
    }
  };
};

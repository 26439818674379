export const useCarouselScroll = (
  ref: React.RefObject<HTMLDivElement>,
  scrollOffset: number
) => {
  // carousel controls
  const onScrollLeft = () => {
    if (!ref.current) return;
    ref.current.scrollLeft -= scrollOffset;
  };

  const onScrollRight = () => {
    if (!ref.current) return;
    ref.current.scrollLeft += scrollOffset;
  };

  return {
    onScrollLeft,
    onScrollRight
  };
};

import { Dispatch } from "@reduxjs/toolkit";
import instance from "data/axios-setup";
import { onResponseSuccess } from "data/error-mapping";
import { getCallSign } from "data/utils";
import toast from "react-hot-toast";
import { NavigateFunction } from "react-router-dom";
import { actions } from "../reducers/events.reducer";

export const getAllEvents = () => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      dispatch(actions.gettingEvent());
      const res = await instance.get(`${callSign}/event`);
      onResponseSuccess(res, () => {
        dispatch(actions.settingAllEvents(res.data.data));
      });
    } catch (err: any) {
      dispatch(actions.error());
    }
  };
};

export const reloadAllEvents = () => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      const res = await instance.get(`${callSign}/event`);
      onResponseSuccess(res, () => {
        dispatch(actions.settingAllEvents(res.data.data));
      });
    } catch (err: any) {
      dispatch(actions.error());
    }
  };
};

export const getSingleEvent = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      dispatch(actions.gettingEvent());
      const res = await instance.get(`${callSign}/event/${id}`);
      onResponseSuccess(res, () => {
        dispatch(actions.settingEvents(res.data.data));
      });
    } catch (err: any) {
      dispatch(actions.error());
    }
  };
};

export const getSingleEventMetrics = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      dispatch(actions.gettingMetrics());
      const res = await instance.get(`${callSign}/event/metrics/${id}`);
      onResponseSuccess(res, () => {
        dispatch(actions.settingMetrics(res.data.data));
      });
    } catch (err: any) {
      dispatch(actions.error());
    }
  };
};

export const getTicketDetails = (token: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.gettingTicketDetails());
      const res = await instance.get(`ticket-verification?token=${token}`);
      onResponseSuccess(res, () => {
        dispatch(actions.settingTicketDetails(res.data.data));
      });
    } catch (err: any) {
      dispatch(actions.error());
    }
  };
};

export const verifyTicket = (data: any, setStep: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.verifyingTicket());
      const res = await instance.post(`ticket-verification`, data);
      onResponseSuccess(res, () => {
        dispatch(actions.verifiedTicket());
        toast.success("Ticket has been verified successfully");
        setStep(2);
      });
    } catch (err: any) {
      dispatch(actions.error());
      toast.error(err.response.data.message);
    }
  };
};

export const updateSingleEvent = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      const res = await instance.get(`${callSign}/event/${id}`);
      onResponseSuccess(res, () => {
        dispatch(actions.settingEvents(res.data.data));
      });
    } catch (err: any) {
      dispatch(actions.error());
    }
  };
};

export const createEvent = (
  data: any,
  dis: any,
  navigate: NavigateFunction
) => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      dispatch(actions.loadingBegin());
      const res = await instance.post(`${callSign}/event`, data);
      onResponseSuccess(res, () => {
        dispatch(actions.createEventSuccess());
        toast.success("Event has been created successfully");
        dis(updateSingleEvent(res.data.data?.id));
        navigate(`/events/modify/${res.data.data?.id}`);
      });
    } catch (err: any) {
      dispatch(actions.error());
      toast.error(err.response.data.message);
    }
  };
};

export const createDraft = (
  data: any,
  dis: any,
  navigate: NavigateFunction
) => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      dispatch(actions.creatingDraft());
      const res = await instance.post(`${callSign}/event`, data);
      onResponseSuccess(res, () => {
        dispatch(actions.createEventSuccess());
        toast.success("Event has been created and saved to draft successfully");
        dis(reloadAllEvents());
        navigate("/events");
      });
    } catch (err: any) {
      dispatch(actions.error());
      toast.error(err.response.data.message);
    }
  };
};

export const updateEvent = (
  data: any,
  id: any,
  setActiveStep: any,
  dis: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      dispatch(actions.loadingBegin());
      const res = await instance.patch(`${callSign}/event/${id}`, data);
      onResponseSuccess(res, () => {
        dispatch(actions.createEventSuccess());
        toast.success("Event has been updated successfully");
        dis(updateSingleEvent(id));
        setActiveStep(2);
      });
    } catch (err: any) {
      dispatch(actions.error());
      toast.error(err.response.data.message);
    }
  };
};

export const addTicket = (data: any, id: any, dis: any, setActiveStep: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      dispatch(actions.loadingBegin());
      const res = await instance.patch(`/${callSign}/event/${id}`, data);
      onResponseSuccess(res, () => {
        toast.success("Tickets has been added to event successfully");
        dis(updateSingleEvent(id));
        setActiveStep(3);
        dispatch(actions.createEventSuccess());
        dispatch(actions.addedTickets());
      });
    } catch (err: any) {
      dispatch(actions.error());
      toast.error(err.response.data.message);
    }
  };
};

export const deleteTicket = (ticketId: any, id: any, dis: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      dispatch(actions.deletingTicket());
      toast.loading("Deleting ticket");
      const res = await instance.patch(
        `/${callSign}/event/tickets/${ticketId}`
      );
      toast.dismiss();
      onResponseSuccess(res, () => {
        toast.success("Ticket has been deleted successfully");
        dis(updateSingleEvent(id));
        dispatch(actions.deletedTicket());
      });
    } catch (err: any) {
      toast.dismiss();
      dispatch(actions.error());
      toast.error(err.response.data.message);
    }
  };
};

export const addAdditionalInfo = (
  data: any,
  id: any,
  dis: any,
  setActiveStep: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      dispatch(actions.loadingBegin());
      const res = await instance.patch(`/${callSign}/event/${id}`, data);
      onResponseSuccess(res, () => {
        toast.success(
          "Additional information has been added to event successfully"
        );
        dis(updateSingleEvent(id));
        setActiveStep(4);
        dispatch(actions.createEventSuccess());
      });
    } catch (err: any) {
      dispatch(actions.error());
      toast.error(err.response.data.message);
    }
  };
};
export const publishEvent = (
  data: any,
  id: any,
  dis: any,
  navigate: NavigateFunction
) => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      dispatch(actions.loadingBegin());
      const res = await instance.patch(`/${callSign}/event/${id}`, data);
      onResponseSuccess(res, () => {
        toast.success("Your event has been published successfully");
        dis(updateSingleEvent(id));
        dispatch(actions.createEventSuccess());
        dispatch(actions.publishEventSuccess());
        navigate("/events");
      });
    } catch (err: any) {
      dispatch(actions.error());
      toast.error(err.response.data.message);
    }
  };
};

export const unPublishEvent = (
  data: any,
  id: any,
  dis: any,
  navigate: NavigateFunction
) => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      toast.loading("Your event is been unpublished");
      dispatch(actions.loadingBegin());
      const res = await instance.patch(`/${callSign}/event/${id}`, data);
      toast.dismiss();
      onResponseSuccess(res, () => {
        toast.success("Your event has been unpublished successfully");
        dis(updateSingleEvent(id));
        dispatch(actions.createEventSuccess());
        dis(reloadAllEvents());
        navigate("/events");
      });
    } catch (err: any) {
      toast.dismiss();
      dispatch(actions.error());
      toast.error(err.response.data.message);
    }
  };
};

export const getAllTicketSold = (
  id: string,
  params: { page: number; perpage: number }
) => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      dispatch(actions.gettingTickets());
      const res = await instance.get(
        `${callSign}/event/purchased-tickets/${id}`,
        { params }
      );
      onResponseSuccess(res, () => {
        dispatch(actions.settingTickets(res.data));
      });
    } catch (err: any) {
      dispatch(actions.error());
    }
  };
};

export const getAllTicketRefunds = (
  id: string,
  params: { page: number; perpage: number }
) => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      dispatch(actions.gettingTickets());
      const res = await instance.get(
        `${callSign}/ticket-refund-request/${id}`,
        { params }
      );
      onResponseSuccess(res, () => {
        dispatch(actions.settingTicketsRefund(res.data));
      });
    } catch (err: any) {
      dispatch(actions.error());
    }
  };
};

export const getRefundAmount = (values: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      const res = await instance.post(
        `/${callSign}/event/refund-amount`,
        values
      );
      toast.dismiss();
      onResponseSuccess(res, () => {
        dispatch(actions.gottenTicketAmount(res.data));
      });
    } catch (err: any) {
      toast.dismiss();
      dispatch(actions.error());
      toast.error(err.response.data.message);
    }
  };
};

export const refundTicketSold = (
  values: any,
  id: any,
  dis: any,
  handleClose: any,
  currentPage: number,
  pageSize: number
) => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      dispatch(actions.refundingTicket());
      const res = await instance.post(
        `/${callSign}/event/refund-customer`,
        values
      );
      toast.dismiss();
      onResponseSuccess(res, () => {
        toast.success("Ticket has been refunded successfully");
        dis(getAllTicketSold(id, { page: currentPage, perpage: pageSize }));

        dis(getAllTicketRefunds(id, { page: currentPage, perpage: pageSize }));
        dispatch(actions.refundedTicket());
        handleClose();
      });
    } catch (err: any) {
      toast.dismiss();
      dispatch(actions.error());
      toast.error(err.response.data.message);
    }
  };
};

export const generateCouponCode = () => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      dispatch(actions.generatingCouponCode());
      const res = await instance.get(`${callSign}/ticket-coupon/generate`);
      onResponseSuccess(res, () => {
        dispatch(actions.setCouponCode(res.data.data));
      });
    } catch (err: any) {
      dispatch(actions.error());
    }
  };
};

export const createDiscountCode = (values: any, dis: any, id: any) => {
  return async (dispatch: Dispatch) => {
    const data = {
      code: values.code.toUpperCase(),
      name: values.name,
      percentage: +values.percentage,
      useTimes: +values.useTimes,
      expiryDate: values.expiryDate,
      customCode: values.customCode,
      isPercentage: values.isPercentage,
      fixedAmount: +values.fixedAmount
    };
    try {
      const callSign = getCallSign();
      dispatch(actions.creatingDiscountCode());
      const res = await instance.post(
        `/${callSign}/ticket-coupon/create/${values.ticketId}`,
        data
      );
      onResponseSuccess(res, () => {
        toast.success("Discount code created successfully");
        dis(getAllDiscountCodes(id));
        dispatch(actions.createdDiscountCode());
      });
    } catch (err: any) {
      dispatch(actions.error());
      toast.error(err.response.data.message);
    }
  };
};

export const disableDiscountCode = (id: number, dis: any, eventId: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      const res = await instance.patch(
        `${callSign}/ticket-coupon/disable-coupon-code/${id}`
      );
      onResponseSuccess(res, () => {
        toast.success("Discount code Disabled successfully");
        dis(updateSingleEvent(eventId));
      });
    } catch (err: any) {
      dispatch(actions.error());
    }
  };
};

export const getAllDiscountCodes = (
  id: string,
  params?: { page: number; perpage: number }
) => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      dispatch(actions.gettingAllDiscountCodes());
      const res = await instance.get(`${callSign}/ticket-coupon?event=${id}`, {
        params
      });
      onResponseSuccess(res, () => {
        dispatch(actions.settingAllDiscountCodes(res.data));
      });
    } catch (err: any) {
      dispatch(actions.error());
    }
  };
};

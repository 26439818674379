import axios, { AxiosError } from "axios";

import config, { configs } from "data/config";
import { getToken, removeToken } from "./utils";

const baseURL = config.server;

const instance = axios.create({
  baseURL: baseURL
});

instance.interceptors.request.use((config) => {
  const configInstance = { ...config };
  const token = getToken();
  config.headers ? (config.headers.Authorization = `${token}`) : "";
  return configInstance;
});

instance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;

    if (!response) {
      console.log("Internet connection is slow");
    }

    if (response && response.status === 403) {
      // removeToken();
      // return window.location.reload();
      return (window.location.href = "/forbidden");
    }

    if (response && response.status === 401) {
      removeToken();
      return (window.location.href = "/auth/login");
    }
    return Promise.reject(error);
  }
);

export default instance;

// v2 instance
export const instanceV2 = axios.create({
  baseURL: `${configs.API_BASE_URL}/v2`
});

instanceV2.interceptors.request.use((config) => {
  const configInstance = { ...config };
  const token = getToken();
  config.headers ? (config.headers.Authorization = `${token}`) : "";
  return configInstance;
});

instanceV2.interceptors.response.use(
  (response) => response,
  (error) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;

    if (!response) {
      console.log("Internet connection is slow");
    }

    if (response && response.status === 403) {
      // removeToken();
      // return window.location.reload();
      return (window.location.href = "/forbidden");
    }

    if (response && response.status === 401) {
      removeToken();
      return window.location.reload();
      return (window.location.href = "/auth/login");
    }
    return Promise.reject(error);
  }
);

// v3 instance
export const instanceV3 = axios.create({
  baseURL: `${configs.API_BASE_URL}/v3`
});

instanceV3.interceptors.request.use((config) => {
  const configInstance = { ...config };
  const token = getToken();
  config.headers ? (config.headers.Authorization = `${token}`) : "";
  return configInstance;
});

instanceV3.interceptors.response.use(
  (response) => response,
  (error) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;

    if (!response) {
      console.log("Internet connection is slow");
    }

    if (response && response.status === 403) {
      // removeToken();
      // return window.location.reload();
      return (window.location.href = "/forbidden");
    }

    if (response && response.status === 401) {
      removeToken();
      return window.location.reload();
      return (window.location.href = "/auth/login");
    }
    return Promise.reject(error);
  }
);

import { AxiosResponse } from "axios";
import instance from "data/axios-setup";

class SendMoneyService {
  async sendMoney(callSign: string, body: any) {
    return new Promise<AxiosResponse<{ message: string }>>(
      async (resolve, reject) => {
        try {
          const res = await instance.post(`${callSign}/send-money`, body);
          resolve(res);
        } catch (error) {
          reject(error);
        }
      }
    );
  }

  async getSendMoneyFee(
    callSign: string,
    params?: any
  ): Promise<AxiosResponse<{ data: number; message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/${callSign}/withdrawal-fee`, {
          params
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new SendMoneyService();

import {
  ITransaction,
  PaginationState,
  ITransactionSummary
} from "app/store/models/transaction";
import qs from "qs";
import { getCallSign } from "data/utils";
import instance from "data/axios-setup";
import { AxiosResponse } from "axios";

class TransactionService {
  async getSingleTransaction(
    signal: AbortSignal,
    params: any
  ): Promise<
    AxiosResponse<{
      data: ITransaction[];
      pagination: PaginationState;
      message?: string;
    }>
  > {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/${callSign}/transactions`, {
          params,
          signal
        });
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getSingleSubBusinessTransaction(
    callSign: string,
    signal: AbortSignal,
    params: any
  ): Promise<
    AxiosResponse<{
      data: ITransaction[];
      pagination: PaginationState;
      message?: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/${callSign}/sub-business/transactions`,
          {
            params,
            signal
          }
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getTransactions(
    params: any
  ): Promise<
    AxiosResponse<{ data: ITransaction[]; pagination: PaginationState }>
  > {
    const callSign = getCallSign();
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/${callSign}/transactions`, {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "comma" });
          }
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getTransactionById(params: {
    id: number;
  }): Promise<AxiosResponse<{ data: ITransaction[] }>> {
    return new Promise(async (resolve, reject) => {
      const callSign = getCallSign();

      try {
        const res = await instance.get(
          `/${callSign}/transactions/${params.id}`
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getConversionTransactions(
    callSign: string,
    params: any
  ): Promise<
    AxiosResponse<{ data: ITransaction[]; pagination: PaginationState }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`${callSign}/conversion-transactions`, {
          params
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAirtimeDataTransactions(
    callSign: string,
    params: any
  ): Promise<
    AxiosResponse<{ data: ITransaction[]; pagination: PaginationState }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`${callSign}/airtime-transactions`, {
          params
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getTransactionSummary({
    params
  }: {
    params: { currency: string };
  }): Promise<AxiosResponse<{ data: ITransactionSummary }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/${callSign}/transactions/summary`,
          {
            params
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getSubBusinessTransactions(
    callSign: string,
    params: any
  ): Promise<
    AxiosResponse<{ data: ITransaction[]; pagination: PaginationState }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(
          `/${callSign}/sub-business/transactions`,
          {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params, { arrayFormat: "comma" });
            }
          }
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getSubBusinessTransactionById(
    callSign: string,
    params: { id: number }
  ): Promise<AxiosResponse<{ data: ITransaction[] }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(
          `/${callSign}/sub-business/transactions/${params.id}`
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new TransactionService();

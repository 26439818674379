export const capitalize = (str: string) => {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

export const isValidURL = (str: string) => {
  if (!str.startsWith("http://") && !str.startsWith("https://")) {
    str = `https://${str}`;
  }

  try {
    const url = new URL(str);

    if (!url) return false;
    const hostname = url.hostname.split(".");

    if (hostname.length < 2) return false;
    if (!hostname[0] || !hostname[hostname.length - 1]) return false;

    return true;
  } catch (e) {
    return false;
  }
};

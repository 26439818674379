import {
  useFlutterwave,
  closePaymentModal,
  FlutterWaveTypes
} from "flutterwave-react-v3";
import { useMemo } from "react";
import { useAppSelector } from "./useReduxState";
import { configs } from "data/config";

interface IFlutterwave extends FlutterWaveTypes.FlutterwaveConfig {
  country: string;
}

/**
 * Custom hook to handle flutterwave payment
 * @param amount - Amount to be processed by flutterwave
 * @param currency - supported flutterwave currency code in which payment is to be processed in uppercase `(NGN`, `GHS...)`
 * @param country - supported flutterwave country code in which payment is to be processed in uppercase `(NG`, `GH...)`
 * @param fwcallBack - callback function to handle the response from flutterwave
 * @param onClose - callback function to call after flutterwave modal has been closed
 * @param callOnStart - callback function to call before the flutterwave modal is opened... this was added specifically to close all underlying modals: underying modals tend to clash with that of flutterwaves'.
 * @returns functions
 * - `onHandleFlutterPayment`
 * - `closePaymentModal`
 */
export const useFlutterwavePayment = (
  amount: number,
  currency: string,
  country: string,
  fwcallBack: (response: FlutterWaveTypes.FlutterWaveResponse) => void,
  onClose: () => void,
  callOnStart?: () => void
) => {
  const [userProfile, business] = useAppSelector((state) => [
    state.dashboard.userProfile,
    state.business.business
  ]);

  const config: IFlutterwave = useMemo(() => {
    return {
      public_key: configs.FLW_PUBLIC_KEY as string,
      tx_ref: Date.now().toString(),
      amount: amount,
      currency: currency.toUpperCase(),
      country: country.toUpperCase(),
      payment_options: "card",
      customer: {
        email: userProfile?.email as string,
        phone_number: business?.phone as string,
        name: business?.name as string
      },
      customizations: {
        title: `Fund ${currency.toUpperCase()} Wallet`,
        description: `Payment for funding Changera ${currency.toUpperCase()} Wallet`,
        logo: business?.logo as string
      }
    };
  }, [amount, userProfile]);

  const handleFlutterPayment = useFlutterwave(config);

  /**
   * Fucntion to trigger flutterwave modal
   */
  const onHandleFlutterPayment = () => {
    callOnStart && callOnStart(); //most likely to close all overlaying modals before the flutterwave modal comes up... this is because the flutterwave modal becomes unresponsive if there are overlapping modals
    handleFlutterPayment({
      callback: (res) => {
        fwcallBack(res);
        closePaymentModal();
      },
      onClose: onClose
    });
  };

  return { onHandleFlutterPayment, closePaymentModal };
};

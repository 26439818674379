import { IObject } from "data/models/base";

export const lineChartDataFormatter = (dataKey: string, data: IObject[]) => {
  const map: IObject = {};

  const dataResult = [];

  for (let stat of data) {
    // base condition... return an empty array if the dataKey is non existent in the data
    if (!(dataKey in stat)) return [];
    // initialize the map data with zero values for credit and debit.
    if (!(stat[dataKey] in map)) {
      let statKey = stat[dataKey];
      map[statKey] = {
        credit: "0",
        debit: "0"
      };
    }
    // set the credit value in the map data
    if ("credit" in stat) {
      map[stat[dataKey]].credit = stat.credit;
    }
    // set the debit value in the map data
    if ("debit" in stat) {
      map[stat[dataKey]].debit = stat.debit;
    }
  }

  // turn the map into an array
  for (const [key, value] of Object.entries(map)) {
    dataResult.push({
      name: key,
      credit: parseFloat(value.credit),
      debit: parseFloat(value.debit)
    });
  }

  // sort by day if daily data is being requested
  if (dataKey === "day") {
    const dataResultSorted = dataResult.sort(function (a, b) {
      return new Date(a.name).valueOf() - new Date(b.name).valueOf();
    });

    return dataResultSorted;
  }

  // sort by week if weekly data is being requested
  if (dataKey === "Week") {
    const dataResultSorted = dataResult.sort(function (a, b) {
      return +a.name - +b.name;
    });

    return dataResultSorted;
  }

  // sort by month if monthly data is being requested
  if (dataKey === "month") {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec"
    ];

    const dataResultSorted = dataResult.sort(function (a, b) {
      return months.indexOf(a.name.trim()) - months.indexOf(b.name.trim());
    });

    return dataResultSorted;
  }

  return dataResult;
};

export const getTotalCreditAndDebit = (
  data?: {
    type: string;
    total: string;
  }[]
) => {
  const map: { credit: number; debit: number } = {
    credit: 0,
    debit: 0
  };

  if (!data)
    return {
      credit: 0,
      debit: 0
    };

  for (let datum of data) {
    if (datum.type === "credit") {
      map["credit"] = parseFloat(datum.total);
    }
    if (datum.type === "debit") {
      map["debit"] = parseFloat(datum.total);
    }
  }

  return map;
};

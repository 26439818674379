import {
  IAccountError,
  IAccountState,
  IAccountSuccess,
  TAccountLoading
} from "../models/account";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: IAccountState = {
  error: null,
  status: {
    resetPin: "idle",
    changePassword: "idle",
    getResetPinCode: "idle",
    uploadProfileImage: "idle",
    validateResetPinCode: "idle",
    changeTransactionPin: "idle",
    createTransactionPin: "idle",
    updateNotificationSettings: "idle"
  }
};

export const AccountSlice = createSlice({
  initialState,
  name: "account",
  reducers: {
    loading: (state: IAccountState, action: PayloadAction<TAccountLoading>) => {
      state.status[action.payload] = "loading";
    },
    idle: (state: IAccountState, action: PayloadAction<IAccountSuccess>) => {
      state.status[action.payload.for] = "idle";
    },
    error: (state: IAccountState, action: PayloadAction<IAccountError>) => {
      state.error = action.payload.msg;
      state.status[action.payload.for] = "failed";
    },
    success: (state: IAccountState, action: PayloadAction<IAccountSuccess>) => {
      state.status[action.payload.for] = "succeeded";
    },
    updateNotificationInfo: (state: IAccountState) => {
      state.status.updateNotificationSettings = "succeeded";
    }
  }
});

export const actions = AccountSlice.actions;
export default AccountSlice.reducer;

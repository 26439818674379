import {
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  InputRightElement,
  useToast,
  Box,
  Text,
  Stack,
  FormErrorMessage,
  Alert,
  AlertIcon,
  AlertDescription
} from "@chakra-ui/react";
import { FundWalletWithCardProps } from "../base-models";
import getSymbolFromCurrency from "currency-symbol-map";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import {
  useAppDispatch,
  useAppSelector,
  useCurrencyFlag,
  useFlutterwavePayment,
  useGetAllTransactions,
  useGetRecentTransactions
} from "app/hooks";
import { useState } from "react";
import { FlutterWaveTypes } from "flutterwave-react-v3";
import { actions } from "app/store/reducers/app.reducer";
import walletsService from "data/services/wallets.service";
import { DEFAULT_ERROR_MESSAGE, onResponseSuccess } from "data/error-mapping";
import { fundWalletWithCardRequestBody } from "app/store/models/wallet";
import { getAllFiatWallets } from "app/store";

const FundWithCard = ({
  closeOverlayingModalCallBack,
  currency,
  country
}: FundWalletWithCardProps) => {
  //
  const dispatch = useAppDispatch();
  //
  const chakraToast = useToast();

  //
  const walletDetails = useAppSelector((state) => state.wallets.singleWallet);
  const businessStatus = useAppSelector(
    (state) => state.dashboard.recentActivities?.kyc?.status
  );

  //
  const [state, setState] = useState("");

  const CurrencyFlag = useCurrencyFlag();
  const { fetchRecentTransactions } = useGetRecentTransactions();
  const { fetchAllTransactions } = useGetAllTransactions();

  const flutterCallBack = async (
    fwResponse: FlutterWaveTypes.FlutterWaveResponse
  ) => {
    // console.log(fwResponse);

    const { amount, currency, flw_ref, status, transaction_id, tx_ref } =
      fwResponse;

    try {
      const res = await walletsService.fundWalletWithCard({
        amount,
        currency,
        flw_ref,
        status,
        transaction_id,
        tx_ref
      } as fundWalletWithCardRequestBody);
      onResponseSuccess(res, () => {
        dispatch(actions.setLoading(false));
        chakraToast({
          title: "Wallet funded successfully",
          description: res.data.message,
          status: "info",
          variant: "subtle",
          position: "top-right",
          duration: 9000,
          isClosable: true
        });
        dispatch(getAllFiatWallets());

        fetchAllTransactions();
        fetchRecentTransactions();
        window.location.reload();
      });
    } catch (error: any) {
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      chakraToast({
        title: "Error Funding Wallet",
        description: msg,
        status: "error",
        variant: "subtle",
        position: "top-right",
        duration: 9000,
        isClosable: true
      });
      dispatch(actions.setLoading(false));
    }
  };

  const flutterOnClose = () => {
    dispatch(actions.setLoading(false));
  };

  const flutterCallOnStart = () => {
    closeOverlayingModalCallBack();
    dispatch(actions.setLoading(true));
  };

  const { onHandleFlutterPayment } = useFlutterwavePayment(
    +state,
    currency.toUpperCase(),
    country.toUpperCase(),
    flutterCallBack,
    flutterOnClose,
    flutterCallOnStart
  );

  const onFundWithCardHandler = () => {
    onHandleFlutterPayment();
  };

  return walletDetails.data?.currencyId ? (
    <Stack w="full">
      <Box w="full">
        <Text mb={6}>How much do you want to fund?</Text>
      </Box>
      <FormControl
        isInvalid={
          !!state && +state < walletDetails.data?.currencyId.fundingMin
        }
      >
        <FormLabel color={"gray.600"} fontSize="sm">
          Amount to Fund
        </FormLabel>
        <InputGroup>
          <Input
            as={NumberFormat}
            prefix={getSymbolFromCurrency(currency)}
            placeholder="0.00"
            thousandSeparator={true}
            inputMode="numeric"
            fixedDecimalScale={false}
            decimalScale={2}
            allowLeadingZeros={false}
            displayType="input"
            name="amount"
            value={state}
            onValueChange={({ value }: NumberFormatValues) => {
              setState(() => {
                if (value.startsWith("-")) return value.slice(1);
                return value;
              });
            }}
            isAllowed={(values: any) => {
              const { value } = values;
              return value >= 0;
            }}
            autoComplete="off"
          />
          <InputRightElement children={CurrencyFlag(currency)} />
        </InputGroup>
        <FormErrorMessage>
          Min:{" "}
          {`${
            walletDetails.data?.currencyId.symbol
          }${walletDetails.data?.currencyId.fundingMin.toLocaleString()}`}
        </FormErrorMessage>
      </FormControl>
      <Stack direction={"column"} pt="16">
        {businessStatus === "pending" ? (
          <Alert status="error" bg="white">
            <AlertIcon />
            <AlertDescription textColor={"error.500"}>
              Your Business is awaiting approval
            </AlertDescription>
          </Alert>
        ) : null}
        <Button
          mt="16"
          size="lg"
          fontSize={14}
          width="100%"
          colorScheme="primary"
          disabled={
            !state ||
            +state < walletDetails.data?.currencyId.fundingMin ||
            businessStatus !== "approved"
          }
          onClick={onFundWithCardHandler}
        >
          Proceed to Pay with Card
        </Button>
      </Stack>
    </Stack>
  ) : null;
};

export default FundWithCard;

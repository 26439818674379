import Crypto from "crypto-js";
import { configs } from "data/config";
// import { configs } from "data/config";
// import ebanqoService from "data/services/ebanqo.service";
import { plusSomeSeconds } from "data/utils";

import { useCallback, useMemo } from "react";
import useInlineScript from "./use-inline-script";
import { useAppSelector } from "./useReduxState";

const clientId = "Changera";

interface IGenerateHash {
  plainText: string;
  secretKey: string;
}

function generateHash({ plainText, secretKey }: IGenerateHash) {
  return String(Crypto?.HmacSHA256(plainText, secretKey));
}

export function jwtSign(
  payload: Record<string, any>,
  secret: string,
  expiresInSec?: number
) {
  const header = {
    alg: "HS256",
    typ: "JWT"
  };
  const exp = plusSomeSeconds(null, expiresInSec || 120);
  const encodedHeaders = btoa(JSON.stringify(header));
  const encodedPayload = btoa(JSON.stringify({ ...payload, exp: exp }));
  const signature = generateHash({
    plainText: `${encodedHeaders}.${encodedPayload}`,
    secretKey: secret
  });
  const encodedSignature = btoa(signature);
  const jwt = `${encodedHeaders}.${encodedPayload}.${encodedSignature}`;
  return jwt.endsWith("==") ? jwt.slice(0, -2) : jwt;
}

const chatScript = async ({
  clientId,
  userData
}: {
  clientId?: string;
  userData?: Record<string, any>;
}) => {
  let userDataString = "";
  const includeUserData = false;
  if (userData && includeUserData) {
    const userDataEntries = Object.entries(userData).map(
      ([key, value]) => `${key}: '${value}'`
    );
    userDataString = `${userDataEntries.join(", ")}`;
  }

  // const expiresInSeconds = 600;

  const sharedQuery = {
    source: "webchat",
    os: "web"
  } as Record<string, any>;

  let queryObj: Record<string, any> = userData
    ? {
        fullname: userData?.fullname,
        form_id: "identifier",
        identifier: userData?.userId,
        ...sharedQuery
      }
    : { ...sharedQuery };

  // if (configs.CHAT_SUPPORT_SECRET) {
  //   let token = jwtSign(
  //     {
  //       ping: "pong" // the content of the jwt is immaterial, only the integrity of the signature is required for validation
  //     },
  //     configs.CHAT_SUPPORT_SECRET || "",
  //     expiresInSeconds
  //   );

  //   // const token = await ebanqoService.generateToken();

  //   console.log({ token });

  //   if (token) queryObj["token"] = token;
  // }

  const userDataEntries = Object.entries(queryObj).map(
    ([key, value]) => `${key}=${value}`
  );
  const queryUrl = `?${userDataEntries.join("&")}`;

  return `
(function (w, d, s, o, f, js, fjs) {
     w['EBANQO-WIDGET'] = o;
     w[o] = w[o] ||
         function () {
             (w[o].q = w[o].q || []).push(arguments);
         };
     (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
     js.id = o;
     js.src = f;
     js.async = 1;
     fjs.parentNode.insertBefore(js, fjs);
 })(window, document, 'script', 'ebanqo_widget', 'https://widget.ebanqo.io/app.js');
 ebanqo_widget && ebanqo_widget('init', { url: 'https://webchat.ebanqo.io/v2/${clientId}${queryUrl}'${
    userDataString ? `, ${userDataString}` : ","
  } })();
`;
};

export function useSupport() {
  const { userProfile: user } = useAppSelector((state) => state.dashboard);

  const bootConfig = useMemo(
    () => ({
      name: user?.firstName,
      fullname: `${String(
        `${user?.firstName || ""} ${user?.lastName || ""}`
      ).trim()}`,
      userId: `Changera-business-${user?.id}`,
      email: user?.email,
      phone: user?.phone || undefined,
      createdAt: user?.createdAt,
      utmSource: "web"
    }),
    [user]
  );

  const { injectScript } = useInlineScript("cs-chat");

  // skip this... but will propose to provider to support id hashing
  //   if (
  //     !isReady.current &&
  //     configs.INTERCOM_SECRET &&
  //     String(configs.INTERCOM_SECRET).length > 0
  //   ) {
  //     try {
  //       const userHash = generateHash({
  //         plainText: `${user?.id}` || "",
  //         secretKey: configs.INTERCOM_SECRET
  //       });
  //       Object.assign(bootConfig, { userHash });
  //       if (userHash) isReady.current = true;
  //     } catch (err) {
  //       console.warn({ err });
  //     }
  //   }

  const boot = useCallback(
    async (_props?: any) => {
      injectScript({
        inlineScript: await chatScript({ clientId, userData: bootConfig })
      });
    },
    [injectScript, bootConfig]
  );

  const bootWithProps = useCallback(() => {
    return boot(bootConfig);
  }, [bootConfig, boot]);

  const minimize = useCallback(() => {
    const chatbotContainer = document.querySelector(".ebanqo-widget-wrapperV2");
    if (chatbotContainer) {
      (chatbotContainer as any).style.display = "";
    }
  }, []);

  const maximize = useCallback(() => {
    const chatbotContainer = document.querySelector(".ebanqo-widget-wrapperV2");
    if (chatbotContainer) {
      (chatbotContainer as any).style.display = "block";
    }
  }, []);

  const shutdown = useCallback(() => {
    minimize();
    const chatbotPrompt = document.querySelector(
      ".initial-prompt-message-wrapper"
    );
    if (chatbotPrompt) {
      (chatbotPrompt as any).style.display = "";
    }
  }, [minimize]);

  const restore = useCallback(() => {
    maximize();
    const chatbotPrompt = document.querySelector(
      ".initial-prompt-message-wrapper"
    );
    if (chatbotPrompt) {
      (chatbotPrompt as any).style.display = "flex";
    }
  }, [maximize]);

  const logout = useCallback(() => {
    // we are unable to access the chat iframe...due to security policy
    // provider should expose a logout api
  }, []);

  return {
    bootWithProps,
    boot,
    shutdown,
    minimize,
    maximize,
    logout,
    restore
  };
}

// Charla

const charlaWidgetScript = ({
  url,
  propertyId
}: {
  url: string;
  propertyId: string;
}) => {
  return `const widgetElement = document.createElement('charla-widget'); widgetElement.setAttribute("p", "${propertyId}"); document.body.appendChild(widgetElement) ; const widgetCode = document.createElement('script'); widgetCode.src = '${url}'; document.body.appendChild(widgetCode);
  `;
};

export const useSupportV2 = () => {
  const { injectScript } = useInlineScript("payborda-cs-chat");

  const bootCharlaWidget = useCallback(async () => {
    const [url, propertyId] = [configs.CHARLA_URL, configs.CHARLA_ID];
    if (!url && !propertyId) return;

    injectScript({
      inlineScript: charlaWidgetScript({
        url: url as string,
        propertyId: propertyId as string
      })
    });
  }, [injectScript]);

  const dispatchUserDetails = (props: {
    email: string;
    name: string;
    phone: string;
    businessName?: string;
    callSign?: string;
    businessEmail?: string;
  }) => {
    const charla_event = new CustomEvent("charla:updateVisitorAttributes", {
      detail: {
        email: props.email,
        phone: props.phone,
        name: `
          User's Name: ${props.name}\n
          ${
            props?.businessName
              ? "Business's Name: " + props.businessName + "\n"
              : ""
          }
          ${
            props?.callSign
              ? "Business's callSign: " + props.callSign + "\n"
              : ""
          }
          ${
            props?.businessEmail
              ? "Business's Email: " + props.businessEmail + "\n"
              : ""
          }
        `
      }
    });

    console.log("@event: charla:updateVisitorAttributes");

    document.dispatchEvent(charla_event);
  };

  return {
    bootCharlaWidget,
    dispatchUserDetails
  };
};

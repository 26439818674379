import { ILien, LienState, PaginationState } from "../models/lien";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: LienState = {
  allLien: {
    data: [],
    error: "",
    loading: false,
    pagination: {
      next: 2,
      total: 0,
      prevPage: 0,
      lastPage: 0,
      pageSize: 10,
      hasNext: true,
      currentPage: 1,
      hasPrevious: false
    }
  }
};

export const LienReducer = createSlice({
  initialState,
  name: "lien",
  reducers: {
    getAllLienLoading: (state) => {
      state.allLien.loading = true;
    },
    getAllLienSuccess: (
      state,
      action: PayloadAction<{
        data: ILien[];
        pagination: PaginationState;
      }>
    ) => {
      state.allLien.loading = false;
      state.allLien.data = action.payload.data;
      state.allLien.pagination = action.payload.pagination;
    },
    getAllLienFailed: (state, action: PayloadAction<string>) => {
      state.allLien.loading = false;
      state.allLien.error = action.payload;
    }
  }
});

export const actions = LienReducer.actions;
export default LienReducer.reducer;

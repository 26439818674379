import {
  FLUSH,
  PAUSE,
  PURGE,
  PERSIST,
  REGISTER,
  REHYDRATE,
  persistStore,
  persistReducer
} from "redux-persist";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import auth from "./reducers/auth.reducer";
import bank from "./reducers/bank.reducer";
import misc from "./reducers/misc.reducer";
import storage from "redux-persist/lib/storage";
import account from "./reducers/account.reducer";
import currency from "./reducers/currency.reducer";
import business from "./reducers/business.reducer";
import dashboard from "./reducers/dashboard.reducer";
import withdrawal from "./reducers/withdrawal.reducer";
import transaction from "./reducers/transaction.reducer";
import mobileMoneyWallet from "./reducers/mobile-money-wallet";
import businessSetup from "./reducers/business-setup.reducers";
import teammanagement from "./reducers/team-management.reducer";
import wallets from "./reducers/wallet.reducer";
import card from "./reducers/card.reducer";
import app from "./reducers/app.reducer";
import sendMoney from "./reducers/send-money.reducer";
import airtimeAndData from "./reducers/airtime-and-data.reducer";
import convertFunds from "./reducers/convert-funds.reducer";
import virtualAccounts from "./reducers/virtual-accounts.reducer";
import recipient from "./reducers/recipient.reducer";
import paySomeone from "./reducers/pay-someone.reducer";
import notification from "./reducers/notification.reducer";
import events from "./reducers/events.reducer";
import fx from "./reducers/fx.reducer";
import featureManagement from "./reducers/feature-management.reducer";
import subscription from "./reducers/subscription.reducer";
import subBusinesses from "./reducers/sub-buinesses.reducer";
import quote from "./reducers/quote.reducer";
import bid from "./reducers/bid.reducer";
import lien from "./reducers/lien.reducer";

const rootReducer = combineReducers({
  fx,
  app,
  bid,
  auth,
  card,
  misc,
  bank,
  account,
  currency,
  business,
  sendMoney,
  recipient,
  dashboard,
  withdrawal,
  transaction,
  businessSetup,
  teammanagement,
  wallets,
  mobileMoneyWallet,
  airtimeAndData,
  virtualAccounts,
  convertFunds,
  paySomeone,
  notification,
  events,
  featureManagement,
  subscription,
  subBusinesses,
  quote,
  lien
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: []
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export const persistor = persistStore(store);

import {
  NotificationType,
  IGetNotificationsParam
} from "../models/notification";
import toast from "react-hot-toast";
import { getCallSign } from "data/utils";
import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/notification.reducer";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import notificationService from "data/services/notification.service";

export const getNotifications = (
  callSign: string,
  params: IGetNotificationsParam
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.gettingNotifications(params.type));
      const res = await notificationService.getNotifications(callSign, params);
      dispatch(actions.setNotifications({ ...res.data, type: params.type }));
    } catch (err: any) {
      const msg = err?.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.error(msg));
    }
  };
};

export const updateNotification = (
  type: NotificationType,
  callSign: string,
  id: number
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.updatingNotification());
      await notificationService.updateNotification(callSign, id);
      dispatch(actions.updateNotification({ id, type }));
    } catch (err: any) {
      const msg = err?.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error(msg));
    }
  };
};

export const readAllNotification = (
  type: NotificationType,
  data: { ids: number[] }
) => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign()!;
      dispatch(actions.updatingNotification());
      if (!data.ids.length) return toast.success("Success");
      await notificationService.readAllNotification(callSign, data);
      dispatch(actions.readAllNotification(type));
      toast.success("Success");
    } catch (err: any) {
      const msg = err?.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error(msg));
    }
  };
};

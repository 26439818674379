import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFeature, IFeatureState } from "data/models/feature-management";

const initialState: IFeatureState = {
  singleFeature: {
    data: null,
    loading: false,
    error: ""
  }
};

export const FeatureManagement = createSlice({
  name: "feature-management",
  initialState,
  reducers: {
    getSingleFeatureBegin: (state) => {
      state.singleFeature.loading = true;
      state.singleFeature.error = "";
    },
    getSingleFeatureSuccess: (
      state,
      action: PayloadAction<{ data: IFeature }>
    ) => {
      state.singleFeature.loading = false;
      state.singleFeature.error = "";
      state.singleFeature.data = action.payload.data;
    },
    getSingleFeatureError: (state, action: PayloadAction<string>) => {
      state.singleFeature.loading = false;
      state.singleFeature.error = action.payload;
    }
  }
});

export const actions = FeatureManagement.actions;

export default FeatureManagement.reducer;

import {
  Button,
  Stack,
  PinInput,
  PinInputField,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  Box
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  getEmailVerificationCode,
  getPhoneVerificationCode,
  verifyEmailAddress,
  verifyPhoneNumber
} from "app/store";
import { Sms, Whatsapp } from "iconsax-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RegistrationSuccessContainer } from "./registration-success-container";

const channels = ["whatsapp", "sms"];
export const VerificationContainer = () => {
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { userProfile } = useAppSelector((state) => state?.dashboard);
  const {
    gettingCode,
    loading,
    phoneCodeGotten,
    emailCodeGotten,
    emailVerified,
    phoneVerified
  } = useAppSelector((state) => state.auth);

  const [counter, setCounter] = useState(300);
  useEffect(() => {
    const timer = setInterval(() => {
      setCounter(counter - 1);
    }, 1000);
    return () => clearInterval(timer);
  });

  const [emailCounter, setEmailCounter] = useState(300);
  useEffect(() => {
    const timer = setInterval(() => {
      setEmailCounter(emailCounter - 1);
    }, 1000);
    return () => clearInterval(timer);
  });

  const getEmailCode = () => {
    setEmailCounter(300);
    dispatch(getEmailVerificationCode());
  };

  const [channel, setchannel] = useState("whatsapp");
  const getPhoneCode = (channel: string) => {
    setCounter(300);
    dispatch(getPhoneVerificationCode(channel, onClose));
  };
  const [values, setValues] = useState({
    emailCode: "",
    phoneCode: ""
  });

  const verifyPhoneCode = () => {
    dispatch(verifyPhoneNumber(values));
  };
  const verifyEmailCode = () => {
    dispatch(verifyEmailAddress(values));
  };

  useEffect(() => {
    if (userProfile && userProfile.emailVerified && userProfile.phoneVerified) {
      navigate("/");
    }
  }, [userProfile]);
  return (
    <>
      {/* Display success message if these conditions passes */}
      {(emailVerified && phoneVerified) ||
      (userProfile && phoneVerified && userProfile.emailVerified) ||
      (userProfile && emailVerified && userProfile.phoneVerified) ? (
        <RegistrationSuccessContainer />
      ) : (
        <>
          {" "}
          <div className="w-11/12 p-6 bg-gray-100 mdx:w-5/12 sm:w-9/12">
            <p className="text-gray-900 text-heading100 mdx:text-heading300">
              Verification
            </p>
            <p className="mt-1 text-gray-700">
              To secure your account, please complete the following verification
            </p>
            <div className="mt-4 mdx:mt-10 authForm">
              <form>
                {/* show nothing if phone is verified already */}
                {phoneVerified || userProfile?.phoneVerified ? (
                  <></>
                ) : (
                  <>
                    <div className="my-4">
                      <p className="mb-2 text-gray-500">
                        Phone number verification code
                      </p>
                      <div className="flex flex-col justify-between lg:items-center lg:flex-row">
                        <Stack direction="row" spacing={[2]}>
                          <PinInput
                            mask
                            size="lg"
                            type="number"
                            onChange={(e) =>
                              setValues({ ...values, phoneCode: e })
                            }
                          >
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                          </PinInput>
                        </Stack>
                        {/* change button to submit button when otp has been sent */}

                        {phoneCodeGotten ? (
                          <Button
                            disabled={!values.phoneCode}
                            isLoading={gettingCode}
                            onClick={verifyPhoneCode}
                            className="mt-4 bg-primary-600 mdx:mt-0"
                            textColor="white"
                            bgColor="#111834"
                            padding="6"
                            _hover={{ bgColor: "#3B435E" }}
                          >
                            <div className="flex items-center">Submit</div>
                          </Button>
                        ) : (
                          <Button
                            onClick={onOpen}
                            className="mt-4 bg-primary-600 mdx:mt-0"
                            textColor="white"
                            bgColor="#111834"
                            padding="6"
                            _hover={{ bgColor: "#3B435E" }}
                          >
                            <div className="flex items-center">Get code</div>
                          </Button>
                        )}
                      </div>
                      <p className="mt-2 text-gray-700">
                        Input the 6 digit code sent to{" "}
                        {userProfile?.phone?.slice(0, 4)}
                        {"***"}
                        {userProfile?.phone?.slice(-3)}
                      </p>
                      <div>
                        {phoneCodeGotten && (
                          <>
                            {counter < 1 ? (
                              <p className="mt-3 font-light text-primary-600">
                                Didn’t receive an OTP?{" "}
                                <span
                                  className="ml-1 font-bold cursor-pointer"
                                  onClick={onOpen}
                                >
                                  Resend OTP
                                </span>
                              </p>
                            ) : (
                              <p className="mt-3 font-light text-primary-600">
                                Didn’t receive an OTP?{" "}
                                <span className="font-bold">
                                  Resend in {counter}s
                                </span>
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {/* show nothing if email is verified already */}
                {emailVerified || userProfile?.emailVerified ? (
                  <></>
                ) : (
                  <>
                    <div className="my-4">
                      <p className="mb-2 text-gray-500">
                        Email verification code
                      </p>
                      <div className="flex flex-col justify-between lg:items-center lg:flex-row">
                        <Stack direction="row" spacing={[2]}>
                          <PinInput
                            mask
                            size="lg"
                            onChange={(e) =>
                              setValues({ ...values, emailCode: e })
                            }
                          >
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                          </PinInput>
                        </Stack>
                        {/* change button to submit button when otp has been sent */}
                        {emailCodeGotten ? (
                          <Button
                            disabled={!values.emailCode}
                            isLoading={loading}
                            onClick={verifyEmailCode}
                            className="mt-4 bg-primary-600 mdx:mt-0"
                            textColor="white"
                            bgColor="#111834"
                            padding="6"
                            _hover={{ bgColor: "#3B435E" }}
                          >
                            <div className="flex items-center">Submit</div>
                          </Button>
                        ) : (
                          <Button
                            isLoading={loading}
                            onClick={getEmailCode}
                            className="mt-4 bg-primary-600 mdx:mt-0"
                            textColor="white"
                            bgColor="#111834"
                            padding="6"
                            _hover={{ bgColor: "#3B435E" }}
                          >
                            <div className="flex items-center">Get code</div>
                          </Button>
                        )}
                      </div>
                      <p className="mt-2 text-gray-700">
                        Input the 6 digit code sent to{" "}
                        {userProfile?.email?.slice(0, 5)}
                        ***
                      </p>
                      <div>
                        {emailCodeGotten && (
                          <>
                            {emailCounter < 1 ? (
                              <p className="mt-3 font-light text-primary-600">
                                Didn’t receive an OTP?{" "}
                                <span
                                  className="ml-1 font-bold cursor-pointer"
                                  onClick={getEmailCode}
                                >
                                  Resend OTP
                                </span>
                              </p>
                            ) : (
                              <p className="mt-3 font-light text-primary-600">
                                Didn’t receive an OTP?{" "}
                                <span className="font-bold">
                                  Resend in {emailCounter}s
                                </span>
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </>
      )}

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent px={2} pt={2} pb={4}>
          <ModalBody>
            <div className="p-3">
              <Text
                m={0}
                fontSize={20}
                color="#333843"
                fontWeight={600}
                textAlign="center"
              >
                {" "}
                Choose OTP Channel
              </Text>
              <p className="mt-5 text-base text-center text-gray-700 ">
                We’ll send you a code to verify the number you provided. How do
                you want to receive the code?
              </p>

              <div className="flex flex-wrap gap-2 my-4 justify-center">
                {" "}
                {channels.map((type) => (
                  <Box
                    border={
                      channel === type
                        ? "solid 1px #111834"
                        : "solid 1px #EFF0F3"
                    }
                    color={channel === type ? "#111834" : "#111834"}
                    fontWeight={channel === type ? "semibold" : "normal"}
                    cursor={"pointer"}
                    borderRadius={"12px"}
                    p={3}
                    display={"flex"}
                    alignItems="center"
                    gap={"2"}
                    key={type}
                    onClick={() => {
                      setchannel(type);
                    }}
                  >
                    <div>
                      {type === "whatsapp" ? (
                        <Whatsapp size="20" color={"#111834"} />
                      ) : (
                        <Sms size="20" color={"#111834"} />
                      )}
                    </div>
                    <div>
                      <p className="text-secondary text-sm capitalize">
                        Send via {type}
                      </p>
                    </div>
                  </Box>
                ))}
              </div>

              <div className="mt-4 flex gap-2">
                <Button
                  className="my-3"
                  variant="outline"
                  color="#111834"
                  w="full"
                  padding="6"
                  onClick={() => {
                    setchannel("sms");
                    onClose();
                  }}
                >
                  Close
                </Button>{" "}
                <Button
                  isLoading={gettingCode}
                  isDisabled={!channel}
                  className="my-3 bg-primary-600"
                  textColor="white"
                  bgColor="#111834"
                  w="full"
                  padding="6"
                  _hover={{ bgColor: "#111834" }}
                  onClick={() => getPhoneCode(channel)}
                >
                  Get OTP
                </Button>{" "}
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

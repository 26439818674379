import { getRecentRecipients, getRecipients } from "app/store";
import { IRecipientTypes } from "app/store/models/recipient.model";
import { useAppDispatch, useAppSelector } from "./useReduxState";

/**
 * Custom Hook to fetch all recipients from the store
 * @returns -
 * - `dispatchAllRecipients`
 * - `paginationData`
 */
export const useDispatchAllRecipients = ({
  recipientType
}: {
  recipientType: IRecipientTypes;
}) => {
  const paginationData = useAppSelector(
    (state) => state.recipient.pagination.getRecipients
  );

  const dispatch = useAppDispatch();

  const dispatchAllRecipients = (currentPage?: number, pageSize?: number) => {
    const page = !currentPage ? paginationData.currentPage : currentPage;

    const perpage = !pageSize ? paginationData.pageSize : pageSize;

    if (!perpage) return;
    dispatch(getRecipients({ recipientType, perpage, page }));
  };

  return { dispatchAllRecipients, paginationData };
};

/**
 * returns a function to fetch recent recipients from the store
 * @returns `dispatchRecentRecipients`
 */
export const useDispatchRecentRecipients = () => {
  const dispatch = useAppDispatch();

  const dispatchRecentRecipients = () => {
    dispatch(getRecentRecipients({ page: 1, perpage: 5 }));
  };

  return { dispatchRecentRecipients };
};

import { AxiosResponse } from "axios";
import instance from "data/axios-setup";
import { IFeature } from "data/models/feature-management";
import { getCallSign } from "data/utils";

class FeatureManagementService {
  async getSingleFeature(
    feature: string
  ): Promise<AxiosResponse<{ data: IFeature }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/${callSign}/feature-management/${feature}`
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new FeatureManagementService();

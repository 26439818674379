import { createSlice } from "@reduxjs/toolkit";
import { SubscriptionState } from "../models/subscription";

const initialState: SubscriptionState = {
  loading: false,
  subscriptionPrice: {
    loading: false,
    data: null
  }
};

export const SubscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    makingSubscribtion: (state) => {
      state.loading = true;
    },
    subscribed: (state) => {
      state.loading = false;
    },
    updatingPaymentMethod: (state) => {
      state.loading = true;
    },
    updatedPaymentMethod: (state) => {
      state.loading = false;
    },
    gettingSubscriptionPrice: (state) => {
      state.subscriptionPrice.loading = true;
    },
    settingSubscriptionPrice: (state, action) => {
      state.subscriptionPrice.loading = false;
      state.subscriptionPrice.data = action.payload;
    },
    error: (state) => {
      state.subscriptionPrice.loading = false;
      state.loading = false;
    }
  }
});

export const actions = SubscriptionSlice.actions;

export default SubscriptionSlice.reducer;

export const businessTypes = [
  {
    name: "Sole Proprietorship",
    value: "sole-proprietorship"
  },
  {
    name: "Limited Liability",
    value: "limited-liability"
  },
  {
    name: "Partnership",
    value: "partnership"
  },
  {
    name: "Association",
    value: "association"
  },
  {
    name: "Other",
    value: "other"
  }
];

import { useEffect } from "react";
import { getToken } from "data/utils";
import { useNavigate } from "react-router-dom";
import { TwoFALoginContainer } from "views/containers/auth";

const TwoFALogin = (): JSX.Element => {
  const navigate = useNavigate();

  const token = getToken();
  useEffect(() => {
    if (token) {
      navigate("/");
    } else {
    }
  }, []);

  return <TwoFALoginContainer />;
};

export default TwoFALogin;

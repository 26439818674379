import getSymbolFromCurrency from "currency-symbol-map";
import { supportedCountries } from "./supportedCountries";

export const currencyFormatter = (currency: string) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency.toUpperCase()
  });
};

export const numberToCurrencyFormatter = new Intl.NumberFormat("en-US", {
  notation: "standard",
  compactDisplay: "short",
  maximumFractionDigits: 2
});

export const currencyStringFormater = (currency: string) => {
  if (!currency) return "";
  return currency === "cusd".toLocaleLowerCase()
    ? "cUSD"
    : currency?.toUpperCase();
};

export const getCurrencySymbolFromCountry = (country: string) => {
  const selectedCountry = supportedCountries.find((c) => c.name === country);
  return getSymbolFromCurrency(selectedCountry?.currency || "");
};

export const resolveGenerateStatementCurrency = (
  currency: string = "",
  country: string = ""
) => {
  let value = currency ? currency : country;
  let func = currency ? getSymbolFromCurrency : getCurrencySymbolFromCountry;

  let symbol = value === "ghs" ? "" : func(value || "");

  const name = symbol ? "" : currencyStringFormater(value);
  symbol = symbol || "";
  return { name, symbol };
};

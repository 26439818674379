import { VirtualAccountResData } from "app/store/models/virtual-accounts";
import { GetAllFiatWalletResponse } from "app/store/models/wallet";
import { IObject } from "data/models/base";
import { currencyStringFormater } from "./currencyFormatter";

export const findFeeRange = (num: number, ranges: string[]) => {
  for (let i = 0; i < ranges.length; i++) {
    let [start, end] = ranges[i]
      .split("-")
      .map((value) => (value === "infinity" ? Infinity : Number(value)));
    if (num >= start && num <= end) {
      return ranges[i];
    }
  }
  return null;
};

export const getFeeValue = (
  amount: number = 0,
  fee: number = 0,
  type?: string
) => {
  if (type === "fixed") return fee;
  if (type === "percentage") return (amount * fee) / 100;
  return fee;
};

export const getCurrencyNetwork = (currency: string, network: string) => {
  if (network === "trc-20") return `${currency}_tron`;
  if (network === "matic") return `${currency}_matic`;
  return currency;
};

export const getCurrencyFromNetwork = (
  currency: "usdt" | "usdc" | string,
  network: "erc-20" | "trc-20" | "matic" | string
) => {
  if (network === "matic") {
    return `${currency.toUpperCase()} Polygon`;
  }
  if (network === "trc-20") {
    return `${currency.toUpperCase()} Tron`;
  }
  return currencyStringFormater(currency);
};

export const avalaibleCryptoNetworks = (coin: string) => {
  if (coin === "usdt") {
    return [
      {
        network: "erc-20",
        title: "Ethereum Network (ERC-20)"
      },
      {
        network: "trc-20",
        title: "Tron Network (TRC-20)"
      },
      {
        network: "matic",
        title: "Polygon Network"
      }
    ];
  }
  if (coin === "usdc") {
    return [
      {
        network: "erc-20",
        title: "Ethereum Network (ERC-20)"
      },
      {
        network: "matic",
        title: "Polygon Network"
      }
    ];
  }
  return [
    {
      network: "erc-20",
      title: "Ethereum Network (ERC-20)"
    },
    {
      network: "trc-20",
      title: "Tron Network (TRC-20)"
    },
    {
      network: "matic",
      title: "Polygon Network"
    }
  ];
};

export const avalaibleFundingModes = [
  {
    mode: "bankTransfer",
    title: "Bank Transfer"
  }
  // {
  //   mode: "card",
  //   title: "Fund with Card"
  // }
];

// const currencies = ["ngn", "gbp", "usd", "eur", "ghs", "kes"];

export const getWalletsWithVirtualAccount = (
  wallets: GetAllFiatWalletResponse[],
  vAcc: VirtualAccountResData[]
) => {
  //
  const virtualAccountMap: IObject = {};

  for (let acc of vAcc) {
    if (acc.currency === "gbp_eur") {
      virtualAccountMap["gbp"] = acc;
      virtualAccountMap["eur"] = acc;
    } else {
      virtualAccountMap[acc.currency] = acc;
    }
  }

  const walletsWithVirtualAccounts = wallets.map((wallet) => {
    const virtualAccounts: any[] = [];
    Object.entries(virtualAccountMap).map(([key, value]: any[]) => {
      // console.log({ key, wallet: wallet.currency });
      if (key === wallet.currency) {
        virtualAccounts.push(value);
      }
    });
    wallet.virtualAccounts = virtualAccounts;
    // console.log({ wallet });
    return { ...wallet, virtualAccounts };
  });

  return walletsWithVirtualAccounts;

  // console.log({ virtualAccountMap, walletsWithVirtualAccounts });

  // // step 1 - save the virtual accounts in a map, with the walletId (secondary key) as the Id
  // const map: IObject = {};

  // for (let acc of vAcc) {
  //   const accKeyInMap = map[acc?.wallet?.id];
  //   if (!accKeyInMap) {
  //     map[acc?.wallet?.id] = [acc];
  //   } //save an array of the virtual acc if it's not yet in the map
  //   else {
  //     accKeyInMap.push(acc);
  //     map[acc.wallet.id] = accKeyInMap;
  //   } // else push to the array the current virtual account
  // } // now the map is filled with the walletId as the key and the arrays of virtual accounts as the values

  // const formattedWallets = wallets
  //   .map((el) => {
  //     el.virtualAccounts = map[el.id] ? map[el.id] : [];
  //     return el;
  //   })
  //   .sort(function (a, b) {
  //     return (
  //       currencies.indexOf(a.currencyId.code.toLowerCase()) -
  //       currencies.indexOf(b.currencyId.code.toLowerCase())
  //     );
  //   });

  // return formattedWallets;
};

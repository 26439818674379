import {
  IBusiness,
  IBusinessError,
  IBusinessOwners,
  IBusinessState,
  IBusinessSuccess,
  TBusinessLoading
} from "../models/business";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: IBusinessState = {
  error: null,
  statements: [],
  statement: null,
  status: {
    uploadLogo: "idle",
    getSingleBusiness: "idle",
    getTransactionStatement: "idle",
    uploadTransactionStatement: "idle",
    getSingleTransactionStatement: "idle",
    generateTransactionStatement: "idle",
    getBusinessOwners: "idle"
  },
  business: {
    id: "",
    name: "",
    logo: "",
    email: "",
    phone: "",
    address: "",
    callSign: ""
  },
  owners: []
};

export const BusinessSlice = createSlice({
  initialState,
  name: "business",
  reducers: {
    idle: (state: IBusinessState, action: PayloadAction<TBusinessLoading>) => {
      state.status[action.payload] = "idle";
    },
    loading: (
      state: IBusinessState,
      action: PayloadAction<TBusinessLoading>
    ) => {
      state.status[action.payload] = "loading";
    },
    error: (state: IBusinessState, action: PayloadAction<IBusinessError>) => {
      state.error = action.payload.msg;
      state.status[action.payload.for] = "failed";
    },
    success: (
      state: IBusinessState,
      action: PayloadAction<IBusinessSuccess>
    ) => {
      state.status[action.payload.for] = "succeeded";
    },
    updateSingleBusiness: (
      state: IBusinessState,
      action: PayloadAction<IBusinessSuccess>
    ) => {
      state.status[action.payload.for] = "succeeded";

      const data: any = action.payload.data || {};
      state.business = { ...state.business, ...data };
    },
    setSingleBusiness: (
      state: IBusinessState,
      action: PayloadAction<IBusiness>
    ) => {
      state.business = action.payload;
      state.status.getSingleBusiness = "succeeded";
    },

    setSingleStatement: (
      state: IBusinessState,
      action: PayloadAction<string>
    ) => {
      state.statement = action.payload;
      state.status.getSingleTransactionStatement = "succeeded";
    },

    setTransactionStatement: (
      state: IBusinessState,
      action: PayloadAction<any[]>
    ) => {
      state.statements = action.payload;
      state.status.getTransactionStatement = "succeeded";
    },

    setBusinessOwners: (
      state: IBusinessState,
      action: PayloadAction<IBusinessOwners[]>
    ) => {
      state.owners = action.payload;
      state.status.getBusinessOwners = "succeeded";
    }
  }
});

export const actions = BusinessSlice.actions;
export default BusinessSlice.reducer;

import {
  IFX,
  IFXFee,
  ISendFX,
  IGetFXFees,
  IRequestFX,
  IReceiveFXBody,
  IFXReceiptData,
  IFXBeneficiary,
  IActivateFxType,
  IFXAddRecipient,
  IFXAddBeneficiary,
  IFXExternalAccount,
  IReceiveFXResponse,
  IFXAddExternalAccount,
  IFXUploadPaymentProof
} from "app/store/models/fx";
import { AxiosResponse } from "axios";
import instance, { instanceV2 } from "data/axios-setup";
import { IPagination } from "data/models/base";

class FxService {
  async sendFX(
    callSign: string,
    body: ISendFX
  ): Promise<AxiosResponse<{ data: IFXReceiptData; message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post(`/${callSign}/fx/send`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async activateFxType(
    fxId: number,
    callSign: string,
    body: IActivateFxType
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.patch(
          `/${callSign}/fx/activate-fx/${fxId}`,
          body
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async receiveFX(
    callSign: string,
    body: IReceiveFXBody
  ): Promise<AxiosResponse<{ data: IReceiveFXResponse; message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post(`/${callSign}/fx/receive`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getFXFee(
    callSign: string,
    body: IGetFXFees,
    signal?: AbortSignal
  ): Promise<AxiosResponse<{ data: IFXFee }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instanceV2.post(`/${callSign}/fx/fx-fees`, body, {
          signal
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async requestFX(
    callSign: string,
    body: IRequestFX
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post(`/${callSign}/fx`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async reInitiateFXRequest(
    id: number,
    callSign: string,
    body: IRequestFX
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.patch(`/${callSign}/fx/fx-data/${id}`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getSingleFX(id: number, callSign: string): Promise<AxiosResponse<IFX>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/${callSign}/fx/${id}`);
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getSingleFXExternalAccount(
    id: number,
    callSign: string
  ): Promise<AxiosResponse<IFXExternalAccount>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/${callSign}/fx-external-accounts/${id}`
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAllFXs(
    callSign: string
  ): Promise<AxiosResponse<{ data: IFX[]; pagination: IPagination }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/${callSign}/fx`);
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAllFXExternalAccounts(
    callSign: string,
    params?: any
  ): Promise<
    AxiosResponse<{ data: IFXExternalAccount[]; pagination: IPagination }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/${callSign}/fx-external-accounts`,
          { params }
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAllFXBeneficiaries(
    callSign: string,
    params?: any
  ): Promise<
    AxiosResponse<{ data: IFXBeneficiary[]; pagination: IPagination }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/${callSign}/fx-external-accounts/beneficiaries`,
          {
            params
          }
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async addExternalAccount(
    callSign: string,
    body: IFXAddExternalAccount
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/${callSign}/fx-external-accounts`,
          body
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async addBeneficiary(
    callSign: string,
    body: IFXAddBeneficiary
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/${callSign}/fx-external-accounts/beneficiary`,
          body
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async addRecipient(
    callSign: string,
    body: IFXAddRecipient
  ): Promise<AxiosResponse<any>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/${callSign}/fx/add-recipient`,
          body
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async uploadPaymentProof(
    callSign: string,
    { id, fxReceipt }: IFXUploadPaymentProof
  ): Promise<AxiosResponse<any>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.patch(
          `/${callSign}/fx/upload-receipt/${id}`,
          { fxReceipt }
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getSubBusinessFXs(
    callSign: string
  ): Promise<AxiosResponse<{ data: IFX[]; pagination: IPagination }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/${callSign}/sub-business/fx`);
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async subBusinessReinitiateFXRequest(
    id: number,
    callSign: string,
    body: IRequestFX
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.patch(
          `/${callSign}/sub-business/fx/fx-data/${id}`,
          body
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async subBusinessSRequestFX(
    callSign: string,
    body: IRequestFX
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post(`/${callSign}/sub-business/fx`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getSubBusinessFXExternalAccounts(
    callSign: string,
    params?: any
  ): Promise<
    AxiosResponse<{ data: IFXExternalAccount[]; pagination: IPagination }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/${callSign}/sub-business/fx-external-accounts`,
          { params }
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async subBusinessAddExternalAccount(
    callSign: string,
    body: IFXAddExternalAccount
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/${callSign}/sub-business/fx-external-accounts`,
          body
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async subBusinessGetFXFee(
    callSign: string,
    body: IGetFXFees,
    signal?: AbortSignal
  ): Promise<AxiosResponse<{ data: IFXFee }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post(
          `/${callSign}/sub-business/fx/fx-fees`,
          body,
          { signal }
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async subBusinessReceiveFX(
    callSign: string,
    body: IReceiveFXBody
  ): Promise<AxiosResponse<{ data: IReceiveFXResponse; message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post(
          `/${callSign}/sub-business/fx/receive`,
          body
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async subBusinessUploadPaymentProof(
    callSign: string,
    { id, fxReceipt }: IFXUploadPaymentProof
  ): Promise<AxiosResponse<any>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.patch(
          `/${callSign}/sub-business/fx/upload-receipt/${id}`,
          { fxReceipt }
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async subBusinessSendFX(
    callSign: string,
    body: ISendFX
  ): Promise<AxiosResponse<{ data: IFXReceiptData; message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post(
          `/${callSign}/sub-business/fx/send`,
          body
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async subBusinessGetSingleFX(
    id: number,
    callSign: string
  ): Promise<AxiosResponse<IFX>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/${callSign}/sub-business/fx/${id}`
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new FxService();

import { AxiosError } from "axios";
import toast from "react-hot-toast";
import instance from "data/axios-setup";
import { getCallSign } from "data/utils";
import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/misc.reducer";
import miscService from "data/services/misc.service";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import { IResolveBankBody, TMiscLoading } from "../models/misc";
import withdrawalService from "data/services/withdrawal.service";
import transactionService from "data/services/transaction.service";
import { ChangeraTransactionType as Type } from "../models/transaction";
import currencyService from "data/services/currency.service";
import LocalStorage from "data/utils/localStorage";

export const getBanks = (code: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getBanks"));
      const res = await miscService.getBanks(code);
      dispatch(actions.setBanksInfo(res.data.data));
    } catch (err: any) {
      const error: AxiosError<{ message: string }> = err;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.error({ msg, for: "getBanks" }));
    }
  };
};

export const getTransactionStatement = (params: any, type: Type) => {
  return async (dispatch: Dispatch) => {
    try {
      let res: any = {};
      dispatch(actions.loading("getTransactionStatement"));

      if (type === Type.WITHDRAWAL) {
        res = await withdrawalService.getAllWithdrawals(params);
        dispatch(
          actions.setTransactionStatement({
            type: "withdrawal",
            data: res.data.data
          })
        );
      }

      if (type === Type.FUNDING_WALLET) {
        res = await transactionService.getTransactions(params);
        dispatch(
          actions.setTransactionStatement({
            type: "funding",
            data: res.data.data
          })
        );
      }

      if (type === Type.PAY_MONEY) {
        res = await withdrawalService.getAllWithdrawals(params);
        dispatch(
          actions.setTransactionStatement({
            type: "paySomeone",
            data: res.data.data
          })
        );
      }

      if (type === Type.FIAT_CONVERSION) {
        const callSign = getCallSign();
        res = await instance.get(`${callSign}/conversion`, { params });
        dispatch(
          actions.setTransactionStatement({
            type: "conversion",
            data: res.data.data
          })
        );
      }

      if (type === Type.AIRTIME_VTU) {
        const callSign = getCallSign() as string;
        res = await transactionService.getAirtimeDataTransactions(
          callSign,
          params
        );
        dispatch(
          actions.setTransactionStatement({
            type: "airtimeData",
            data: res.data.data
          })
        );
      }

      if (type === Type.VIRTUAL_CARD) {
        res = await transactionService.getTransactions(params);
        dispatch(
          actions.setTransactionStatement({
            type: "card",
            data: res.data.data
          })
        );
      }

      if (type === Type.EVENT_TICKET) {
        res = await transactionService.getTransactions(params);
        dispatch(
          actions.setTransactionStatement({
            type: "ticket",
            data: res.data.data
          })
        );
      }
    } catch (err: any) {
      const error: AxiosError<{ message: string }> = err;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.error({ msg, for: "getTransactionStatement" }));
    }
  };
};

export const getMobileMoneyAgents = (country: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getMobileMoneyAgents"));
      const res = await miscService.getMobileMoneyAgents(country);
      dispatch(actions.setMobileMoneyAgents(res.data.data));
    } catch (err: any) {
      const error: AxiosError<{ message: string }> = err;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.error({ msg, for: "getMobileMoneyAgents" }));
    }
  };
};

export const getCountriesOfRegistration = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getCountriesOfRegistration"));
      const res = await miscService.getCountriesOfRegistration();
      dispatch(actions.setCountriesOfRegistration(res.data));
      const success: { message: string } = res.data as any;
      toast.success(success.message);
    } catch (err: any) {
      const msg: string = err?.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "getCountriesOfRegistration" }));
    }
  };
};

export const resolveBank = (body: IResolveBankBody) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("resolveBank"));
      const res = await miscService.resolveBank(body);
      dispatch(actions.setResolvedBankInfo(res.data.data));
    } catch (err: any) {
      const msg: string = err?.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      // toast.error(msg);
      dispatch(actions.error({ msg, for: "resolveBank" }));
    }
  };
};

export const getsupportedFiatWallets = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getsupportedFiatWalletsBegin());
      const res = await miscService.getSupportedFiatWallets();
      if (res.status === 200 || 201 || 202) {
        dispatch(actions.getsupportedFiatWalletsSuccess(res.data.data));
      }
    } catch (error: any) {
      dispatch(
        actions.getsupportedFiatWalletsError(
          error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
        )
      );
    }
  };
};

export const getsupportedCryptoWallets = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getsupportedCryptoWalletsBegin());
      const res = await miscService.getSupportedCryptoWallets();
      if (res.status === 200 || 201 || 202) {
        dispatch(actions.getsupportedCryptoWalletsSuccess(res.data.data));
      }
    } catch (error: any) {
      dispatch(
        actions.getsupportedCryptoWalletsError(
          error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
        )
      );
    }
  };
};

export const setFirebaseNotificationToken = (deviceToken: string) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setFirebaseNotificationToken(deviceToken));
  };
};

export const setMiscIdleStatus = (value: TMiscLoading) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.idle(value));
  };
};

export const getAppVersion = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAppVersionBegin());
      const res = await miscService.getAppVersion();
      if (res.status === 200 || 201 || 202) {
        dispatch(actions.getAppVersionSuccess(res.data.data));
      }
    } catch (error: any) {
      dispatch(
        actions.getAppVersionError(
          error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
        )
      );
    }
  };
};

export const getExchangeRates = () => {
  const currencies = ["ngn", "ghs", "kes", "gbp", "eur"];
  const lastRefreshedKey = "exchangeRateDashboardChangera";

  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getExchangeRatesBegin());

      const rateData = await Promise.all(
        currencies.map((el) =>
          currencyService.getExchangeRateV2({
            currencyCode: el
          })
        )
      );

      dispatch(
        actions.getExchangeRatesSuccess(rateData.map((el) => el.data.data))
      );

      LocalStorage.set(lastRefreshedKey, Date.now());
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.getExchangeRatesError(msg));
    }
  };
};

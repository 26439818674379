import { useRoutes } from "react-router-dom";
import routes from "app/routes";
import Loading from "views/components/loading";
import {
  Suspense
  // useEffect
} from "react";
// import { useAppSelector, useSupport } from "app/hooks";
// import { getToken } from "data/utils";

export default function Routes() {
  // const { boot } = useSupport();
  // const { isLoggedIn } = useAppSelector((state) => state.auth);
  // const { userProfile } = useAppSelector((state) => state.dashboard);

  // useEffect(() => {
  //   if (process.env.REACT_APP_NODE_ENV === "production") return;
  //   // auto-boot chatbot only if user is loggedin
  //   const token = getToken();
  //   if (!!token && !!isLoggedIn && !!userProfile) {
  //     boot();
  //   }
  //   console.log({ boot: "boot ebanqo" });
  // }, [isLoggedIn, userProfile]);

  const routesHere = useRoutes(routes);

  return <Suspense fallback={<Loading />}>{routesHere}</Suspense>;
}

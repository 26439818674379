import { AxiosResponse } from "axios";
import instance from "data/axios-setup";
import { getCallSign } from "data/utils";
import { IAddBankBody, IBank } from "app/store/models/bank";

class BankService {
  async getUserBanks(
    signal: AbortSignal,
    callSign: string
  ): Promise<AxiosResponse<{ data: IBank[] }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/${callSign}/bank`, { signal });
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async addBank(callSign: string, body: IAddBankBody) {
    return new Promise<AxiosResponse<{ data: IBank; message: string }>>(
      async (resolve, reject) => {
        try {
          const res = await instance.post(`${callSign}/bank`, body);
          resolve(res);
        } catch (error) {
          reject(error);
        }
      }
    );
  }

  async delete(id: number) {
    return new Promise<AxiosResponse<{ data: IBank; message: string }>>(
      async (resolve, reject) => {
        try {
          const callSign = getCallSign();
          const res = await instance.put(`${callSign}/bank/${id}`);
          resolve(res);
        } catch (error) {
          reject(error);
        }
      }
    );
  }
}

export default new BankService();

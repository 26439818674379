import { LoginContainer } from "views/containers/auth";
import { useEffect } from "react";
// import { getUserProfile } from "app/store";
// import { useLocation } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getToken } from "data/utils";
// import { useAppSelector } from "app/hooks";
// import Loading from "views/components/loading";
const Login = (): JSX.Element => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const inviteToken = searchParams.get("token");
  // const location = useLocation();
  // const dispatch = useAppDispatch();

  // const [user, isLoading] = useAppSelector((state) => [
  //   state.dashboard.userProfile,
  //   state.dashboard.loading
  // ]);

  const token = getToken();
  useEffect(() => {
    if (token && !inviteToken) navigate("/");
  }, []);

  // (window as any)?.Intercom("update", { hide_default_launcher: false });

  return <LoginContainer />;
};

export default Login;

import {
  PaySomeoneModalState,
  PaySomeoneDrawerState,
  TPaySomeoneMethod
} from "../models/pay-someone";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { getCallSign } from "data/utils";
import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/pay-someone.reducer";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import currencyService from "data/services/currency.service";
import paySomeoneService from "data/services/pay-someone.service";
import { actions as walletActions } from "../reducers/wallet.reducer";

export const paySomeone = (callSign: string, body: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("paySomeone"));
      const res = await paySomeoneService.paySomeone(callSign, body);

      if (body?.currency && body?.amount) {
        dispatch(
          walletActions.withdrawFiatWallet({
            amount: body.amount,
            currency: body.currency
          })
        );
      }

      toast.success(res.data.message);
      dispatch(actions.success(res.data.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "paySomeone" }));
    }
  };
};

export const getPaySomeoneFee = (
  callSign: string,
  params?: { amount: number; country: string; method: TPaySomeoneMethod }
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getPaySomeoneFee"));
      const res = await paySomeoneService.getPaySomeoneFee(callSign, params);
      dispatch(actions.setFee(res.data.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "getPaySomeoneFee" }));
    }
  };
};

export const getPaySomeoneBusinessRecipient = (callSign: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getPaySomeoneBusinessRecipientLoading());
      const res = await paySomeoneService.getPaySomeoneBusinessRecipient(
        callSign
      );

      dispatch(actions.getPaySomeoneBusinessRecipientSuccess(res.data.data));
    } catch (err) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getPaySomeoneBusinessRecipientError(msg));
    }
  };
};

export const getPaySomeonePersonalRecipient = (username: string) => {
  const callSign = getCallSign() as string;
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getPaySomeonePersonalRecipientLoading());
      const res = await paySomeoneService.getPaySomeonePersonalRecipient(
        callSign,
        username
      );

      dispatch(
        actions.getPaySomeonePersonalRecipientSuccess(res.data.data.data)
      );
    } catch (err) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getPaySomeonePersonalRecipientError(msg));
    }
  };
};

export const getPaySomeoneExchangeRate = (params: {
  amount: number;
  sourceCurrency: string;
  destinationCurrency: string;
}) => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await currencyService.getExchangeRate(params);
      if (res.status === 200 || 201 || 202) {
        dispatch(actions.setExchangeRate(res.data.data));
      }
    } catch (error: any) {
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;

      dispatch(actions.error({ msg, for: "getExchangeRate" }));
    }
  };
};

export const setPaySomeoneDrawer = (value: Partial<PaySomeoneDrawerState>) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setDrawerState(value));
  };
};

export const setPaySomeoneModal = (value: Partial<PaySomeoneModalState>) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setModalState(value));
  };
};

export const closePaySomeoneModal = () => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setModalState({ isOpen: false, view: "transaction-pin" }));
  };
};

export const resetPaySomeoneState = () => {
  return (dispatch: Dispatch) => {
    dispatch(actions.resetState());
  };
};

export const resetPaySomeoneDrawerState = () => {
  return (dispatch: Dispatch) => {
    dispatch(actions.resetDrawerState());
  };
};

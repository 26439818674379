import {
  Pin,
  Amount,
  Method,
  WalletType,
  WithdrawalState,
  IWithdrawalError,
  TWithdrawalLoading,
  IUsdWithdrawalState,
  IWithdrawalCryptoWallet
} from "../models/withdrawal";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPagination } from "data/models/base";

const pagination: IPagination = {
  next: 2,
  total: 0,
  prevPage: 0,
  lastPage: 0,
  pageSize: 10,
  hasNext: true,
  currentPage: 1,
  hasPrevious: false
};

const initialState: WithdrawalState = {
  error: "",
  data: null,
  maxValue: 0,
  isOpen: false,
  noBank: false,
  status: "idle",
  loading: false,
  sendMoney: [],
  withdrawal: [],
  currentStep: 0,
  bank: undefined,
  withdrawals: [],
  withdrawalFee: 0,
  wallet: undefined,
  method: undefined,
  success: false,
  cryptoWallet: undefined,
  currency: undefined,
  subWalletId: undefined,
  walletType: "fiat" as const,
  amount: { value: 0, max: 0 },
  pin: { isOpen: false, value: undefined },
  pagination: { getAllWithdrawals: pagination },
  state: {
    getWithdrawalFee: "idle",
    getAllWithdrawals: "idle",
    getCryptoNetworkFee: "idle"
  },
  usdModal: { isOpen: false }
};

export const WithdrawalSlice = createSlice({
  initialState,
  name: "withdrawal",
  reducers: {
    resetWalletWithdrawal: (state: WithdrawalState) => {
      state.error = "";
      state.data = null;
      state.maxValue = 0;
      state.isOpen = false;
      state.noBank = false;
      state.status = "idle";
      state.loading = false;
      state.currentStep = 0;
      state.bank = undefined;
      state.withdrawalFee = 0;
      state.method = undefined;
      state.walletAddress = "";
      state.success = false;
      state.currency = undefined;
      state.narration = undefined;
      state.subWalletId = undefined;
      state.cryptoWallet = undefined;
      state.usdModal = initialState.usdModal;
      // state.walletType = "fiat" as const;
      state.amount = { value: 0, max: 0 };
      state.pin = { isOpen: false, value: undefined };
      state.state = {
        getWithdrawalFee: "idle",
        getAllWithdrawals: "idle",
        getCryptoNetworkFee: "idle"
      };
    },

    idle: (
      state: WithdrawalState,
      action: PayloadAction<TWithdrawalLoading>
    ) => {
      state.state[action.payload] = "idle";
    },

    loading: (
      state: WithdrawalState,
      action: PayloadAction<TWithdrawalLoading>
    ) => {
      state.state[action.payload] = "loading";
    },

    setWithdrawalTransactionsInfo: (
      state: WithdrawalState,
      action: PayloadAction<{ data: any[]; pagination: IPagination }>
    ) => {
      state.withdrawal = action.payload.data;
      state.state.getAllWithdrawals = "succeeded";
      state.pagination.getAllWithdrawals = action.payload.pagination;
    },

    setWithdrawalSendMoneyInfo: (
      state: WithdrawalState,
      action: PayloadAction<{ data: any[]; pagination: IPagination }>
    ) => {
      state.sendMoney = action.payload.data;
      state.state.getAllWithdrawals = "succeeded";
      state.pagination.getAllWithdrawals = action.payload.pagination;
    },

    setWithdrawalsInfo: (
      state: WithdrawalState,
      action: PayloadAction<any[]>
    ) => {
      state.withdrawals = action.payload;
      state.state.getAllWithdrawals = "succeeded";
    },

    setWithdrawalFee: (
      state: WithdrawalState,
      action: PayloadAction<number>
    ) => {
      state.withdrawalFee = action.payload;
      state.state.getWithdrawalFee = "succeeded";
    },

    setCryptoNetworkFee: (
      state: WithdrawalState,
      action: PayloadAction<any>
    ) => {
      state.cryptoNetworkFee = action.payload;
      state.state.getCryptoNetworkFee = "succeeded";
    },

    error: (
      state: WithdrawalState,
      action: PayloadAction<IWithdrawalError>
    ) => {
      state.error = action.payload.msg;
      state.state[action.payload.for] = "failed";
    },

    setWalletWithdrawalMethod: (
      state: WithdrawalState,
      action: PayloadAction<Method | undefined>
    ) => {
      state.method = action.payload;
    },

    setWithdrawalWallet: (
      state: WithdrawalState,
      action: PayloadAction<any>
    ) => {
      state.wallet = action.payload;
    },

    setWithdrawalCryptoWallet: (
      state: WithdrawalState,
      action: PayloadAction<IWithdrawalCryptoWallet>
    ) => {
      state.cryptoWallet = action.payload;
    },
    setWalletWithdrawalAddress: (
      state: WithdrawalState,
      action: PayloadAction<string>
    ) => {
      state.walletAddress = action.payload;
    },
    setWalletWithdrawalCurrency: (
      state: WithdrawalState,
      action: PayloadAction<string>
    ) => {
      state.currency = action.payload;
    },

    setWalletWithdrawalModal: (
      state: WithdrawalState,
      action: PayloadAction<boolean>
    ) => {
      state.isOpen = action.payload;
    },

    setWalletWithdrawalAmount: (
      state: WithdrawalState,
      action: PayloadAction<Amount>
    ) => {
      state.amount = action.payload;
    },

    setWalletWithdrawalNarration: (
      state: WithdrawalState,
      action: PayloadAction<string>
    ) => {
      state.narration = action.payload;
    },

    setWalletWithdrawalSubWallet: (
      state: WithdrawalState,
      action: PayloadAction<number>
    ) => {
      state.subWalletId = action.payload;
    },

    setWalletWithdrawalWalletType: (
      state: WithdrawalState,
      action: PayloadAction<WalletType>
    ) => {
      state.walletType = action.payload;
    },

    setWalletWithdrawalPin: (
      state: WithdrawalState,
      action: PayloadAction<Pin>
    ) => {
      state.pin = action.payload;
    },

    setWalletWithdrawalBank: (
      state: WithdrawalState,
      action: PayloadAction<number | undefined>
    ) => {
      state.bank = action.payload;
    },

    setWalletWithdrawalStep: (
      state: WithdrawalState,
      action: PayloadAction<number>
    ) => {
      state.currentStep = action.payload;
    },

    walletWithdrawalBegin: (state: WithdrawalState) => {
      state.error = "";
      state.loading = true;
      state.status = "loading";
    },
    walletWithdrawalError: (
      state: WithdrawalState,
      action: PayloadAction<string>
    ) => {
      state.loading = false;
      state.status = "failed";
      state.error = action.payload;
    },
    walletWithdrawalSuccess: (state: WithdrawalState) => {
      state.error = "";
      state.loading = false;
      state.status = "succeeded";
      state.success = true;
    },
    walletWithdrawalReceiptSave: (state: WithdrawalState, action) => {
      state.success = true;
      state.receiptDetails = action.payload.data;
    },
    setWithdrawalUsdModal: (
      state: WithdrawalState,
      action: PayloadAction<IUsdWithdrawalState>
    ) => {
      const prevState = { ...state.usdModal };
      state.usdModal = { ...prevState, ...action.payload };
    }
  }
});

export const actions = WithdrawalSlice.actions;

export default WithdrawalSlice.reducer;

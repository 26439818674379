import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationState } from "../models/transaction";
import { IKyc, Role } from "./business-setup.reducers";

const pagination = {
  next: 0,
  total: 0,
  prevPage: 0,
  lastPage: 0,
  pageSize: 10,
  hasNext: false,
  currentPage: 1,
  hasPrevious: false
};

export interface SubBusinessInfoKyc {
  id: number;
  status: string;
  certificateOfRegistration: string;
  cacRegistrationForm: string;
  certificateOfIncorporation: string;
  memorandumAndArticlesOfAssociation: string;
  particularsOfDirectors: string;
  particularsOfShareholders: string;
  boardResolution: string;
  utilityBill: string;
  bankStatement: string;
  verificationComment: string;
  latestKyc: boolean;
  termsAndConditionsOfPartnership: string;
  partnershipConsentForm: string;
  createdAt: string;
  updatedAt: string;
  subBusiness: SubBusinessInfo;
  processedBy: string;
  subBusinessCompleteSetup: boolean;
}

export interface SubBusinessInfo {
  kyc: IKyc;
  id: number;
  tin: string;
  type: string;
  city: string;
  name: string;
  logo: string;
  email: string;
  state: string;
  phone: string;
  tinUrl: string;
  zipCode: string;
  country: string;
  website: string;
  address: string;
  callSign: string;
  industry: string;
  legalName: string;
  description: string;
  dateOfBirth: string;
  addressNumber: string;
  sourceOfFunds: string;
  otherSourceOfFunds: string;
  countryRegisteredIn: string;
  updatesNotification: string;
  fundingNotification: string;
  countryRegisteredInII: string;
  transfersNotification: string;
  withdrawalNotification: string;
  subBusinessCompleteSetup: boolean;
  dateOfIncorporation: string | null;
  businessRegistrationNumber: string;
  isRegisteredInAnotherCountry: boolean;
  role: "partner" | "business";
  subBusinessOwners: {
    id: number;
    email: string;
    idUrl: string;
    phone: string;
    idType: string;
    version: string;
    position: string;
    lastName: string;
    idNumber: string;
    createdAt: string;
    updatedAt: string;
    firstName: string;
    ownership: string;
    ssn: string | null;
    nationality: string;
    idIssuedDate: string;
    idExpirationDate: string;
    politicsInclined: boolean;
  }[];
  member: {
    role: Role | null;
    status: string;
    type: string;
    email: string;
    firstName: string;
  };
  qrCode: string;
  subscriptionPlan: string;
  subscription: {
    billingRetryCount: number;
    billingWallet: number;
    createdAt: string;
    id: number;
    lastBillingDate: string;
    lastBillingplan: string;
    nextBillingDate: string;
    nextBillingplan: string;
    updatedAt: string;
  };
  status: string;
  createdAt: string;
}

interface SubBusinessSetupState {
  singleSubBusinessInfo: any;
  allSubBusinessKyc: SubBusinessInfoKyc[];
  allSubBusinessKycPagination: PaginationState;
  subBusinessInfo: SubBusinessInfo;
  subBusinessInfoLoading: boolean;
  loading: boolean;
  subBusinessError: boolean;
  createSubBusinessSuccess: boolean;
  createSubBusinessCompleted: boolean;
  addedSubBusinessOwner: boolean;
  confirmedCallSign: boolean;
  confirmingCallSign: boolean;
  supportedCountries: [];
  allSubBusinessInfoLoading: boolean;
}

const initialState: SubBusinessSetupState = {
  subBusinessInfo: null as any,
  allSubBusinessKyc: [],
  allSubBusinessKycPagination: pagination,
  subBusinessInfoLoading: false,
  loading: false,
  subBusinessError: false,
  createSubBusinessSuccess: false,
  createSubBusinessCompleted: false,
  singleSubBusinessInfo: null,
  addedSubBusinessOwner: false,
  confirmedCallSign: false,
  confirmingCallSign: false,
  supportedCountries: [],
  allSubBusinessInfoLoading: false
};

export const SubBusinessSetupSlice = createSlice({
  name: "subBusinessSetup",
  initialState,
  reducers: {
    error: (state: SubBusinessSetupState) => {
      state.loading = false;
      state.subBusinessError = true;
      state.subBusinessInfoLoading = false;
      state.subBusinessInfoLoading = false;
      state.confirmingCallSign = false;
    },
    gettingAllBusinesses: (state: SubBusinessSetupState) => {
      state.subBusinessInfoLoading = true;
    },
    gettingBusinessInfo: (state: SubBusinessSetupState) => {
      state.subBusinessInfoLoading = true;
    },
    settingAllBusiness: (state: SubBusinessSetupState, action) => {
      state.allSubBusinessKyc = action.payload.data;
      state.allSubBusinessKycPagination = action.payload.pagination;
      state.subBusinessInfoLoading = false;
    },
    settingBusinessInfo: (
      state: SubBusinessSetupState,
      action: PayloadAction<SubBusinessInfo>
    ) => {
      state.subBusinessInfo = action.payload;
      state.subBusinessInfoLoading = false;
    },

    clearingBusinessInfo: (state: SubBusinessSetupState) => {
      state.subBusinessInfo = null as any;
      state.subBusinessInfoLoading = false;
    },

    subBusinessError: (state: SubBusinessSetupState) => {
      state.loading = false;
      state.subBusinessInfoLoading = false;
      state.subBusinessError = true;
    },
    loadingBegin: (state: SubBusinessSetupState) => {
      state.loading = true;
    },
    loadingStop: (state: SubBusinessSetupState) => {
      state.loading = false;
    },
    createSubBusinessSuccess: (state: SubBusinessSetupState) => {
      state.createSubBusinessSuccess = true;
      state.loading = false;
      state.subBusinessError = false;
    },
    addedSubBusinessOwnerDetails: (state: SubBusinessSetupState) => {
      state.addedSubBusinessOwner = true;
      state.loading = false;
    },
    finishBusinessRegistration: (state: SubBusinessSetupState) => {
      state.createSubBusinessSuccess = true;
      state.createSubBusinessCompleted = true;
      state.loading = false;
      state.subBusinessError = false;
    },
    confimingCallSign: (state: SubBusinessSetupState) => {
      state.confirmingCallSign = true;
    },
    callSignConfirmed: (
      state: SubBusinessSetupState,
      action: PayloadAction<any>
    ) => {
      state.confirmingCallSign = false;
      state.confirmedCallSign = action.payload;
    },
    settingSupportedCountries: (
      state: SubBusinessSetupState,
      action: PayloadAction<any>
    ) => {
      state.supportedCountries = action.payload;
    },
    updateBusinessLogo: (
      state: SubBusinessSetupState,
      action: PayloadAction<string>
    ) => {
      if (state.subBusinessInfo) state.subBusinessInfo.logo = action.payload;
    }
  }
});

export const {
  gettingAllBusinesses,
  gettingBusinessInfo,
  settingBusinessInfo,
  settingAllBusiness,
  subBusinessError,
  loadingBegin,
  loadingStop,
  createSubBusinessSuccess,
  addedSubBusinessOwnerDetails,
  error,
  finishBusinessRegistration,
  confimingCallSign,
  callSignConfirmed,
  settingSupportedCountries,
  updateBusinessLogo,
  clearingBusinessInfo
} = SubBusinessSetupSlice.actions;

export default SubBusinessSetupSlice.reducer;

import {
  IRecipient,
  IRecipientError,
  IRecipientState,
  IUSDBeneficiary,
  TRecipientLoading
} from "../models/recipient.model";
import { IPagination } from "data/models/base";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const pagination: IPagination = {
  next: 2,
  total: 0,
  prevPage: 0,
  lastPage: 0,
  pageSize: 10,
  hasNext: true,
  currentPage: 1,
  hasPrevious: false
};

const initialState: IRecipientState = {
  error: null,
  recipients: [],
  recentRecipients: [],

  pagination: {
    getRecipients: pagination
  },

  status: {
    addRecipient: "idle",
    getRecipients: "idle",
    recentRecipients: "idle"
  },

  usdBeneficiary: { data: [], error: "", loading: false }
};

export const RecipientSlice = createSlice({
  initialState,
  name: "recipient",
  reducers: {
    idle: (
      state: IRecipientState,
      action: PayloadAction<TRecipientLoading>
    ) => {
      state.status[action.payload] = "idle";
    },
    loading: (
      state: IRecipientState,
      action: PayloadAction<TRecipientLoading>
    ) => {
      state.status[action.payload] = "loading";
    },
    error: (state: IRecipientState, action: PayloadAction<IRecipientError>) => {
      state.error = action.payload.msg;
      state.status[action.payload.for] = "failed";
    },
    setRecipientsInfo: (
      state: IRecipientState,
      action: PayloadAction<{ data: IRecipient[]; pagination: IPagination }>
    ) => {
      state.recipients = action.payload.data;
      state.pagination.getRecipients = action.payload.pagination;
      state.status.getRecipients = "succeeded";
    },
    setRecentRecipients: (
      state: IRecipientState,
      action: PayloadAction<{ data: IRecipient[] }>
    ) => {
      state.recentRecipients = action.payload.data;
      state.status.recentRecipients = "succeeded";
    },
    addRecipientInfo: (
      state: IRecipientState,
      action: PayloadAction<IRecipient>
    ) => {
      const recipients = [...state.recipients];
      recipients.push(action.payload);

      state.recipients = recipients;
      state.status.addRecipient = "succeeded";
    },
    addUSDBeneficiaryBegin: (state) => {
      state.usdBeneficiary.error = "";
      state.usdBeneficiary.loading = true;
    },
    addUSDBeneficiaryFailed: (state, action: PayloadAction<string>) => {
      state.usdBeneficiary.loading = false;
      state.usdBeneficiary.error = action.payload;
    },
    addUSDBeneficiarySuccess: (
      state,
      action: PayloadAction<IUSDBeneficiary[]>
    ) => {
      state.usdBeneficiary.error = "";
      state.usdBeneficiary.loading = false;
      state.usdBeneficiary.data = action.payload;
    }
  }
});

export const actions = RecipientSlice.actions;
export default RecipientSlice.reducer;

import { getExchangeRates } from "app/store";
import { AxiosError } from "axios";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import { IGetExchangeRateV2 } from "data/models/virtual-cards";
import currencyService from "data/services/currency.service";
import LocalStorage from "data/utils/localStorage";
import { useState } from "react";
import toast from "react-hot-toast";
import { useCrypto } from "./use-wallet-functions";
import { useAppDispatch, useAppSelector } from "./useReduxState";

interface IGetExchangeRate {
  amount: string;
  destinationAmount: string;
  rate: number;
}

export const useExchangeRate = (
  amount: number,
  sourceCurrency: string,
  destinationCurrency: string
) => {
  const [rate, setRate] = useState<IGetExchangeRate | null>(null);
  const [inverseRate, setInverseRate] = useState<IGetExchangeRate | null>(null);
  const [isLoading, setLoading] = useState(false);
  const { isCrypto } = useCrypto();

  const exchangeRate = async () => {
    if (!amount) return;

    if (isCrypto(sourceCurrency)) sourceCurrency = "usd";

    setLoading(true);
    try {
      const [rateData, inverseRateData] = (await Promise.all([
        currencyService.getExchangeRate({
          amount,
          sourceCurrency,
          destinationCurrency
        }),
        currencyService.getExchangeRate({
          amount,
          destinationCurrency: sourceCurrency,
          sourceCurrency: destinationCurrency
        })
      ])) as any;

      // (await currencyService.getExchangeRate({
      //   amount,
      //   sourceCurrency,
      //   destinationCurrency
      // })) as any;
      setRate(rateData.data.data);
      setInverseRate(inverseRateData.data.data);
      setLoading(false);
    } catch (err) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      setLoading(false);
      toast.error(msg);
    }
  };

  return {
    rate,
    inverseRate,
    exchangeRate,
    setRate,
    setInverseRate,
    isLoading
  };
};

export const useBidsExchangeRate = () => {
  const [rate, setRate] = useState<any>(null);
  const [isLoading, setLoading] = useState(false);

  const exchangeRate = async (
    type: "buy" | "sell",
    currencies: string[] = []
  ) => {
    setLoading(true);
    try {
      const rateData = await Promise.all(
        currencies.map(async (el) => {
          const res = await currencyService.getExchangeRate({
            amount: 1,
            sourceCurrency: type === "buy" ? el : "usd",
            destinationCurrency: type === "buy" ? "usd" : el
          });

          return { type, currency: el, rate: res.data.data.rate };
        })
      );

      setRate(rateData);
      setLoading(false);
    } catch (err) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      setLoading(false);
      toast.error(msg);
    }
  };

  const exchangeRateV2 = async (
    type: "buy" | "sell",
    currencyPair: string[] = [] //e.g ["ngn-usd"]
  ) => {
    setLoading(true);
    try {
      const rateData = await Promise.all(
        currencyPair.map(async (el) => {
          const [sourceCurrency, destinationCurrency] = el.split("-");
          const res = await currencyService.getExchangeRate({
            amount: 1,
            sourceCurrency,
            destinationCurrency
          });

          return {
            type,
            sourceCurrency,
            destinationCurrency,
            rate: type === "buy" ? 1 / res.data.data.rate : res.data.data.rate
          };
        })
      );

      setRate(rateData);
      setLoading(false);
    } catch (err) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      setLoading(false);
      toast.error(msg);
    }
  };

  return {
    rate,
    setRate,
    isLoading,
    exchangeRate,
    exchangeRateV2
  };
};

export const useExchangeRateV2 = (currencyCode: string) => {
  const [rate, setRate] = useState<IGetExchangeRateV2 | null>(null);
  const [isLoading, setLoading] = useState(false);
  const { isCrypto } = useCrypto();

  const exchangeRate = async () => {
    if (!currencyCode) return;

    if (isCrypto(currencyCode)) currencyCode = "usd";

    setLoading(true);
    try {
      const rateData = await currencyService.getExchangeRateV2({
        currencyCode
      });
      setRate(rateData.data.data);
      setLoading(false);
    } catch (err) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      setLoading(false);
      toast.error(msg);
    }
  };

  return {
    rate,
    exchangeRate,
    setRate,
    isLoading
  };
};

export const useExchangeRateDashboard = () => {
  const [rate] = useAppSelector((state) => [state.misc.exchangeRates]);

  const lastRefreshedKey = "exchangeRateDashboardChangera";

  const dispatch = useAppDispatch();

  const dispatchExchangeRate = async () => {
    dispatch(getExchangeRates());
  };

  const getLastRefreshed = () => {
    return LocalStorage.get(lastRefreshedKey);
  };

  return {
    data: rate.data,
    dispatchExchangeRate,
    isLoading: rate.loading,
    getLastRefreshed
  };
};

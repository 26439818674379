import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AuthenticatedUser,
  DashboardState,
  RecentDashboardActivities
} from "../models/dashboard";

const initialState: DashboardState = {
  userProfile: null,
  loading: false,
  isRecentActivitiesLoading: false,
  dashboardError: false,
  recentActivities: null
};

export const DashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    gettingUserProfile: (state: DashboardState) => {
      state.loading = true;
    },
    settingUser: (
      state: DashboardState,
      action: PayloadAction<AuthenticatedUser>
    ) => {
      state.userProfile = action.payload;
      state.loading = false;
    },
    fetchRecentActivities: (state: DashboardState) => {
      state.isRecentActivitiesLoading = true;
    },
    setRecentActivities: (
      state: DashboardState,
      action: PayloadAction<RecentDashboardActivities>
    ) => {
      state.recentActivities = action.payload;
      state.isRecentActivitiesLoading = false;
    },
    errRecentActivities: (state: DashboardState) => {
      state.isRecentActivitiesLoading = false;
      state.dashboardError = true;
    },
    dashboardError: (state: DashboardState) => {
      state.loading = false;
      state.dashboardError = true;
      state.isRecentActivitiesLoading = false;
    },
    updateUserProfileImage: (
      state: DashboardState,
      action: PayloadAction<string>
    ) => {
      if (state.userProfile) state.userProfile.image = action.payload;
    }
  }
});

export const actions = DashboardSlice.actions;

export default DashboardSlice.reducer;

import {
  IChangeraUser,
  PaySomeoneState,
  PaySomeoneError,
  IGetExchangeRate,
  PaySomeoneLoading,
  PaySomeoneModalState,
  PaySomeoneDrawerState
} from "../models/pay-someone";
import { IBusiness } from "../models/business";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: PaySomeoneState = {
  fee: 0,
  error: null,
  status: {
    paySomeone: "idle",
    getExchangeRate: "idle",
    getPaySomeoneFee: "idle"
  },
  drawer: {
    fee: 0,
    amount: 0,
    isOpen: false,
    view: "method",
    wallet: undefined,
    recipient: undefined,
    method: "bank-account"
  },
  modal: { isOpen: false, view: "transaction-pin" },
  exchangeRate: { amount: 0, destinationAmount: 0, rate: 0 },
  getPaySomeoneBusinessRecipient: {
    error: "",
    data: undefined,
    isLoading: false
  },
  getPaySomeonePersonalRecipient: {
    error: "",
    data: undefined,
    isLoading: false
  }
};

export const PaySomeoneSlice = createSlice({
  initialState,
  name: "pay-someone",
  reducers: {
    loading: (
      state: PaySomeoneState,
      action: PayloadAction<PaySomeoneLoading>
    ) => {
      state.status[action.payload] = "loading";
    },

    success: (state: PaySomeoneState, action: PayloadAction<any>) => {
      state.success = action.payload;
      state.status.paySomeone = "succeeded";
    },

    resetState: (state: PaySomeoneState) => {
      state.fee = initialState.fee;
      state.modal = initialState.modal;
      state.drawer = initialState.drawer;
      state.status = initialState.status;
      state.exchangeRate = initialState.exchangeRate;
    },

    resetDrawerState: (state: PaySomeoneState) => {
      state.drawer = initialState.drawer;
    },

    setModalState: (
      state: PaySomeoneState,
      action: PayloadAction<Partial<PaySomeoneModalState>>
    ) => {
      state.modal.isOpen =
        action.payload.isOpen !== undefined
          ? action.payload.isOpen
          : state.modal.isOpen;

      state.modal.view = action.payload.view || state.modal.view;
    },

    setDrawerState: (
      state: PaySomeoneState,
      action: PayloadAction<Partial<PaySomeoneDrawerState>>
    ) => {
      state.drawer = { ...state.drawer, ...action.payload };
    },

    setFee: (state: PaySomeoneState, action: PayloadAction<number>) => {
      state.fee = action.payload;
      state.status.getPaySomeoneFee = "succeeded";
    },

    setExchangeRate: (
      state: PaySomeoneState,
      action: PayloadAction<IGetExchangeRate>
    ) => {
      state.exchangeRate = action.payload;
      state.status.getExchangeRate = "succeeded";
    },

    error: (state: PaySomeoneState, action: PayloadAction<PaySomeoneError>) => {
      state.error = action.payload.msg;
      state.status[action.payload.for] = "failed";
    },

    getPaySomeoneBusinessRecipientError: (
      state: PaySomeoneState,
      action: PayloadAction<string>
    ) => {
      state.getPaySomeoneBusinessRecipient.data = undefined;
      state.getPaySomeoneBusinessRecipient.isLoading = false;
      state.getPaySomeoneBusinessRecipient.error = action.payload;
    },

    getPaySomeonePersonalRecipientError: (
      state: PaySomeoneState,
      action: PayloadAction<string>
    ) => {
      state.getPaySomeonePersonalRecipient.data = undefined;
      state.getPaySomeonePersonalRecipient.isLoading = false;
      state.getPaySomeonePersonalRecipient.error = action.payload;
    },

    getPaySomeonePersonalRecipientLoading: (state: PaySomeoneState) => {
      state.getPaySomeonePersonalRecipient.isLoading = true;
      state.getPaySomeonePersonalRecipient.data = undefined;
    },

    getPaySomeoneBusinessRecipientLoading: (state: PaySomeoneState) => {
      state.getPaySomeoneBusinessRecipient.isLoading = true;
      state.getPaySomeoneBusinessRecipient.data = undefined;
    },

    getPaySomeoneBusinessRecipientSuccess: (
      state: PaySomeoneState,
      action: PayloadAction<IBusiness>
    ) => {
      state.getPaySomeoneBusinessRecipient.error = "";
      state.getPaySomeoneBusinessRecipient.isLoading = false;
      state.getPaySomeoneBusinessRecipient.data = action.payload;
    },

    getPaySomeonePersonalRecipientSuccess: (
      state: PaySomeoneState,
      action: PayloadAction<IChangeraUser>
    ) => {
      state.getPaySomeonePersonalRecipient.error = "";
      state.getPaySomeonePersonalRecipient.isLoading = false;
      state.getPaySomeonePersonalRecipient.data = action.payload;
    }
  }
});

export const actions = PaySomeoneSlice.actions;

export default PaySomeoneSlice.reducer;

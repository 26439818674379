import { GetCurrencyExchangeRateBody } from "app/store/models/currency";
import { IGetExchangeRate } from "app/store/models/send-money";

import { AxiosResponse } from "axios";
import instance, { instanceV2 } from "data/axios-setup";
import { IGetExchangeRateV2 } from "data/models/virtual-cards";

class CurrencyService {
  async getCurrencyExchangeRate(
    body: GetCurrencyExchangeRateBody
  ): Promise<AxiosResponse<{ data: number; message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post("/exchange-rate/rate", body);
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getExchangeRate(params: {
    amount: number;
    sourceCurrency: string;
    destinationCurrency: string;
  }): Promise<AxiosResponse<{ data: IGetExchangeRate; message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get("/exchange-rate", { params });
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  // new exchange rate
  async getExchangeRateV2(params: {
    currencyCode: string;
  }): Promise<AxiosResponse<{ data: IGetExchangeRateV2; message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instanceV2.get("/exchange-rate", { params });
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new CurrencyService();

import {
  IMobileMoneyWallet,
  IMobileMoneyWalletState,
  IMobileMonetWalletError,
  TMobileMoneyWalletLoading,
  TMobileMoneyWalletView,
  IMobileMoneyModalState
} from "../models/mobile-money-wallet";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: IMobileMoneyWalletState = {
  error: null,
  wallets: [],
  view: "details",
  modal: { isOpen: false, view: "delete" },
  status: {
    addWallet: "idle",
    getWallets: "idle",
    deleteWallet: "idle"
  }
};

export const MobileMoneyWalletSlice = createSlice({
  initialState,
  name: "mobile-money-wallet",
  reducers: {
    idle: (
      state: IMobileMoneyWalletState,
      action: PayloadAction<TMobileMoneyWalletLoading>
    ) => {
      state.status[action.payload] = "idle";
    },
    loading: (
      state: IMobileMoneyWalletState,
      action: PayloadAction<TMobileMoneyWalletLoading>
    ) => {
      state.status[action.payload] = "loading";
    },
    error: (
      state: IMobileMoneyWalletState,
      action: PayloadAction<IMobileMonetWalletError>
    ) => {
      state.error = action.payload.msg;
      state.status[action.payload.for] = "failed";
    },
    setWalletsInfo: (
      state: IMobileMoneyWalletState,
      action: PayloadAction<IMobileMoneyWallet[]>
    ) => {
      state.wallets = action.payload;
      state.status.getWallets = "succeeded";
    },
    deleteWallet: (
      state: IMobileMoneyWalletState,
      action: PayloadAction<number>
    ) => {
      const wallets = state.wallets.filter((w) => w.id !== action.payload);

      state.wallets = wallets;
      state.modal = initialState.modal;
      state.status.deleteWallet = "idle";
    },
    addWalletInfo: (
      state: IMobileMoneyWalletState,
      action: PayloadAction<IMobileMoneyWallet>
    ) => {
      state.status.addWallet = "succeeded";
      state.wallets = [...state.wallets, action.payload];
    },
    setView: (
      state: IMobileMoneyWalletState,
      action: PayloadAction<TMobileMoneyWalletView>
    ) => {
      state.view = action.payload;
    },
    setModal: (
      state: IMobileMoneyWalletState,
      action: PayloadAction<IMobileMoneyModalState>
    ) => {
      state.modal = { ...state.modal, ...action.payload };
    },
    resetModal: (state: IMobileMoneyWalletState) => {
      state.modal = initialState.modal;
    }
  }
});

export const actions = MobileMoneyWalletSlice.actions;
export default MobileMoneyWalletSlice.reducer;

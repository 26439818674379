import {
  USDWithdrawalBody,
  TCryptoWithdrawalNetworkFees,
  SubWalletWithdrawalRequestData,
  FiatWalletWithdrawalRequestBody,
  CryptoWalletWithdrawalRequestBody
} from "app/store/models/withdrawal";
import { AxiosResponse } from "axios";
import instance from "data/axios-setup";
import { getCallSign } from "data/utils";
import { IPagination } from "data/models/base";

class WalletService {
  async fiatWalletWithdrawal(
    body: FiatWalletWithdrawalRequestBody
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const callSign = getCallSign();
        const response = await instance.post(`/${callSign}/withdrawal`, body);

        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async cryptoWalletWithdrawal(
    body: CryptoWalletWithdrawalRequestBody
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const callSign = getCallSign();
        const response = await instance.post(
          `/${callSign}/withdrawal/crypto`,
          body
        );

        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async subWalletWithdrawal(
    data: SubWalletWithdrawalRequestData
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/withdrawal/${data.subWalletId}/${data.bankId}/withdraw-sub-wallet`,
          { amount: data.amount, transactionPin: data.transactionPin }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getAllWithdrawals(
    params?: any
  ): Promise<
    AxiosResponse<{ data: any[]; message: string; pagination: IPagination }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const callSign = getCallSign();
        const response = await instance.get(`/${callSign}/withdrawal`, {
          params
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getWithdrawalFee(
    callSign: string,
    params?: any
  ): Promise<AxiosResponse<{ data: number; message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/${callSign}/withdrawal-fee`, {
          params
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getCryptoWithdrawalNetworkFee(
    body: TCryptoWithdrawalNetworkFees
  ): Promise<AxiosResponse<{ data: number; message: string }>> {
    return new Promise(async (resolve, reject) => {
      const { token, amount, address, network, callSign } = body;
      try {
        const response = await instance.get(
          `/${callSign}/withdrawal/crypto-network-fees/${token}/${amount}/${address}/${network}`
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getSubBusinessCryptoWithdrawalNetworkFee(
    body: TCryptoWithdrawalNetworkFees
  ): Promise<AxiosResponse<{ data: number; message: string }>> {
    return new Promise(async (resolve, reject) => {
      const { token, amount, address, network, callSign } = body;
      try {
        const response = await instance.get(
          `/${callSign}/sub-business/withdrawal/crypto-network-fees/${token}/${amount}/${address}/${network}`
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getSubBusinessWithdrawals(
    callSign: string,
    params?: any
  ): Promise<
    AxiosResponse<{ data: any[]; message: string; pagination: IPagination }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/${callSign}/sub-business/withdrawal`,
          { params }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getSubBusinessWithdrawalFee(
    callSign: string,
    params?: any
  ): Promise<AxiosResponse<{ data: number; message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/${callSign}/sub-business/withdrawal/fee`,
          { params }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async subBusinessCryptoWalletWithdrawal(
    callSign: string,
    body: CryptoWalletWithdrawalRequestBody
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/${callSign}/sub-business/withdrawal/crypto`,
          body
        );

        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async usdWalletWithdrawal(
    body: USDWithdrawalBody
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const callSign = getCallSign();
        const response = await instance.post(
          `/${callSign}/withdrawal/usd`,
          body
        );

        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new WalletService();

import {
  CurrencyError,
  CurrencyState,
  CurrencyLoading
} from "../models/currency";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: CurrencyState = {
  rate: 0,
  data: {},
  error: null,
  status: {
    getExchangeRate: "idle"
  }
};

export const CurrencySlice = createSlice({
  initialState,
  name: "currency",
  reducers: {
    loading: (state: CurrencyState, action: PayloadAction<CurrencyLoading>) => {
      state.status[action.payload] = "loading";
    },
    error: (state: CurrencyState, action: PayloadAction<CurrencyError>) => {
      state.error = action.payload.msg;
      state.status[action.payload.for] = "failed";
    },
    setCurrencyExchangeRate: (
      state: CurrencyState,
      action: PayloadAction<number>
    ) => {
      state.rate = action.payload;
      state.status.getExchangeRate = "idle";
    },
    setExchangeRate: (state: CurrencyState, action: PayloadAction<any>) => {
      state.data = action.payload;
    }
  }
});

export const actions = CurrencySlice.actions;
export default CurrencySlice.reducer;

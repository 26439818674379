import { useEffect, useState } from "react";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import theme from "app/theme/chakraTheme";
import { Provider } from "react-redux";
import { persistor, store } from "app/store";
import Loading, { LoadingNoBg } from "views/components/loading";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
// import { IntercomProvider } from "react-use-intercom";
import ErrorView from "views/components/Error/no-connection";
import Routes from "app/routes/routes";
import { useSupportV2 } from "app/hooks";
import { configs } from "data/config";

const App = (): JSX.Element => {
  // const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID as string;

  const [appState, setAppState] = useState({
    noInternetConnection: false
  });

  const handleInternetConnectionChange = () => {
    navigator.onLine
      ? setAppState({ noInternetConnection: false })
      : setAppState({ noInternetConnection: true });
  };

  useEffect(() => {
    handleInternetConnectionChange();
    window.addEventListener("online", handleInternetConnectionChange);
    window.addEventListener("offline", handleInternetConnectionChange);
  }, []);

  const { noInternetConnection } = appState;
  if (noInternetConnection) {
    return (
      <ChakraProvider theme={theme}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <ErrorView text="No Internet Connnection" />
      </ChakraProvider>
    );
  }

  const { bootCharlaWidget } = useSupportV2();

  useEffect(() => {
    if (configs.NODE_ENV !== "production") return;
    bootCharlaWidget();
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate loading={<Loading />} persistor={persistor}>
          <ChakraProvider theme={theme}>
            <ColorModeScript initialColorMode={theme.config.initialColorMode} />
            {/* <IntercomProvider
              autoBoot
              appId={intercomAppId}
              autoBootProps={{ hideDefaultLauncher: false }}
            > */}

            <Routes />
            {/* </IntercomProvider> */}
            <LoadingNoBg />
            <Toaster />
          </ChakraProvider>
        </PersistGate>
      </BrowserRouter>
    </Provider>
  );
};

export default App;

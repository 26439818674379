import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IEurFee,
  VirtualAccountResData,
  VirtualAccountState
} from "../models/virtual-accounts";

const initialState: VirtualAccountState = {
  allVirtualAccounts: {
    data: [],
    loading: false,
    error: ""
  },
  subBusiness: {
    allVirtualAccounts: {
      data: [],
      loading: false,
      error: ""
    }
  },
  creatingVBA: false,
  eurVbaFee: {
    loading: false,
    fee: null
  },
  usdFee: {
    loading: false,
    fee: null
  }
};

export const VirtualAccountSlice = createSlice({
  name: "virtual-accounts",
  initialState,
  reducers: {
    getAllVirtualAccountsBegin: (state) => {
      state.allVirtualAccounts.loading = true;
      state.allVirtualAccounts.error = "";
      state.subBusiness.allVirtualAccounts.loading = true;
      state.subBusiness.allVirtualAccounts.error = "";
    },
    getAllVirtualAccountsSuccess: (
      state,
      action: PayloadAction<VirtualAccountResData[]>
    ) => {
      state.allVirtualAccounts.data = action.payload;
      state.allVirtualAccounts.loading = false;
      state.allVirtualAccounts.error = "";
    },
    getAllVirtualAccountsError: (state, action: PayloadAction<string>) => {
      state.allVirtualAccounts.loading = false;
      state.allVirtualAccounts.error = action.payload;
      state.subBusiness.allVirtualAccounts.loading = false;
      state.subBusiness.allVirtualAccounts.error = action.payload;
      state.eurVbaFee.loading = false;
    },
    getSubBusinessVirtualAccountsSuccess: (
      state,
      action: PayloadAction<VirtualAccountResData[]>
    ) => {
      state.subBusiness.allVirtualAccounts.error = "";
      state.subBusiness.allVirtualAccounts.loading = false;
      state.subBusiness.allVirtualAccounts.data = action.payload;
    },
    createVBA: (state, action: PayloadAction<boolean>) => {
      state.creatingVBA = action.payload;
    },
    getAllVirtualAccountsFee: (state, action: PayloadAction<boolean>) => {
      state.eurVbaFee.loading = action.payload;
      state.usdFee.loading = action.payload;
    },
    getEurFeeSuccess: (state, action: PayloadAction<IEurFee>) => {
      state.eurVbaFee.loading = false;
      state.eurVbaFee.fee = action.payload;
    },
    getUsdFeeSuccess: (state, action: PayloadAction<IEurFee>) => {
      state.usdFee.loading = false;
      state.usdFee.fee = action.payload;
    }
  }
});

export const actions = VirtualAccountSlice.actions;

export default VirtualAccountSlice.reducer;

import CryptoJS from "crypto-js";
import { configs } from "data/config";

const secret = configs.AES_SECRET_KEY as string;

export const encryptKey = (data: string) =>
  CryptoJS.AES.encrypt(data, secret).toString();

export const decryptKey = (key: string) => {
  var bytes = CryptoJS.AES.decrypt(key, secret);
  var originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

import {
  IAddRecipientBody,
  TRecipientLoading,
  IGetRecipientsParams,
  IAddUSDBeneficiaryBody
} from "../models/recipient.model";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/recipient.reducer";
import recipientService from "data/services/recipient.service";
import { DEFAULT_ERROR_MESSAGE, onResponseSuccess } from "data/error-mapping";

export const getRecipients = (params: IGetRecipientsParams) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getRecipients"));
      const res = await recipientService.getRecipients(params);
      onResponseSuccess(res, () => {
        dispatch(actions.setRecipientsInfo(res.data));
      });
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "getRecipients" }));
    }
  };
};

export const getRecentRecipients = (params: IGetRecipientsParams) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("recentRecipients"));
      const res = await recipientService.getRecipients(params);
      onResponseSuccess(res, () => {
        dispatch(actions.setRecentRecipients(res.data));
      });
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "recentRecipients" }));
    }
  };
};

export const addRecipient = (body: IAddRecipientBody, cb?: () => void) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("addRecipient"));
      const res = await recipientService.addRecipient(body);

      onResponseSuccess(res, () => {
        dispatch(actions.addRecipientInfo(res.data.data));
        toast.success(res.data.message);
        !!cb && cb();
      });
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "addRecipient" }));
    }
  };
};

export const addUSDBeneficiary = (
  body: IAddUSDBeneficiaryBody,
  cb?: () => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.addUSDBeneficiaryBegin());
      const res = await recipientService.addUSDBeneficiary(body);

      onResponseSuccess(res, () => {
        dispatch(actions.addUSDBeneficiarySuccess(res.data.data));
        toast.success(res.data.message);
        !!cb && cb();
      });
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.addUSDBeneficiaryFailed(msg));
    }
  };
};

export const setRecipientIdleStatus = (value: TRecipientLoading) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.idle(value));
  };
};

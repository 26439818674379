import { getToken } from "data/utils";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  email?: string;
  inviteToken: string;
  isLoggedIn: boolean;
  isRegistered: boolean;
  loading: boolean;
  isLoading2FA: boolean;
  isLoadingWebAuthn: boolean;
  errorMsg: string;
  error: boolean;
  passwordResetLintSent: boolean;
  passwordResetSuccessfull: boolean;
  signUpSuccessful: boolean;
  gettingCode: boolean;
  emailCodeGotten: boolean;
  phoneCodeGotten: boolean;
  phoneVerified: boolean;
  emailVerified: boolean;
}

const initialState: AuthState = {
  inviteToken: "",
  isLoggedIn: !!getToken(),
  isLoading2FA: false,
  isLoadingWebAuthn: false,
  isRegistered: false,
  loading: false,
  errorMsg: "",
  error: false,
  passwordResetLintSent: false,
  passwordResetSuccessfull: false,
  signUpSuccessful: false,
  gettingCode: false,
  emailCodeGotten: false,
  phoneCodeGotten: false,
  phoneVerified: false,
  emailVerified: false
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loadingBegin: (state: AuthState) => {
      state.loading = true;
    },
    loading2FA: (state: AuthState, action: PayloadAction<boolean>) => {
      state.isLoading2FA = action.payload;
    },
    loadingWebAuthn: (state: AuthState, action: PayloadAction<boolean>) => {
      state.isLoadingWebAuthn = action.payload;
    },
    loadingStop: (state: AuthState) => {
      state.loading = false;
    },
    error: (state: AuthState) => {
      state.loading = false;
      state.gettingCode = false;
      state.isLoading2FA = false;
      state.isLoadingWebAuthn = false;
      state.error = true;
    },
    loginSuccess: (state: AuthState, action: PayloadAction<boolean>) => {
      state.loading = false;
      state.isLoggedIn = action.payload;
      state.error = false;
    },
    saveLoginEmail: (
      state: AuthState,
      action: PayloadAction<{ email: string; token: any }>
    ) => {
      state.email = action.payload.email;
      state.inviteToken = action.payload.token;
    },
    forgetPasswordSuccess: (state: AuthState) => {
      state.passwordResetLintSent = true;
      state.loading = false;
      state.error = false;
    },
    resetPasswordSuccess: (state: AuthState) => {
      state.passwordResetSuccessfull = true;
      state.loading = false;
      state.error = false;
    },
    registerSuccess: (state: AuthState, action: PayloadAction<boolean>) => {
      state.signUpSuccessful = true;
      state.loading = false;
      state.isRegistered = action.payload;
      state.error = false;
    },

    GettingPhoneCode: (state: AuthState) => {
      state.gettingCode = true;
    },
    PhoneCodeGotten: (state: AuthState) => {
      state.phoneCodeGotten = true;
      state.gettingCode = false;
      state.error = false;
    },
    EmailCodeGotten: (state: AuthState) => {
      state.emailCodeGotten = true;
      state.loading = false;
      state.error = false;
    },
    phoneVerified: (state: AuthState) => {
      state.phoneVerified = true;
      state.gettingCode = false;
      state.error = false;
    },
    emailVerified: (state: AuthState) => {
      state.emailVerified = true;
      state.loading = false;
      state.error = false;
    },
    logoutBegin: (state: AuthState) => {
      state.loading = true;
    },
    logoutSuccess: (state: AuthState) => {
      state.loading = false;
      state.isLoggedIn = false;
      state.error = false;
    },
    success: (state: AuthState) => {
      state.loading = false;
      state.isLoading2FA = false;
      state.isLoadingWebAuthn = false;
    }
  }
});

export const {
  loadingBegin,
  error,
  loginSuccess,
  logoutBegin,
  logoutSuccess,
  loadingStop,
  registerSuccess,
  forgetPasswordSuccess,
  resetPasswordSuccess,
  GettingPhoneCode,
  EmailCodeGotten,
  PhoneCodeGotten,
  phoneVerified,
  emailVerified,
  success,
  saveLoginEmail,
  loading2FA,
  loadingWebAuthn
} = AuthSlice.actions;

export default AuthSlice.reducer;

import {
  CardType,
  CardError,
  CardState,
  CardDrawerState,
  CardModalState,
  ISIngleCard,
  VirtualCardBrand
} from "../models/card";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IFee,
  IGetCardCreationFees,
  IGetCardFundingFees,
  VirtualCard
} from "data/models/virtual-cards";
import { IPagination } from "data/models/base";

const pagination = {
  next: 0,
  total: 0,
  prevPage: 0,
  lastPage: 0,
  pageSize: 10,
  hasNext: false,
  currentPage: 1,
  hasPrevious: false
};

const initialState: CardState = {
  error: null,
  drawer: { isOpen: false, cardBrand: "" },
  createCard: { type: "virtual", currency: "" },
  status: {
    fundCard: "idle",
    createCard: "idle",
    withdrawFund: "idle",
    reactivateCard: "idle",
    deactivateCard: "idle"
  },

  modal: {
    isOpen: false,
    type: "virtual",
    action: "deactivate",
    view: "confirm-deactivate"
  },

  allVirtualCards: {
    loading: false,
    error: "",
    data: [],
    pagination: { ...pagination, pageSize: 5 }
  },

  singleVirtualCard: {
    loading: false,
    error: "",
    data: null
  },

  cardCreationFees: {
    loading: false,
    error: "",
    data: null
  },

  cardFundingFees: {
    loading: false,
    error: "",
    data: null
  },

  fees: { creation: null, error: "", funding: null, loading: false }
};

export const CardSlice = createSlice({
  initialState,
  name: "card",
  reducers: {
    resetCardState: (state: CardState) => {
      state.error = initialState.error;
      state.modal = initialState.modal;
      state.drawer = initialState.drawer;
      state.status = initialState.status;
      state.createCard = initialState.createCard;
    },

    setDrawerState: (
      state: CardState,
      action: PayloadAction<CardDrawerState & { type?: CardType }>
    ) => {
      state.drawer = { ...state.drawer, ...action.payload };
    },

    setCreateCardCurrency: (
      state: CardState,
      action: PayloadAction<string>
    ) => {
      state.createCard.currency = action.payload;
    },

    setModalState: (
      state: CardState,
      action: PayloadAction<Partial<CardModalState>>
    ) => {
      state.status = initialState.status;
      state.modal = { ...state.modal, ...action.payload };
    },

    acceptCardTerms: (state: CardState, action: PayloadAction<any>) => {
      state.acceptCardTerms = action.payload;
    },

    resetDrawerState: (state: CardState) => {
      state.acceptCardTerms = false;
      state.drawer = initialState.drawer;
      state.status = initialState.status;
    },

    resetModalState: (state: CardState) => {
      state.modal = initialState.modal;
    },

    error: (state: CardState, action: PayloadAction<CardError>) => {
      state.error = action.payload.msg;
      state.status[action.payload.for] = "failed";
    },

    createCard: (state: CardState) => {
      state.modal.isOpen = true;
      state.modal.view = "createSuccess";
      state.status.createCard = "succeeded";
    },

    withdrawFund: (state: CardState) => {
      state.modal.isOpen = true;
      state.modal.view = "withdrawFundSuccess";
      state.status.withdrawFund = "succeeded";
    },

    liquidateCard: (state: CardState) => {
      state.modal.isOpen = true;
      state.modal.view = "liquidateSuccess";
      state.status.withdrawFund = "succeeded";
    },

    fundCard: (state: CardState) => {
      state.modal.isOpen = true;
      state.modal.view = "fundSuccess";
      state.status.fundCard = "succeeded";
    },

    setDeactivateCard: (state: CardState) => {
      state.modal.view = "deactivateSuccess";
      state.status.deactivateCard = "succeeded";
    },

    setReactivateCard: (state: CardState) => {
      state.modal.view = "reactivateSuccess";
      state.status.reactivateCard = "succeeded";
    },

    // API reducers
    getAllVirtualCardsBegin: (state) => {
      state.allVirtualCards.loading = true;
      state.allVirtualCards.error = "";
    },

    getAllVirtualCardsSuccess: (
      state,
      action: PayloadAction<{ data: VirtualCard[]; pagination: IPagination }>
    ) => {
      state.allVirtualCards.data = action.payload.data;
      state.allVirtualCards.loading = false;
      state.allVirtualCards.error = "";
      state.allVirtualCards.pagination = action.payload.pagination;
    },

    getAllVirtualCardsError: (
      state,

      action: PayloadAction<string>
    ) => {
      state.allVirtualCards.loading = false;
      state.allVirtualCards.error = action.payload;
    },

    getSingleVirtualCardBegin: (state) => {
      state.singleVirtualCard.loading = true;
      state.singleVirtualCard.error = "";
    },

    getSingleVirtualCardSuccess: (
      state,
      action: PayloadAction<{ data: ISIngleCard }>
    ) => {
      state.singleVirtualCard.data = action.payload.data;
      state.singleVirtualCard.loading = false;
      state.singleVirtualCard.error = "";
    },

    getSingleVirtualCardError: (state, action: PayloadAction<string>) => {
      state.singleVirtualCard.loading = false;
      state.singleVirtualCard.error = action.payload;
    },

    getCardCreationFeesBegin: (state) => {
      state.cardCreationFees.loading = true;
      state.cardCreationFees.error = "";
    },

    getCardCreationFeesSuccess: (
      state,
      action: PayloadAction<{ data: IGetCardCreationFees }>
    ) => {
      state.cardCreationFees.data = action.payload.data;
      state.cardCreationFees.loading = false;
      state.cardCreationFees.error = "";
    },

    getCardCreationFeesError: (state, action: PayloadAction<string>) => {
      state.cardCreationFees.loading = false;
      state.cardCreationFees.error = action.payload;
    },

    getCardCreationFeesReset: (state) => {
      state.cardCreationFees.loading = false;
      state.cardCreationFees.error = "";
      state.cardCreationFees.data = null;
    },

    getCardFundingFeesBegin: (state) => {
      state.cardFundingFees.loading = true;
      state.cardFundingFees.error = "";
    },

    getCardFundingFeesSuccess: (
      state,
      action: PayloadAction<{ data: IGetCardFundingFees }>
    ) => {
      state.cardFundingFees.data = action.payload.data;
      state.cardFundingFees.loading = false;
      state.cardFundingFees.error = "";
    },

    getCardFundingFeesError: (state, action: PayloadAction<string>) => {
      state.cardFundingFees.loading = false;
      state.cardFundingFees.error = action.payload;
    },

    getCardFundingFeesReset: (state) => {
      state.cardFundingFees.loading = false;
      state.cardFundingFees.error = "";
      state.cardFundingFees.data = null;
    },

    getCardFeesBegin: (state) => {
      state.fees.error = "";
      state.fees.loading = true;
    },

    getCardFeesSuccess: (
      state,
      action: PayloadAction<{ creation: IFee; funding: IFee }>
    ) => {
      state.fees.error = "";
      state.fees.loading = false;
      state.fees.creation = action.payload.creation;
      state.fees.funding = action.payload.funding;
    },

    getCardFeesError: (state, action: PayloadAction<string>) => {
      state.fees.loading = false;
      state.fees.error = action.payload;
    },

    setCardState: (state, action: PayloadAction<VirtualCardBrand>) => {
      state.drawer.cardBrand = action.payload;
    }
  }
});

export const actions = CardSlice.actions;

export default CardSlice.reducer;

import { AxiosResponse } from "axios";
import instance, { instanceV2 } from "data/axios-setup";
import {
  SubWalletDataModel,
  GetSingleWalletResponse,
  fundSubWalletRequestBody,
  GetAllFiatWalletResponse,
  GetAllCryptoWalletResponse,
  CreateSubWalletRequestBody,
  fundWalletWithCardRequestBody,
  GetAllSubBusinessFiatWalletResponse
} from "app/store/models/wallet";
import {
  CreateCryptoWalletRequestBody,
  CreateFiatWalletRequestBody
} from "views/containers/wallet/create-wallet/models";
import { getCallSign } from "data/utils";

class WalletService {
  async getAllFiatWallets(): Promise<
    AxiosResponse<{ data: GetAllFiatWalletResponse[] }>
  > {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/${callSign}/wallets`);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getAllCryptoWallets(): Promise<
    AxiosResponse<{ data: GetAllCryptoWalletResponse[] }>
  > {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/${callSign}/crypto-wallet`);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getAllCryptoWalletsV2(): Promise<
    AxiosResponse<{ data: GetAllCryptoWalletResponse[] }>
  > {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instanceV2.get(`/${callSign}/crypto-wallet`);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async createFiatWallet(
    body: CreateFiatWalletRequestBody
  ): Promise<AxiosResponse<{ data: any }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(`/${callSign}/fiat-wallet`, body);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async createCryptoWallet(
    body: CreateCryptoWalletRequestBody
  ): Promise<AxiosResponse<{ data: any }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/${callSign}/crypto-wallet`,
          body
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async createCryptoWalletV2(
    body: CreateCryptoWalletRequestBody
  ): Promise<AxiosResponse<{ data: any }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instanceV2.post(
          `/${callSign}/crypto-wallet`,
          body
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getSingleFiatWallet(
    currency: string
  ): Promise<AxiosResponse<{ data: GetSingleWalletResponse }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/${callSign}/wallet`, {
          params: { currency: currency }
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getSingleCryptoWallet(
    params: CreateCryptoWalletRequestBody
  ): Promise<AxiosResponse<{ data: GetSingleWalletResponse }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/${callSign}/get-crypto-wallet`, {
          params
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getSingleCryptoWalletV2(
    params: CreateCryptoWalletRequestBody
  ): Promise<AxiosResponse<{ data: GetSingleWalletResponse }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instanceV2.get(
          `/${callSign}/get-crypto-wallet`,
          {
            params
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async createSubWallet(
    body: CreateSubWalletRequestBody
  ): Promise<AxiosResponse<{ data: SubWalletDataModel }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(`/${callSign}/sub-wallet`, body);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async fundSubWallet(
    body: fundSubWalletRequestBody,
    walletId: number,
    subWalletId: number
  ): Promise<AxiosResponse<{ message: string }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/${callSign}/${walletId}/sub-wallet/${subWalletId}`,
          body
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async fundWalletWithCard(
    body: fundWalletWithCardRequestBody
  ): Promise<AxiosResponse<{ message: string }>> {
    const callSign = getCallSign();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(`${callSign}/fund-wallet`, body);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getAllSubBusinessFiatWallets(
    callSign: string
  ): Promise<AxiosResponse<{ data: GetAllSubBusinessFiatWalletResponse[] }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/${callSign}/sub-business/fiat-wallets`
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getAllSubBusinessCryptoWallets(
    callSign: string
  ): Promise<AxiosResponse<{ data: GetAllCryptoWalletResponse[] }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/${callSign}/sub-business/crypto-wallets`
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async createSubBusinessFiatWallet(
    callSign: string,
    body: CreateFiatWalletRequestBody
  ): Promise<AxiosResponse<{ data: any }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/${callSign}/sub-business/fiat-wallets`,
          body
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async createSubBusinessCryptoWallet(
    callSign: string,
    body: CreateCryptoWalletRequestBody
  ): Promise<AxiosResponse<{ data: any }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/${callSign}/sub-business/crypto-wallets`,
          body
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getSubBusinessSingleFiatWallet(
    callSign: string,
    currency: string
  ): Promise<AxiosResponse<{ data: GetSingleWalletResponse }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/${callSign}/sub-business/fiat-wallet`,
          {
            params: { currency: currency }
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getSingleSubBusinessCryptoWallet(
    callSign: string,
    params: CreateCryptoWalletRequestBody
  ): Promise<AxiosResponse<{ data: GetSingleWalletResponse }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/${callSign}/sub-business/crypto-wallet`,
          {
            params
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new WalletService();

import { logOut } from "app/store";
import { AxiosError } from "axios";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import authService from "data/services/auth.service";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "./useReduxState";
import toast from "react-hot-toast";
import { removeCallSign } from "data/utils";
import { useSupport } from "./use-support";
import { configs } from "data/config";

/**
 * Custom hook to handler logging a user out
 * @returns function `logoutHandler`
 */
export const useLogoutFunction = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { shutdown } = useSupport();

  const logoutHandler = async () => {
    try {
      await authService.logout();
      removeCallSign();

      if (configs.NODE_ENV !== "production") {
        shutdown(); // close chatbot when a user logs out
      }

      dispatch(logOut(navigate));
    } catch (err) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
    }
  };

  return logoutHandler;
};

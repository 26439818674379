import {
  WalletState,
  SubWalletDataModel,
  GetSingleWalletResponse,
  CreateCryptoWalletParams,
  GetAllFiatWalletResponse,
  GetAllCryptoWalletResponse,
  GetAllSubBusinessFiatWalletResponse
} from "../models/wallet";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: WalletState = {
  fiatWallets: {
    loading: false,
    data: [],
    error: ""
  },
  cryptoWallets: {
    loading: false,
    data: [],
    error: ""
  },
  singleWallet: {
    loading: false,
    data: null,
    error: ""
  },
  setFiatWallet: {},
  selectedSubWallet: null,
  createdSubWallet: null,
  isFundWalletModalOpen: "",
  isSelectFundWalletModalOpen: false,
  isCreateSubWalletStatus: "",
  selectedFiatWallet: "",
  selectedCryptoWallet: null,
  isShowFundSubWallet: false,

  subBusiness: {
    selectedFiatWallet: "",
    selectedCryptoWallet: null,
    fiatWallets: { loading: false, data: [], error: "" },
    cryptoWallets: { loading: false, data: [], error: "" },
    singleWallet: { loading: false, data: null, error: "" }
  }
};

export const WalletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    fetchFiatWalletsBegin: (state: WalletState) => {
      state.fiatWallets.loading = true;
      state.fiatWallets.error = "";
    },
    fetchFiatWalletsSuccess: (
      state: WalletState,
      action: PayloadAction<GetAllFiatWalletResponse[]>
    ) => {
      state.fiatWallets.data = action.payload;
      state.fiatWallets.loading = false;
      state.fiatWallets.error = "";
    },
    fetchFiatWalletsError: (
      state: WalletState,
      action: PayloadAction<string>
    ) => {
      state.fiatWallets.loading = false;
      state.fiatWallets.error = action.payload;
    },
    fetchCryptoWalletsBegin: (state: WalletState) => {
      state.cryptoWallets.loading = true;
      state.cryptoWallets.error = "";
    },
    fetchCryptoWalletsSuccess: (
      state: WalletState,
      action: PayloadAction<GetAllCryptoWalletResponse[]>
    ) => {
      state.cryptoWallets.data = action.payload;
      state.cryptoWallets.loading = false;
      state.cryptoWallets.error = "";
    },
    fetchCryptowalletError: (
      state: WalletState,
      action: PayloadAction<string>
    ) => {
      state.cryptoWallets.loading = false;
      state.cryptoWallets.error = action.payload;
    },
    fetchSubBusinessCryptoWalletsBegin: (state: WalletState) => {
      state.subBusiness.cryptoWallets.error = "";
      state.subBusiness.cryptoWallets.loading = true;
    },
    fetchSubBusinessCryptoWalletsSuccess: (
      state: WalletState,
      action: PayloadAction<GetAllCryptoWalletResponse[]>
    ) => {
      state.subBusiness.cryptoWallets.data = action.payload;
      state.subBusiness.cryptoWallets.loading = false;
      state.subBusiness.cryptoWallets.error = "";
    },
    fetchSubBusinessCryptowalletError: (
      state: WalletState,
      action: PayloadAction<string>
    ) => {
      state.subBusiness.cryptoWallets.loading = false;
      state.subBusiness.cryptoWallets.error = action.payload;
    },
    fetchSubBusinessFiatWalletsBegin: (state: WalletState) => {
      state.subBusiness.fiatWallets.loading = true;
      state.subBusiness.fiatWallets.error = "";
    },
    fetchSubBusinessFiatWalletsSuccess: (
      state: WalletState,
      action: PayloadAction<GetAllSubBusinessFiatWalletResponse[]>
    ) => {
      state.subBusiness.fiatWallets.data = action.payload;
      state.subBusiness.fiatWallets.loading = false;
      state.subBusiness.fiatWallets.error = "";
    },
    fetchSubBusinessFiatWalletsError: (
      state: WalletState,
      action: PayloadAction<string>
    ) => {
      state.subBusiness.fiatWallets.loading = false;
      state.subBusiness.fiatWallets.error = action.payload;
    },
    fetchSubBusinessSingleWalletsBegin: (state: WalletState) => {
      state.subBusiness.singleWallet.loading = true;
      state.subBusiness.singleWallet.error = "";
    },
    fetchSubBusinessSingleWalletSuccess: (
      state: WalletState,
      action: PayloadAction<GetSingleWalletResponse>
    ) => {
      state.subBusiness.singleWallet.data = action.payload;
      state.subBusiness.singleWallet.loading = false;
      state.subBusiness.singleWallet.error = "";
    },
    fetchSubBusinessSingleWalletError: (
      state: WalletState,
      action: PayloadAction<string>
    ) => {
      state.subBusiness.singleWallet.loading = false;
      state.subBusiness.singleWallet.error = action.payload;
    },
    fetchSingleWalletetsBegin: (state: WalletState) => {
      state.singleWallet.loading = true;
      state.singleWallet.error = "";
    },
    fetchSingleWalletSuccess: (
      state: WalletState,
      action: PayloadAction<GetSingleWalletResponse>
    ) => {
      state.singleWallet.data = action.payload;
      state.singleWallet.loading = false;
      state.singleWallet.error = "";
    },
    fetchSingleWalletError: (
      state: WalletState,
      action: PayloadAction<string>
    ) => {
      state.singleWallet.loading = false;
      state.singleWallet.error = action.payload;
    },
    onFundWalletModalOpen: (
      state: WalletState,
      action: PayloadAction<string>
    ) => {
      state.isFundWalletModalOpen = action.payload;
    },
    onFundWalletModalClose: (state: WalletState) => {
      state.isFundWalletModalOpen = "";
    },
    onSelectFundWalletModalOpen: (state: WalletState) => {
      state.isSelectFundWalletModalOpen = true;
    },
    onSelectFundWalletModalClose: (state: WalletState) => {
      state.isSelectFundWalletModalOpen = false;
    },
    setCreateSubWalletStatus: (
      state: WalletState,
      action: PayloadAction<string>
    ) => {
      state.isCreateSubWalletStatus = action.payload;
    },
    setSelectedFiatWallet: (
      state: WalletState,
      action: PayloadAction<string>
    ) => {
      state.selectedFiatWallet = action.payload;
    },
    setFiatWallet: (state: WalletState, action: PayloadAction<any>) => {
      state.selectedFiatWallet = action.payload;
    },
    setSelectedCryptoWallet: (
      state: WalletState,
      action: PayloadAction<CreateCryptoWalletParams | null>
    ) => {
      state.selectedCryptoWallet = action.payload;
    },
    setSelectedFiatSubWallet: (
      state,
      action: PayloadAction<SubWalletDataModel | null>
    ) => {
      state.selectedSubWallet = action.payload;
    },
    setCreatedFiatSubWallet: (
      state,
      action: PayloadAction<SubWalletDataModel | null>
    ) => {
      state.createdSubWallet = action.payload;
    },
    setShowFundSubWallet: (state, action: PayloadAction<boolean>) => {
      state.isShowFundSubWallet = action.payload;
    },
    withdrawFiatWallet: (
      state,
      action: PayloadAction<{ currency: string; amount: number }>
    ) => {
      const index = state.fiatWallets.data.findIndex(
        (w) => w.currency === action.payload.currency
      );

      const prevBalance = parseFloat(state.fiatWallets.data[index].balance);

      state.fiatWallets.data[index].balance = String(
        prevBalance - action.payload.amount
      );

      if (
        state?.singleWallet?.data?.balance &&
        state?.singleWallet?.data?.currency === action.payload.currency
      ) {
        state.singleWallet.data.balance = String(
          prevBalance - action.payload.amount
        );
      }
    }
  }
});

export const actions = WalletSlice.actions;

export default WalletSlice.reducer;

import { Dispatch } from "@reduxjs/toolkit";
import instance from "data/axios-setup";
import { onResponseSuccess } from "data/error-mapping";
import { getCallSign, saveCallSign } from "data/utils";
import toast from "react-hot-toast";
import {
  businessError,
  gettingBusinessInfo,
  settingBusinessInfo,
  loadingBegin,
  createBusinessSuccess,
  error,
  finishBusinessRegistration,
  addedBusinessOwnerDetails,
  confimingCallSign,
  callSignConfirmed,
  settingAllBusiness,
  settingSupportedCountries,
  gettingAllBusinesses
} from "../reducers/business-setup.reducers";

export const getBusinessInfo = (cs?: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      dispatch(gettingBusinessInfo());
      const res = await instance.get(`/business/${cs || callSign}`);
      onResponseSuccess(res, () => {
        dispatch(settingBusinessInfo(res.data.data));
      });
    } catch (err: any) {
      dispatch(businessError());
    }
  };
};
export const getAllBusiness = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(gettingAllBusinesses());
      const res = await instance.get("/business/all");
      onResponseSuccess(res, () => {
        dispatch(settingAllBusiness(res.data.data));
      });
    } catch (err: any) {
      dispatch(businessError());
    }
  };
};

export const getAllSupportedCountries = () => {
  return async (dispatch: Dispatch) => {
    const callSign = getCallSign();
    try {
      const res = await instance.get(
        `/business/${callSign}/supported-countries`
      );
      onResponseSuccess(res, () => {
        dispatch(settingSupportedCountries(res.data.data));
      });
    } catch (err: any) {
      dispatch(businessError());
    }
  };
};

export const reloadBusinessInfo = () => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      const res = await instance.get(`/business/${callSign}`);
      onResponseSuccess(res, () => {
        dispatch(settingBusinessInfo(res.data.data));
      });
    } catch (err: any) {
      dispatch(businessError());
    }
  };
};

export const createBusiness = (data: any, setActiveSetup: any, dis: any) => {
  return async (dispatch: Dispatch) => {
    const {
      name,
      countryRegisteredIn,
      countryRegisteredInII,
      newIsRegisteredInAnotherCountry,
      legalName,
      businessRegistrationNumber,
      dateOfIncorporation,
      type,
      industry,
      description,
      callSign,
      email,
      phone,
      sourceOfFunds,
      otherSourceOfFunds
    } = data;
    const isRegisteredInAnotherCountry =
      newIsRegisteredInAnotherCountry === "true" ? true : false;
    const args = {
      name,
      countryRegisteredIn,
      countryRegisteredInII,
      isRegisteredInAnotherCountry,
      legalName,
      businessRegistrationNumber,
      dateOfIncorporation,
      type,
      industry,
      description,
      callSign: callSign.trim(),
      email,
      phone,
      sourceOfFunds:
        sourceOfFunds !== "other" ? sourceOfFunds : otherSourceOfFunds
    };
    try {
      dispatch(loadingBegin());
      const res = await instance.post("/business", args);
      onResponseSuccess(res, () => {
        saveCallSign(res.data.data.callSign);
        dispatch(createBusinessSuccess());
        toast.success("Business has been created successfully");
        setActiveSetup(2);
        dis(reloadBusinessInfo());
      });
    } catch (err: any) {
      dispatch(error());
      toast.error(err.response.data.message);
    }
  };
};

export const updateBusiness = (data: any, setActiveSetup: any, dis: any) => {
  return async (dispatch: Dispatch) => {
    const mainCallSign = getCallSign();
    const {
      name,
      countryRegisteredIn,
      countryRegisteredInII,
      newIsRegisteredInAnotherCountry,
      legalName,
      businessRegistrationNumber,
      dateOfIncorporation,
      type,
      industry,
      description,
      callSign,
      email,
      phone,
      sourceOfFunds,
      otherSourceOfFunds
    } = data;
    const isRegisteredInAnotherCountry =
      newIsRegisteredInAnotherCountry === "true" ? true : false;
    const args = {
      name,
      countryRegisteredIn,
      countryRegisteredInII,
      isRegisteredInAnotherCountry,
      legalName,
      businessRegistrationNumber,
      dateOfIncorporation,
      type,
      industry,
      description,
      callSign,
      email,
      phone,
      sourceOfFunds:
        sourceOfFunds !== "other" ? sourceOfFunds : otherSourceOfFunds
    };
    try {
      dispatch(loadingBegin());
      const res = await instance.put(`/business/${mainCallSign}`, args);
      onResponseSuccess(res, () => {
        dispatch(createBusinessSuccess());
        toast.success("Business has been updated successfully");
        setActiveSetup(2);
        dis(reloadBusinessInfo());
      });
    } catch (err: any) {
      dispatch(error());
      toast.error(err.response.data.message);
    }
  };
};

export const addCallSign = (data: any, setActiveSetup: any, dis: any) => {
  return async (dispatch: Dispatch) => {
    const { callSign } = data;
    const args = {
      callSign
    };
    try {
      dispatch(loadingBegin());
      const res = await instance.put(`/business/single`, args);
      onResponseSuccess(res, () => {
        dispatch(createBusinessSuccess());
        toast.success("Call sign has been added successfully");
        setActiveSetup(3);
        dis(reloadBusinessInfo());
      });
    } catch (err: any) {
      dispatch(error());
      toast.error(err.response.data.message);
    }
  };
};

export const confirmCallSign = (data: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(confimingCallSign());
      const res = await instance.get(`/business/exists-callsign/${data}`);
      onResponseSuccess(res, () => {
        dispatch(callSignConfirmed(res.data.data));
      });
    } catch (err: any) {
      dispatch(error());
    }
  };
};

export const addContactDetails = (data: any, setActiveSetup: any, dis: any) => {
  return async (dispatch: Dispatch) => {
    const {
      country,
      address,
      state,
      city,
      zipCode,
      website,
      phone,
      addressNumber
    } = data;
    const args = {
      country,
      address,
      addressNumber,
      state,
      city,
      zipCode,
      website,
      phone
    };
    try {
      const callSign = getCallSign();
      dispatch(loadingBegin());
      const res = await instance.put(`/business/${callSign}`, args);
      onResponseSuccess(res, () => {
        dispatch(createBusinessSuccess());
        toast.success("Business contact details has been added successfully");
        setActiveSetup(3);
        dis(reloadBusinessInfo());
      });
    } catch (err: any) {
      dispatch(error());
      toast.error(err.response.data.message);
    }
  };
};

export const addOwnerDetails = (data: any, setDisplayForm: any, dis: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      dispatch(loadingBegin());

      const res = await instance.post(`/business/${callSign}/owner`, data);
      onResponseSuccess(res, () => {
        toast.success("Business owner details has been added successfully");
        dis(reloadBusinessInfo());
        setDisplayForm(false);
        dispatch(createBusinessSuccess());
        dispatch(addedBusinessOwnerDetails());
      });
      return true;
    } catch (err: any) {
      dispatch(error());
      toast.error(err.response.data.message);
    }
  };
};

export const updateOwnerDetails = (
  data: any,
  setDisplayForm: any,
  businessOwnerId: any,
  dis: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const callSign = getCallSign();
      dispatch(loadingBegin());
      const res = await instance.put(
        `/business/${callSign}/owner/${businessOwnerId}`,
        data
      );
      onResponseSuccess(res, () => {
        toast.success("Business owner details has been updated successfully");
        dis(reloadBusinessInfo());
        setDisplayForm(false);
        dispatch(createBusinessSuccess());
      });
    } catch (err: any) {
      dispatch(error());
      toast.error(err.response.data.message);
    }
  };
};

export const addBusinessDocuments = (data: any, dis: any) => {
  return async (dispatch: Dispatch) => {
    const {
      certificateOfIncorporation,
      memorandumAndArticlesOfAssociation,
      utilityBill,
      bankStatement,
      certificateOfRegistrationII,
      others
    } = data;
    const args = {
      certificateOfIncorporation,
      memorandumAndArticlesOfAssociation,
      utilityBill,
      bankStatement,
      certificateOfRegistrationII,
      others
    };
    try {
      const callSign = getCallSign();
      dispatch(loadingBegin());
      const res = await instance.post(
        `/business/${callSign}/upload-document`,
        args
      );
      onResponseSuccess(res, () => {
        dispatch(finishBusinessRegistration());
        toast.success("Documents has been added successfully");
        dis(reloadBusinessInfo());
      });
    } catch (err: any) {
      dispatch(error());
      toast.error(err.response.data.message);
    }
  };
};
